import React, { useState, useEffect } from 'react';
import {Col, Row} from "reactstrap";
import _ from "lodash";
import API from '../../utils/API';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import {v4 as uuidv4} from 'uuid';
import Dropzone from 'react-dropzone-uploader';
import Loader from '../common/loader';
import Masonry from "react-masonry-css";
import SweetAlert from 'react-bootstrap-sweetalert';

const WorkOrderPhotosBefore = (props) =>  {

    const [workOrder, setWorkOrder] = useState(null);
    const [previewImageLoading, setPreviewImageLoading] = useState(false);
    const [uploadedPreviewImages, setUploadedPreviewImages] = useState([]);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    
    const handleTakePhoto = (dataUri) => {
        let decoded = atob(dataUri.substr(dataUri.indexOf(";") + 8 )); 
        setUploadedPreviewImages([...uploadedPreviewImages, {
            id: uuidv4(),
            fileName : 'cameraphoto',
            mimeType : 'image/png',
            fileSize : decoded.length,
            file : dataUri
        }]);
        setPreviewImageLoading(false);
    };

    const readPreviewImage = (id, file) => {
        getBase64(file, (result) => {
            setUploadedPreviewImages([...uploadedPreviewImages, {
                id,
                fileName : file.name,
                mimeType : file.type,
                fileSize : file.size,
                file : result
            }]);
            setPreviewImageLoading(false);
        });
        
    };

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const handlePreviewImages = ({ meta, file }, status) => {
        if (meta.status === "done") {
            readPreviewImage(meta.id, file);
        }
    };

    const uploadPreviewImages = () => {
        setPreviewImageLoading(true);
        API.post('project/' + workOrder.project.id + '/workorder/' + workOrder.id + '/uploadpreviewimages', {files : uploadedPreviewImages}).then(function(response) {
            reloadWorkOrder();
            setPreviewImageLoading(false);
            setShowCreateDialog(false);
        }).catch(function(response) {
            setPreviewImageLoading(false);
            setShowCreateDialog(false);
        });
    };

    const deletePreviewImage = (id) => {
        setPreviewImageLoading(true);
        API.delete('project/' + workOrder.project.id + '/workorder/' + workOrder.id + '/previewimage/' + id).then(function(response) {
            reloadWorkOrder();
            setPreviewImageLoading(false);
        }).catch(function() {
            setPreviewImageLoading(false);
        });
    };

    const downloadPreviewImage = (id) => {
        setPreviewImageLoading(true);
        API.get('/workorderpreviewimage/' + id).then(function(response) {
            const linkId = 'link-workorderpreview-id-' + id;
            const link = document.createElement("a");
            link.id = linkId;
            link.innerHTML = "";
            link.download = response.data.fileName;
            link.href = response.data.base64;
            document.body.appendChild(link);
            document.getElementById(linkId).click();
            setPreviewImageLoading(false);
        }).catch(function() {
            setPreviewImageLoading(false);
        });
    };

    const reloadWorkOrder = () => {
        props.onWorkOrderChange();
        if (workOrder !== null) {
            API.get('workorder/' + workOrder.id).then(function(response) {
                setWorkOrder(response.data);
            });
        }
    };

    const openCreateDialog = () => {
        setShowCreateDialog(true);
    };
    
    const closeCreateDialog = () => {
        setShowCreateDialog(false);
    }

    useEffect(() => {
        setWorkOrder(props.workOrder);
    }, []);

    return (
        workOrder !== null ? (
            <>
                <SweetAlert
                    show={showCreateDialog}
                    showCloseButton={true}
                    onCancel={closeCreateDialog}
                    onConfirm={uploadPreviewImages}
                    confirmBtnText={'Voorfoto toevoegen'}
                    cancelBtnText={'Annuleren'}
                    style={{width: '90%'}}
                >
                    <Row>    
                        <Col lg={6} md={12}>
                            <Camera idealFacingMode={FACING_MODES.ENVIRONMENT} isImageMirror={false} onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } } />
                        </Col>
                        <Col lg={6} md={12}>
                            {previewImageLoading ? (
                                <Loader />
                            ) : (
                                <Dropzone
                                    onChangeStatus={e => {
                                        handlePreviewImages(e)
                                    }}
                                    accept={'image/*'}
                                    maxFiles={20}
                                    multiple={true}
                                    canCancel={false}
                                    canRemove={false}
                                    inputContent="Plaats je voor-foto's hier"
                                    styles={{
                                        previewImage: { minHeight: 150, maxHeight: 150 },
                                        dropzone: { minHeight: 200, maxHeight: 250 }
                                    }}
                                />
                            )}
                        </Col>
                    </Row>
                </SweetAlert>

                <div className={'row'}>
                    <div className={'col-xl-12'}>
                        <Masonry
                            breakpointCols={4}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column">
                            {workOrder.previewImages.map((previewImage, index) =>
                                <li key={'previewImage_' + index} className={'text-center'} style={{border: '1px solid #dddddd'}}>
                                    <Row className={'ml-3 mr-3 mt-3 mt-3'}>
                                        <Col lg={12}>
                                            <img src={previewImage.attachment} style={{ width: '100%' }} alt="" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            
                                        </Col>
                                    </Row>
                                    <Row className={'ml-3 mr-3 mb-3 mt-3'}>
                                        <Col lg={6} className={'text-left'}>
                                            <button className={'btn btn-primary'} onClick={() => downloadPreviewImage(previewImage.id)}>Download</button>
                                        </Col>
                                        <Col lg={6} className={'text-right'}>
                                            <button className={'btn btn-primary'} onClick={() => deletePreviewImage(previewImage.id)}>X</button>
                                        </Col>
                                    </Row>
                                </li>
                            )}
                        </Masonry>
                    </div>
                </div>
                
                <br />
                <br />
                <div className={'row'}>
                    <div className={'col-xl-12'}>
                        <button className={'btn btn-primary'} onClick={openCreateDialog}>Nieuwe afbeelding toevoegen</button>
                    </div>
                </div>
            </>
        ) : null
        
    );
};

export default WorkOrderPhotosBefore;