import React, { Fragment, useState, useMemo, useEffect } from 'react';
import {Card, CardBody, CardTitle, Col, Input, Row} from "reactstrap";
import _ from "lodash";
import GeoCode from "react-geocode";
import { Link } from 'react-router-dom';
import useReactTable from '../../hooks/useReactTable';
import API from '../../utils/API';
import ReactTable from 'react-table-6';
import SweetAlert from 'react-bootstrap-sweetalert';

const WorkOrderTasks = (props) =>  {

    const [workOrder, setWorkOrder] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [newTask, setNewTask] = useState({});
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const [formErrors, setFormErrors] = useState({});
    const [users, setUsers] = useState([]);
    const columns = useMemo(() => [
        {
            Header : 'Taak',
            accessor : 'name',
            headerClassName: "text-left",
            width: 250,
            Cell: row => {
                return <Link to={`/task/detail/` + row.row._original.id} target={'_parent'}>{row.row.name}</Link>
            }
        },{
            Header : 'Begin',
            accessor : 'plannedDateForExecution',
            headerClassName: "text-left",
            width: 250,
            Cell: row => {
                return new Intl.DateTimeFormat("nl-BE", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit"
                }).format(new Date(row.row.plannedDateForExecution))
            }
        },{
            Header : 'Einde',
            accessor : 'plannedEndDateForExecution',
            headerClassName: "text-left",
            width: 250,
            Cell: row => {
                return new Intl.DateTimeFormat("nl-BE", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit"
                }).format(new Date(row.row.plannedEndDateForExecution))
            }
        },
        {
            Header : 'Uitvoerder',
            accessor : 'responsibleUser',
            headerClassName: "text-left",
            Cell: row => {
                if (row.row.responsibleUser !== undefined && row.row.responsibleUser !== null) {
                    return <span>{row.row.responsibleUser.firstName} {row.row.responsibleUser.lastName}</span>
                } else {
                    return <span></span>
                }
            }
        }
    ]);

    const openSegmentToAddTask = () => {
        setShowCreateDialog(true);
    };

    const closeSegmentToAddTask = () => {
        setShowCreateDialog(false);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        let errors = {...formErrors};
        setNewTask({...newTask, [name]: value});
    };

    const handleChangeCheckbox =  (e) => {
        const {name, value} = e.target;
        let errors = {...formErrors};
        setNewTask({...newTask, [name]: value === 'on'});
    };

    const changeTaskResponsibleUser = (event) => {
        let value = event.target.value;
        if (value === "" || value === null || value === '0' ) {
            //this.setState((prevState) => ({errorsOfTaskForm: { ...prevState.errorsOfTaskForm, responsibleUserId : true }}));
        
        }
        setNewTask({...newTask, responsibleUserId: value});
    };

    const changeTaskAccountableUser = (event) => {
        let value = event.target.value;
        if (value === "" || value === null || value === '0' ) {
            //this.setState((prevState) => ({errorsOfTaskForm: { ...prevState.errorsOfTaskForm, responsibleUserId : true }}));
        
        }
        setNewTask({...newTask, accountableUserId: value});
    };

    const validateTask = () => {
        let validated = true;
        //if (this.state.taskForm.name === "" || this.state.taskForm.name === null) {
        //    validated = false;
        //}
        //if (this.state.taskForm.description === "" || this.state.taskForm.description === null) {
         //   validated = false;
        //}
        //if (this.state.taskForm.responsibleUserId === "" || this.state.taskForm.responsibleUserId === null) {
        //    validated = false;
        //}
        //if (this.state.taskForm.accountableUserId === "" || this.state.taskForm.accountableUserId === null) {
        //    validated = false;
        //}
        return validated;
    };

    const addNewTask = () => {
        console.log("Adding new task");
        console.log(validateTask());
        if(validateTask()) {
            API.post('project/' + workOrder.project.id +  'workorder/' + workOrder.id + '/task', newTask).then(function(response) {
                API.get('/project/' + workOrder.project.id +'/tasks').then(function(response) {
                    setTasks(response.data);
                    setShowCreateDialog(false);
                });
            }).catch(function(response) {
                console.log(response);
            });
        }
    };

    const cancelAddTask = () => {
        setShowCreateDialog(false);
    };

    useEffect(() => {
        setWorkOrder(props.workOrder);
        API.get('users').then(function(response) {
            setUsers(response.data);
        })
    }, []);


    useEffect(() => {
        if (workOrder !== null) {
            API.get('workorder/' + workOrder.id + '/tasks').then(function(response) {
                setTasks(response.data);
            });
        }
    },[workOrder]);

    return (
        workOrder !== null ? (
            <>
                <div className="card firstPost" >
                    <div className="profile-img-style">
                        <div className="row">
                            <div className="col-sm-8">
                                <div className="media">
                                    <div className="media-body align-self-center">
                                        <h6><strong>Takenoverzicht</strong> <a onClick={openSegmentToAddTask}><span className={'fa fa-2x fa-plus'}></span></a></h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <SweetAlert
                            show={showCreateDialog}
                            showCloseButton={true}
                            onCancel={cancelAddTask}
                            info
                            title="Taak toevoegen"
                            onConfirm={addNewTask}
                            confirmBtnText={'Taak toevoegen'}
                            cancelBtnText={'Annuleren'}
                            style={{width: '90%'}}
                        >
                            <form>
                            <div className={'form-group row'}>
                                    <div className={'col-3'}>Naam:</div>
                                    <div className={'col-9'}><input onChange={handleChange} className={'form-control'} type={'text'} name={'name'} value={newTask.name} /></div>
                                    {/*this.state.errorsOfTaskForm.name === true ? (
                                        <div className={'col-9 offset-3'}>Naam is een verplicht veld</div>
                                    ) : null*/}
                                </div>
                                <div className={'form-group row'}>
                                    <div className={'col-3'}>Beschrijving:</div>
                                    <div className={'col-9'}><input onChange={handleChange} className={'form-control'} type={'text'} name={'description'} value={newTask.description} /></div>
                                    {/*this.state.errorsOfTaskForm.description === true ? (
                                        <div className={'col-9 offset-3'}>Beschrijving is een verplicht veld</div>
                                    ) : null*/}
                                </div>
                                <div className={'form-group row'}>
                                    <div className={'col-xs-3 col-md-3'}>Start van uitvoering:</div>
                                    <div className={'col-xs-9 col-md-3'}><input onChange={handleChange} className={'form-control digits'} name={'startDate'} type={'date'} value={newTask.startDate} /></div>
                                    <div className={'col-xs-9 col-md-3'}><input onChange={handleChange} className={'form-control digits'} name={'startTime'} type={'time'} value={newTask.startTime} /></div>
                                </div>
                                <div className={'form-group row'}>
                                    <div className={'col-xs-3 col-md-3'}>Einde van uitvoering:</div>
                                    <div className={'col-xs-9 col-md-3'}><input onChange={handleChange} className={'form-control digits'} name={'endDate'} type={'date'} value={newTask.endDate} /></div>
                                    <div className={'col-xs-9 col-md-3'}><input onChange={handleChange} className={'form-control digits'} name={'endTime'} type={'time'} value={newTask.endTime} /></div>
                                </div>
                                <div className={'form-group row'}>
                                    <div className={'col-xs-3 col-md-3'}>Inclusief werk op zaterdag</div>
                                    <div className={'col-xs-9 col-md-3'}><input type={'checkbox'} className={'form-control uniform'} name={'inclusiveSaturday'} checked={newTask.inclusiveSaturday} onChange={handleChangeCheckbox} /></div>
                                    <div className={'col-xs-3 col-md-3'}>Inclusief werk op zondag</div>
                                    <div className={'col-xs-9 col-md-3'}><input type={'checkbox'} className={'form-control uniform'} name={'inclusiveSunday'} checked={newTask.inclusiveSunday} onChange={handleChangeCheckbox} /></div>
                                </div>
                                <div className={'form-group row'}>
                                    <div className={'col-xs-3 col-md-3'}>Uitvoerder:</div>
                                    <div className={'col-xs-9 col-md-3'}>
                                        <select onChange={changeTaskResponsibleUser} className={'form-control'} name={'taskResponsibleUserId'}>
                                            <option value={'0'}>---</option>
                                            {users.map(function(user) {
                                                return (
                                                    <option value={user.id}>{user.firstName} {user.lastName}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    {/*this.state.errorsOfTaskForm.responsibleUserId === true ? (
                                        <div className={'col-9 offset-3'}>Uitvoerder is een verplicht veld</div>
                                    ) : null*/}
                                    <div className={'col-xs-3 col-md-3'}>Eindverantwoordelijke:</div>
                                    <div className={'col-xs-9 col-md-3'}>
                                        <select onChange={changeTaskAccountableUser} className={'form-control'} name={'taskAccountableUserId'}>
                                            <option value={'0'}>---</option>
                                            {users.map(function(user) {
                                                return (
                                                    <option value={user.id}>{user.firstName} {user.lastName}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    {/*this.state.errorsOfTaskForm.accountableUserId === true ? (
                                        <div className={'col-9 offset-3'}>Eindverantwoordelijke is een verplicht veld</div>
                                    ) : null*/}
                                </div>
                            </form>
                        </SweetAlert>
                        <div className="row">
                            <div className="col-lg-12">
                                <ReactTable 
                                        data={tasks}
                                        columns={columns}
                                        minRows={0}
                                        defaultPageSize={10}
                                        className={"-striped -highlight"}
                                        showPagination={true}
                                        showPagination={true}
                                        previousText={'Vorige'}
                                        nextText={'Volgende'}
                                        pageText={'Pagina'}
                                        rowsText={'rijen'}
                                        ofText={'van'}
                                        getTheadThProps={getTheadThProps}
                                        getTdProps={getTdProps}
                                        getTrProps={getTrProps}
                                        getPaginationProps={getPaginationProps}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ) : null
        
    );
};

export default WorkOrderTasks;