import React, { useState, useEffect } from 'react';
import logo from '../assets/images/logo-pouillon.png';
import man from '../assets/images/dashboard/user.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { withRouter } from "react-router";

const Signin = ({ history }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [value, setValue] = useState(
        localStorage.getItem('profileURL' || man)
    );

    useEffect(() => {
        if (value !== null)
            localStorage.setItem('profileURL', value);
        else
            localStorage.setItem('profileURL', man);
    }, [value]);

    const loginAuth = async (e) => {
        e.preventDefault();

        const client_id = "5_1dycdtr9huzo040w4okkc884kkggcckcgww4wwcck40kc808cs";
        const client_secret = "5ygsa0s1ptcswwgcsc0ck48g4g8c0ggkwck08soow4o08s8oc8";
        const grant_type = "password";
        try {
                await fetch(process.env.REACT_APP_BASE_URL + '/oauth/v2/token', {
                  method : 'POST',
                  headers : {
                      'Content-Type': 'application/x-www-form-urlencoded'
                  },
                  body : "client_id=" + client_id + "&client_secret=" + client_secret + "&grant_type=" + grant_type + "&username=" + email + "&password=" + password
                }).then(function(response) {
                    console.log(response);
                    return response.json();
                }).then(function(response) {
                    console.log(response);
                    if (response.error) {
                        setTimeout(() => {
                            toast.error("De combinatie van het e-mailadres en wachtwoord is incorrect. Gelieve de juiste combinatie in te geven");
                        }, 200);
                    } else {
                        localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN, response.access_token);
                        localStorage.setItem('werkbonnen_pouillon_dev_expires_in', response.expires_in);
                        localStorage.setItem('werkbonnen_pouillon_dev_token_type', response.token_type);
                        localStorage.setItem('werkbonnen_pouillon_dev_refresh_token', response.refresh_token);
                        window.location.href = "/";
                    }
                });
        } catch (error) {
            setTimeout(() => {
                toast.error("Er is een systeemfout opgetreden. Gelieve je systeembeheerder te contacteren.");
            }, 200);
        }
    };

    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div style={{textAlign: "center"}}>
                                                    <img src={logo} alt="" />
                                                </div>
                                                <br />
                                                <div>
                                                    <h4>INLOGGEN</h4>
                                                    <h6>Vul je e-mail en wachtwoord in </h6>
                                                </div>
                                                <form className="theme-form" onSubmit={loginAuth} >
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">Jouw e-mail</label>
                                                        <input 
                                                            className="form-control" 
                                                            type="text" 
                                                            name="email"
                                                            value={email}
                                                            onChange={e => setEmail(e.target.value)}
                                                            placeholder="Email address"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Wachtwoord</label>
                                                        <input className="form-control" type="password" name="password"
                                                            value={password}
                                                            onChange={e => setPassword(e.target.value)} />
                                                    </div>
                                                    <div className="checkbox p-0">
                                                        <input id="checkbox1" type="checkbox" />
                                                        <label htmlFor="checkbox1">Herinner mij</label>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <button className="btn btn-primary btn-block" type="submit">Inloggen</button>
                                                    </div>
                                                    <div className={'form-group form-row mt-3 mb-0'}>
                                                        <a href={`${process.env.PUBLIC_URL}/forgetpassword`}>Wachtwoord vergeten?</a>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </div>
    );
};

export default withRouter(Signin);