import React, {useEffect, useState} from 'react';
import '../../assets/css/reacttable_pouillon.css';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import _ from "lodash";
import {Card, CardBody, Col, Row} from "reactstrap";
import CustomerDetail from './detail/customerDetail';
import ProjectLocation from './detail/projectLocation';
import ProjectDetailInformation from './detail/projectDetailInformation';
import ProjectDocuments from './detail/projectDocuments';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import WorkSharpIcon from '@material-ui/icons/WorkSharp';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { instagramTabsStylesHook } from '@mui-treasury/styles/tabs';
import ProjectInvoices from './detail/projectInvoices';
import ProjectOffers from './detail/projectOffers';
import ProjectTaxCertificates from './detail/projectTaxCertificates';
import ProjectTimesheets from './detail/projectTimesheets';
import ProjectWorkOrders from './detail/projectWorkOrders';
import ProjectTasks from './detail/ProjectTasks';
import ProjectNotes from './detail/projectNotes';
import ProjectTodos from './detail/projectTodos';
import ProjectTodosCompact from './detail/compact/projectTodosCompact';
import ProjectNotesCompact from './detail/compact/projectNotesCompact';
import ProjectWorkOrdersCompact from './detail/compact/projectWorkOrdersCompact';
import ProjectOffersCompact from './detail/compact/projectOffersCompact';
import ProjectTasksCompact from './detail/compact/ProjectTasksCompact';
import ProjectInvoicesCompact from './detail/compact/projectInvoicesCompact';

const ProjectDetail = (props) =>  {
    const [user, setUser] = useState(null);
    const [project, setProject] = useState(null);

    const [tabValue, setTabValue] = useState(0);
    const tabsStyles = instagramTabsStylesHook.useTabs();
    const tabItemStyles = instagramTabsStylesHook.useTabItem({bgColor: '#ececec', selectedBgColor: '#dc6809'});

    useEffect(() => {
        API.get('myuser').then(function(response) {
            setUser(response.data[0]);
        });
        API.get('project/' + props.match.params.id).then(function(response) {
            setProject(response.data);
        });
    }, []);

        return (
            <>
                <Breadcrumb parent="Projecten">
                    {!_.isEmpty(project) ? (
                        <span>
                            Project detail {project.name}
                        </span>
                    ) : null}  
                </Breadcrumb>
                <div className="container-fluid">
                    {user && (user.userRole === 'ADMIN' || user.userRole === 'EMPLOYER' || user.userRole === 'EMPLOYEE' ? (
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Toolbar style={{ minHeight: 72 }}>
                                            <Tabs 
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                value={tabValue} 
                                                onChange={(e, index) => setTabValue(index)}
                                                >
                                                    <Tab classes={tabItemStyles} label={"Dashboard"}></Tab>
                                                    <Tab classes={tabItemStyles} label={"Informatie"}></Tab>
                                                    <Tab classes={tabItemStyles} label={"Klant"}></Tab>
                                                    <Tab classes={tabItemStyles} label={"Locatie"}></Tab>
                                                    <Tab classes={tabItemStyles} label={"Werkbonnen"}></Tab>
                                                    <Tab classes={tabItemStyles} label={"Taken"}></Tab>
                                                    <Tab classes={tabItemStyles} label={"Prestaties"}></Tab>
                                                    <Tab classes={tabItemStyles} label={"BTW-attesten"}></Tab>
                                                    <Tab classes={tabItemStyles} label={"Offerten"}></Tab>
                                                    <Tab classes={tabItemStyles} label={"Facturen"}></Tab>
                                                    <Tab classes={tabItemStyles} label={"To Do's"}></Tab>
                                                    <Tab classes={tabItemStyles} label={"Notities"}></Tab>
                                                    <Tab classes={tabItemStyles} label={"Prestaties"}></Tab>
                                                    <Tab classes={tabItemStyles} label={"BTW-attesten"}></Tab>
                                            </Tabs>
                                        </Toolbar>


                                        <Row className={'mt-3 ml-3 mr-3'}>
                                            <Col lg={12}>
                                                {tabValue === 0 ? (
                                                    <>
                                                        <Row>
                                                            <Col lg={3}>
                                                                {!_.isEmpty(project) && !_.isEmpty(project.customer) ? (
                                                                    <>
                                                                        <CustomerDetail customer={project.customer} />
                                                                    </>
                                                                ) : null}
                                                                <ProjectLocation project={project} />
                                                            </Col>
                                                            <Col lg={6}>
                                                                {!_.isEmpty(project) ? (
                                                                    <ProjectDetailInformation readOnly={user.userRole !== 'ADMIN' && user.userRole !== 'EMPLOYER'} editView={false} project={project} />
                                                                ) : null}
                                                            </Col>
                                                            <Col lg={3}>
                                                                <ProjectTodosCompact project={project} />
                                                                <ProjectNotesCompact project={project} />
                                                                <ProjectWorkOrdersCompact project={project} />
                                                                <ProjectTasksCompact project={project} />
                                                                <ProjectOffersCompact project={project} />
                                                                <ProjectInvoicesCompact project={project} />
                                                            </Col>
                                                        </Row>
                                                    </>
                                                ) : null}
                                                {tabValue === 1 ? (
                                                    !_.isEmpty(project) ? (
                                                        <ProjectDetailInformation readOnly={user.userRole !== 'ADMIN' && user.userRole !== 'EMPLOYER'} editView={false} project={project} />
                                                    ) : null
                                                ) : null}
                                                {tabValue === 2 ? (
                                                    !_.isEmpty(project) && !_.isEmpty(project.customer) ? (
                                                        <>
                                                            <CustomerDetail customer={project.customer} />
                                                        </>
                                                    ) : null
                                                ) : null}
                                                {tabValue === 3 ? (
                                                    <ProjectLocation project={project} />
                                                ) : null}
                                                {tabValue === 4 ? (
                                                    <ProjectWorkOrders project={project} />
                                                ) : null}
                                                {tabValue === 5 ? (
                                                    <ProjectTasks project={project} />
                                                ) : null} 
                                                {tabValue === 6 ? (
                                                    <ProjectTimesheets project={project} />
                                                ) : null}  
                                                {tabValue === 7 ? (
                                                    <ProjectTaxCertificates project={project} />
                                                ) : null}   
                                                {tabValue === 8 && (user.userRole === 'ADMIN' || user.userRole === 'EMPLOYER')  ? (
                                                    <ProjectOffers project={project} />
                                                ) : null}
                                                {tabValue === 9 && (user.userRole === 'ADMIN' || user.userRole === 'EMPLOYER') ? (
                                                    <ProjectInvoices project={project} />
                                                ) : null}
                                                {tabValue === 10 && (user.userRole === 'ADMIN' || user.userRole === 'EMPLOYER') ? (
                                                    <ProjectTodos project={project} />
                                                ) : null}
                                                {tabValue === 11 && (user.userRole === 'ADMIN' || user.userRole === 'EMPLOYER') ? (
                                                    <ProjectNotes project={project} />
                                                ) : null}
                                                {tabValue === 12 && (user.userRole === 'ADMIN' || user.userRole === 'EMPLOYER') ? (
                                                    <ProjectTimesheets project={project} />
                                                ) : null}
                                                {tabValue === 13 && (user.userRole === 'ADMIN' || user.userRole === 'EMPLOYER') ? (
                                                    <ProjectTaxCertificates project={project} />
                                                ) : null}
                                                
                                            </Col>
                                        </Row>  

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col lg={12}>
                                U heeft onvoldoende rechten om deze pagina te bekijken. Indien je wel de nodige rechten zou moeten hebben, gelieve je systeembeheerder te contacteren.
                            </Col>
                        </Row>
                    )) }
                </div>
            </>
        );
};

export default ProjectDetail;