import API from "../utils/API";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import APIUnauthorised from "../utils/APIUnauthorised";

const useReactTable = () => {


  const getTheadThProps = () => ({
    style: {
        fontWeight: '600',
        height: '50px',
        color: '#ff6e40',
        backgroundColor: "#ffffff",
        borderLeft: '0px',
        borderRight: '0px',
        textTransform: 'uppercase'
    },
  });
  const getTdProps = () => ({
    style: {
        height: '50px',
        backgroundColor: '#ffffff',
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
        borderBottom: 0
    },
  });
  const getTrProps = () => ({
    style: {
        height: '50px',
        backgroundColor: '#ffffff',
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
        borderBottom: 0
    }
  });
  
  const getPaginationProps= () => ({
    style: {
        backgroundColor: '#ffffff',
        color: '#ff6e40'
    } 
  });

    return {
      getTheadThProps: getTheadThProps,
      getTdProps: getTdProps,
      getTrProps: getTrProps,
      getPaginationProps: getPaginationProps
    };
}

export default useReactTable;