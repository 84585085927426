import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';
import _ from "lodash";

const Breadcrumb = props => {
    const breadcrumb = props;

    return (
        <Fragment>
            <Card className='mt-3'>
                <CardBody className='pb-2 pt-3'>
                    <Row>
                        <Col lg={12}>
                            <h6>
                                <Link to="/dashboard/default">
                                    <i className="icofont icofont-home"></i>
                                </Link>
                                {breadcrumb.parent ? (
                                    <>
                                        {_.isEmpty(breadcrumb.parentLink) ? (
                                            <>
                                                <i className="icofont icofont-rounded-right"></i>
                                                {breadcrumb.parent}
                                            </>
                                        ) : (
                                            <>
                                                <i className="icofont icofont-rounded-right"></i>
                                                <a href={breadcrumb.parentLink}>{breadcrumb.parent}</a>
                                            </>
                                        )}
                                    </>
                                ) : null}
                                <i className="icofont icofont-rounded-right"></i>
                                <strong>{breadcrumb.children}</strong>
                            </h6>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )
};

export default Breadcrumb