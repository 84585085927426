import React, {Fragment, useCallback} from 'react';
import { useForm } from 'react-hook-form';
import CKEditors from "react-ckeditor-component";
import {Typeahead} from "react-bootstrap-typeahead";
import _ from "lodash";
import API from "../../../utils/API";

class CustomerInformation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            countries : [],
            createNewCustomer : false,
            newCustomer: {
                isBusinessUser : false,
                useAddressOfUser : true,
                firstName : "",
                lastName : "",
                street : "",
                houseNumber : "",
                bus : "",
                postalCode : "",
                city : "",
                country : "",
                extraInformation : "",
                primaryEmail : "",
                primaryPhone : "",
                primaryMobile : "",
                alternativeEmail : "",
                alternativePhone : "",
                alternativeMobile : "",
                userRole : "CUSTOMER",
                invoiceData : {
                    companyName : "",
                    vatNumber : "",
                    cbeNumber : "",
                    street : "",
                    houseNumber : "",
                    bus: "",
                    postalCode : "",
                    city : "",
                    country : ""
                }
            },
            useCustomerInfoAsInvoiceData : true,
            existingCustomer : null,
            errors : {
                firstName : "",
                lastName : "",
                street : "",
                houseNumber : "",
                bus : "",
                postalCode : "",
                city : "",
                country : "",
                primaryEmail : "",
                primaryPhone : "",
                primaryMobile : "",
                invoiceData : {
                    street : "",
                    houseNumber : "",
                    bus : "",
                    postalCode : "",
                    city : "",
                    country : ""
                }
            },
            typing: false,
            typingTimeout: 0
        };
        this._validateOnDemand = true;
    }

    componentDidMount() {
        let me = this;
        API.get('config/countries').then(function(response) {
            me.setState({countries: response.data});
        });

        API.get('users').then(function(response) {
            let optionsArray = [];
            let customersArray = [];
            for (let i = 0; i < response.data.length; i++) {
                let personInformation = response.data[i].firstName + ' ' + response.data[i].lastName;
                if (!_.isEmpty(response.data[i].primaryEmail)) {
                    personInformation += ' - ' + response.data[i].primaryEmail;
                }
                personInformation += ' (' + response.data[i].street + ' ' + response.data[i].houseNumber + ' ' + response.data[i].bus + ' ,' + response.data[i].postalCode + ' ' + response.data[i].city + ')';
                optionsArray.push({
                    id: response.data[i].id ,
                    name:  personInformation
                });
                customersArray[response.data[i].id] = response.data[i];
            }
            me.setState({customers: optionsArray});
            me.setState({customerInformations: customersArray});
        });
    }

    componentWillUnmount() {
    }

    isValidated = () => {
        const isDataValid = this.validationCheck();
        if (isDataValid) {
            if (this.state.createNewCustomer === true) {
                this.props.updateCustomerStore({
                    createNewCustomer : true,
                    newCustomer : this.state.newCustomer
                });
            } else {
                this.props.updateCustomerStore({
                    createNewCustomer : false,
                    existingUserId: this.state.existingCustomer.id
                });
            }
        }
        return isDataValid;
    };

    validationCheck = async () => {
        let isValid = true;
        if (this.state.createNewCustomer) {
            if (_.isEmpty(this.state.newCustomer.firstName)) {
                isValid = false;
                this.setState((prevState) => ({errors: { ...prevState.errors, firstName : "Voornaam is een verplicht veld!" }}));
            } else {
                this.setState((prevState) => ({errors: { ...prevState.errors, firstName : "" }}));
            }
            if (_.isEmpty(this.state.newCustomer.lastName)) {
                isValid = false;
                this.setState((prevState) => ({errors: { ...prevState.errors, lastName : "Naam is een verplicht veld!" }}));
            } else {
                this.setState((prevState) => ({errors: { ...prevState.errors, lastName : "" }}));
            }
            if (_.isEmpty(this.state.newCustomer.street)) {
                isValid = false;
                this.setState((prevState) => ({errors: { ...prevState.errors, street : "Straat is een verplicht veld!" }}));
            } else {
                this.setState((prevState) => ({errors: { ...prevState.errors, street : "" }}));
            }
            if (_.isEmpty(this.state.newCustomer.houseNumber)) {
                isValid = false;
                this.setState((prevState) => ({errors: { ...prevState.errors, houseNumber : "Huisnummer is een verplicht veld!" }}));
            } else {
                this.setState((prevState) => ({errors: { ...prevState.errors, houseNumber : "" }}));
            }
            if (_.isEmpty(this.state.newCustomer.postalCode)) {
                isValid = false;
                this.setState((prevState) => ({errors: { ...prevState.errors, postalCode : "Postcode is een verplicht veld!" }}));
            } else {
                this.setState((prevState) => ({errors: { ...prevState.errors, postalCode : "" }}));
            }
            if (_.isEmpty(this.state.newCustomer.city)) {
                isValid = false;
                this.setState((prevState) => ({errors: { ...prevState.errors, city : "Gemeente is een verplicht veld!" }}));
            } else {
                this.setState((prevState) => ({errors: { ...prevState.errors, city : "" }}));
            }
            if (_.isEmpty(this.state.newCustomer.country)) {
                isValid = false;
                this.setState((prevState) => ({errors: { ...prevState.errors, country : "Land is een verplicht veld!" }}));
            } else {
                this.setState((prevState) => ({errors: { ...prevState.errors, country : "" }}));
            }
            if (!_.isEmpty(this.state.newCustomer.primaryEmail)) {
                let response = await API.get('uniqueuser?username=' + this.state.newCustomer.primaryEmail);
                if (response.data.unique.toString() === 'false') {
                    isValid = false;
                    this.setState((prevState) => ({errors: { ...prevState.errors, primaryEmail : "E-mailadres moet uniek zijn!" }}));
                } else {
                    this.setState((prevState) => ({errors: { ...prevState.errors, primaryEmail : "" }}));
                }
            }
            if (_.isEmpty(this.state.newCustomer.primaryPhone) && _.isEmpty(this.state.newCustomer.primaryMobile)) {
                isValid = false;
                this.setState((prevState) => ({errors: { ...prevState.errors, primaryPhone : "Telefoonnummer of gsmnummer is een verplicht veld!" }}));
                this.setState((prevState) => ({errors: { ...prevState.errors, primaryMobile : "Telefoonnummer of gsmnummer is een verplicht veld!" }}));
            } else {
                this.setState((prevState) => ({errors: { ...prevState.errors, primaryPhone : "" }}));
                this.setState((prevState) => ({errors: { ...prevState.errors, primaryMobile : "" }}));
            }
            if (!this.state.useCustomerInfoAsInvoiceData) {
                if (_.isEmpty(this.state.newCustomer.invoiceData.street)) {
                    isValid = false;
                    this.setState((prevState) => ({errors: { ...prevState.errors, invoiceData : {...prevState.errors.invoiceData, street : "Straat is een verplicht veld!"} }}));
                } else {
                    this.setState((prevState) => ({errors: { ...prevState.errors, invoiceData : {...prevState.errors.invoiceData, street : ""} }}));
                }
                if (_.isEmpty(this.state.newCustomer.invoiceData.houseNumber)) {
                    isValid = false;
                    this.setState((prevState) => ({errors: { ...prevState.errors, invoiceData : {...prevState.errors.invoiceData, houseNumber : "Huisnummer is een verplicht veld!"} }}));
                } else {
                    this.setState((prevState) => ({errors: { ...prevState.errors, invoiceData : {...prevState.errors.invoiceData, houseNumber : ""} }}));
                }
                if (_.isEmpty(this.state.newCustomer.invoiceData.postalCode)) {
                    isValid = false;
                    this.setState((prevState) => ({errors: { ...prevState.errors, invoiceData : {...prevState.errors.invoiceData, postalCode : "Postcode is een verplicht veld!"} }}));
                } else {
                    this.setState((prevState) => ({errors: { ...prevState.errors, invoiceData : {...prevState.errors.invoiceData, postalCode : ""} }}));
                }
                if (_.isEmpty(this.state.newCustomer.invoiceData.city)) {
                    isValid = false;
                    this.setState((prevState) => ({errors: { ...prevState.errors, invoiceData : {...prevState.errors.invoiceData, city : "Gemeente is een verplicht veld!"} }}));
                } else {
                    this.setState((prevState) => ({errors: { ...prevState.errors, invoiceData : {...prevState.errors.invoiceData, city : ""} }}));
                }
                if (_.isEmpty(this.state.newCustomer.invoiceData.country)) {
                    isValid = false;
                    this.setState((prevState) => ({errors: { ...prevState.errors, invoiceData : {...prevState.errors.invoiceData, country : "Land is een verplicht veld!"} }}));
                } else {
                    this.setState((prevState) => ({errors: { ...prevState.errors, invoiceData : {...prevState.errors.invoiceData, country : ""} }}));
                }
            }
        } else {

        }
        return isValid;
    };

    changeCustomer = (selectedArray) => {
        this.setState({existingCustomer: selectedArray[0]});
    };

    changeCreateNewCustomer = (event) => {
        let value = event.target.checked;
        this.setState({createNewCustomer : value });
    };

    changeUserField = event => {
        let value = event.target.value;
        let name = event.target.name;
        this.setState((prevState) => ({newCustomer: { ...prevState.newCustomer, [name] : value }}));
        if (name === 'primaryEmail') {
            const me = this;
            if (me.state.typingTimeout) {
                clearTimeout(me.state.typingTimeout);
            }
            me.setState({
                name: event.target.value,
                typing: false,
                typingTimeout: setTimeout(async function () {
                    let response = await API.get('uniqueuser?username=' + value);
                    if (response.data.unique.toString() === 'false') {
                        me.setState((prevState) => ({errors: { ...prevState.errors, primaryEmail : "E-mailadres moet uniek zijn" }}));
                    } else {
                        me.setState((prevState) => ({errors: { ...prevState.errors, primaryEmail : "" }}));
                    }
                }, 5000)
            });
        }
    };

    changeUserCountry = (selectedArray) => {
        let newCountry = selectedArray[0];
        this.setState((prevState) => ({newCustomer: { ...prevState.newCustomer, country : newCountry }}));
    };

    changeInvoiceDataField = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        this.setState((prevState) => ({newCustomer: { ...prevState.newCustomer, invoiceData : {...prevState.newCustomer.invoiceData, [name] : value} }}));
    };

    changeInvoiceCountry = (selectedArray) => {
        let newCountry = selectedArray[0];
        this.setState((prevState) => ({newCustomer: { ...prevState.newCustomer, invoiceData : {...prevState.newCustomer.invoiceData, country : newCountry} }}));
    };

    changeIsBusinessUser = (event) => {
        let value = event.target.checked;
        this.setState((prevState) => ({newCustomer: { ...prevState.newCustomer, isBusinessUser : value }}));
    };

    changeUseCustomerInfoAsInvoiceData = (event) => {
        let value = event.target.checked;
        this.setState((prevState) => ({newCustomer: { ...prevState.newCustomer, useAddressOfUser : value }}));
    };

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <input type={'checkbox'} key={'newCustomer'} name={'createNewCustomer'} defaultChecked={this.state.createNewCustomer} onChange={this.changeCreateNewCustomer} /> Nieuw klant aanmaken
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <hr />
                            </div>
                        </div>

                        {this.state.createNewCustomer ? (
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <div className={"row"}>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Voornaam</label>
                                                <input className="form-control" type="text" name="firstName" value={this.state.newCustomer.firstName} onChange={this.changeUserField} />
                                                {this.state.errors.firstName ? <span className={'errorInForm'}>{this.state.errors.firstName}</span> : null }
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Naam</label>
                                                <input className="form-control" type="text" name="lastName" value={this.state.newCustomer.lastName} onChange={this.changeUserField} />
                                                {this.state.errors.lastName ? <span className={'errorInForm'}>{this.state.errors.lastName}</span> : null }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="col-xs-12 col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Straat</label>
                                                <input className="form-control" type="text" name="street" value={this.state.newCustomer.street} onChange={this.changeUserField} />
                                                {this.state.errors.street ? <span className={'errorInForm'}>{this.state.errors.street}</span> : null }
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">Huisnummer</label>
                                                <input className="form-control" type="text" name="houseNumber" value={this.state.newCustomer.houseNumber} onChange={this.changeUserField} />
                                                {this.state.errors.houseNumber ? <span className={'errorInForm'}>{this.state.errors.houseNumber}</span> : null }
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">Bus</label>
                                                <input className="form-control" type="text" name="bus" value={this.state.newCustomer.bus} onChange={this.changeUserField} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">Postcode</label>
                                                <input className="form-control" type="text" name="postalCode" value={this.state.newCustomer.postalCode} onChange={this.changeUserField} />
                                                {this.state.errors.postalCode ? <span className={'errorInForm'}>{this.state.errors.postalCode}</span> : null }
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">Gemeente</label>
                                                <input className="form-control" type="text" name="city" value={this.state.newCustomer.city} onChange={this.changeUserField} />
                                                {this.state.errors.city ? <span className={'errorInForm'}>{this.state.errors.city}</span> : null }
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label className="form-label">Land</label>
                                                {this.state.countries ? (
                                                    <Typeahead
                                                        id="project-typeahead"
                                                        name="country"
                                                        onChange={this.changeUserCountry}
                                                        clearButton
                                                        labelKey="name"
                                                        selected={this.state.newCustomer.country ? [this.state.newCustomer.country] : []}
                                                        multiple={false}
                                                        options={this.state.countries}
                                                        placeholder="Selecteer een land ..."
                                                    />
                                                ) : null}
                                                {this.state.errors.country ? <span className={'errorInForm'}>{this.state.errors.country}</span> : null }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">Extra informatie</label>
                                                <textarea className="form-control" rows="5" name="extraInformation" value={this.state.newCustomer.extraInformation} onChange={this.changeUserField}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="col-sm-6 col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">E-mail</label>
                                                <input className="form-control" type="text" name="primaryEmail" value={this.state.newCustomer.primaryEmail} onChange={(event) => {event.persist();this.changeUserField(event)}} />
                                                {this.state.errors.primaryEmail ? <span className={'errorInForm'}>{this.state.errors.primaryEmail}</span> : null }
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">Telefoonnummer</label>
                                                <input className="form-control" type="text" name="primaryPhone" value={this.state.newCustomer.primaryPhone} onChange={this.changeUserField} />
                                                {this.state.errors.primaryPhone ? <span className={'errorInForm'}>{this.state.errors.primaryPhone}</span> : null }
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">Mobiel nummer</label>
                                                <input className="form-control" type="text" name="primaryMobile" value={this.state.newCustomer.primaryMobile} onChange={this.changeUserField} />
                                                {this.state.errors.primaryMobile ? <span className={'errorInForm'}>{this.state.errors.primaryMobile}</span> : null }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="col-sm-6 col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">E-mail (alternatief)</label>
                                                <input className="form-control" type="text" name="alternativeEmail" value={this.state.newCustomer.alternativeEmail} onChange={this.changeUserField} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">Telefoonnummer (alternatief)</label>
                                                <input className="form-control" type="text" name="alternativePhone" value={this.state.newCustomer.alternativePhone} onChange={this.changeUserField} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">Mobiel nummer (alternatief)</label>
                                                <input className="form-control" type="text" name="alternativeMobile" value={this.state.newCustomer.alternativeMobile} onChange={this.changeUserField} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={'col-md-12'}>
                                            <input type={'checkbox'} key={'isBusinessUser'} name={'isBusinessUser'} defaultChecked={this.state.isBusinessUser} onChange={this.changeIsBusinessUser} /> Is een zakelijke gebruiker
                                        </div>
                                    </div>
                                    {this.state.newCustomer.isBusinessUser ? (
                                        <>
                                            <div className={"row"}>
                                                <div className="col-sm-6 col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Bedrijfsnaam</label>
                                                        <input className="form-control" type="text" name="companyName" value={this.state.newCustomer.invoiceData.companyName} onChange={this.changeInvoiceDataField} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Ondernemingsnummer (zonder BE, NL, ...)</label>
                                                        <input className="form-control" type="text" name="cbeNumber" value={this.state.newCustomer.invoiceData.cbeNumber} onChange={this.changeInvoiceDataField} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                    <div className={'row'}>
                                        <div className={'col-md-12'}>
                                            <input type={'checkbox'} key={'useCustomerInfoAsInvoiceData'} name={'useCustomerInfoAsInvoiceData'} defaultChecked={this.state.newCustomer.useAddressOfUser} onChange={this.changeUseCustomerInfoAsInvoiceData} /> Adresgegevens als factuuradres gebruiken
                                        </div>
                                    </div>
                                    {this.state.newCustomer.useAddressOfUser ? null : (
                                        <div>
                                            <div className={"row"}>
                                                <div className="col-xs-12 col-sm-12 col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Straat</label>
                                                        <input className="form-control" type="text" name="street" value={this.state.newCustomer.invoiceData.street} onChange={this.changeInvoiceDataField} />
                                                        {this.state.errors.invoiceData.street ? <span className={'errorInForm'}>{this.state.errors.invoiceData.street}</span> : null }
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-6 col-md-3">
                                                    <div className="form-group">
                                                        <label className="form-label">Huisnummer</label>
                                                        <input className="form-control" type="text" name="houseNumber" value={this.state.newCustomer.invoiceData.houseNumber} onChange={this.changeInvoiceDataField} />
                                                        {this.state.errors.invoiceData.houseNumber ? <span className={'errorInForm'}>{this.state.errors.invoiceData.houseNumber}</span> : null }
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-6 col-md-3">
                                                    <div className="form-group">
                                                        <label className="form-label">Bus</label>
                                                        <input className="form-control" type="text" name="bus" value={this.state.newCustomer.invoiceData.bus} onChange={this.changeInvoiceDataField} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"row"}>
                                                <div className="col-sm-6 col-md-3">
                                                    <div className="form-group">
                                                        <label className="form-label">Postcode</label>
                                                        <input className="form-control" type="text" name="postalCode" value={this.state.newCustomer.invoiceData.postalCode} onChange={this.changeInvoiceDataField} />
                                                        {this.state.errors.invoiceData.postalCode ? <span className={'errorInForm'}>{this.state.errors.invoiceData.postalCode}</span> : null }
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Gemeente</label>
                                                        <input className="form-control" type="text" name="city" value={this.state.newCustomer.invoiceData.city} onChange={this.changeInvoiceDataField} />
                                                        {this.state.errors.invoiceData.city ? <span className={'errorInForm'}>{this.state.errors.invoiceData.city}</span> : null }
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="form-group">
                                                        <label className="form-label">Land</label>
                                                        {this.state.countries ? (
                                                            <Typeahead
                                                                id="project-typeahead"
                                                                name="country"
                                                                onChange={this.changeInvoiceCountry}
                                                                clearButton
                                                                labelKey="name"
                                                                selected={this.state.newCustomer.invoiceData.country ? [this.state.newCustomer.invoiceData.country] : []}
                                                                multiple={false}
                                                                options={this.state.countries}
                                                                placeholder="Selecteer een land ..."
                                                            />
                                                        ) : null}
                                                        {this.state.errors.invoiceData.country ? <span className={'errorInForm'}>{this.state.errors.invoiceData.country}</span> : null }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) :(
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <Typeahead
                                        ref="customerSelector"
                                        labelKey="name"
                                        multiple={false}
                                        onChange={this.changeCustomer}
                                        options={this.state.customers}
                                        placeholder="Kies een klant ..."
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <br />
                    <br />
                </div>
                <br />
                <br />
            </Fragment>
        );
    }
};

export default CustomerInformation;