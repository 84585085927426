import React, { useState, useEffect, useMemo } from 'react';
import {Col, Row} from "reactstrap";
import API from '../../../utils/API';
import _ from "lodash";
import CKEditors from "react-ckeditor-component";
import { Trash2 } from 'react-feather';
import ReactTable from 'react-table-6';
import useReactTable from '../../../hooks/useReactTable';
import Loader from '../../common/loader';

const ProjectTodos = (props) =>  {

    const [project, setProject] = useState(props.project);
    const [todoItems, setTodoItems] = useState([]);
    const [todoItem, setTodoItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const columns = useMemo(() => [
        {
            Header : 'Item',
            accessor : 'itemName',
            width: 150,
            Cell : cell => (
                <div>
                    <a href={`${process.env.PUBLIC_URL}/offer/detail/` + cell.original.id}>{cell.value}</a>
                </div>
            )
        },
        {
            Header : 'Beschrijving',
            accessor : 'description',
            Cell : cell => (
                <div>
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header : 'Status',
            accessor : 'status',
            width: 100,
            Cell : cell => (
                <div>
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header : 'Vervaldatum',
            accessor : 'dueDate',
            width: 150,
            Cell : cell => (
                <div>
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header : '',
            accessor : 'id',
            width: 200,
            Cell : cell => (
                <div>
                    {cell.original.status === 'new' ? (
                        <>
                            <a href={'#'} onClick={() => changeTodoItemStatus(cell.value, 'ongoing')}>Start</a>
                            <a href={'#'} onClick={() => changeTodoItemStatus(cell.value, 'done')}>Beëindig</a>
                        </>
                    ) : null}
                    {cell.original.status === 'ongoing' ? (
                        <>
                            <a href={'#'} onClick={() => changeTodoItemStatus(cell.value, 'done')}>Beëindig</a>
                            <a href={'#'} onClick={() => changeTodoItemStatus(cell.value, 'new')}>Annuleer</a>
                        </>
                    ) : null}
                </div>
            )
        },
        {
            Header : '',
            accessor : 'id',
            width: 50,
            Cell : cell => (
                
                    <>
                        <a href={'#'}  onClick={() => deleteTodoItem(cell.value)}><Trash2 /></a>
                    </>
            )
        }
    ]);

    useEffect(() => {
        setTodoItem({itemName: "", description: "Beschrijving", priority: "medium", dueDate : ""})
    }, []);

    useEffect(() => {
        if (project !== null) {
            API.get('/project/' + project.id + '/todoitems').then(function(response) {
                setTodoItems(response.data);
            });
        }
    }, [project]);

    const addTodoItem = () => {
        API.post('/project/' + project.id + '/todoitem', {
            todoItem
        }).then(function() {
            API.get('/project/' + project.id + '/todoitems').then(function(response) {
                setTodoItems(response.data);
            });
        });
    };

    const changeTodoItemStatus = (todoItemId, status) => {
        setLoading(true);
        API.put('/project/' + project.id + '/todoitem/' + todoItemId + "/changestatus", {
            status
        }).then(function() {
            API.get('/project/' + project.id + '/todoitems').then(function(response) {
                setTodoItems(response.data);
                setLoading(false);
            });
        });
    }

    const deleteTodoItem = (todoItemId) => {
        API.delete('/project/' + project.id + '/todoitem/' + todoItemId).then(function() {
            API.get('/project/' + project.id + '/todoitems').then(function(response) {
                setTodoItems(response.data);
            });
        });
    }

    return (

        loading ? <Loader /> : (
<>
            <Row className={'mt-3'}>
                <Col lg={12}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <br />
                            
                            {project && todoItems !== undefined ? (
                                <ReactTable 
                                    data={todoItems}
                                    columns={columns}
                                    minRows={0}
                                    defaultPageSize={10}
                                    className={"-striped -highlight"}
                                    showPagination={true}
                                    previousText={'Vorige'}
                                    nextText={'Volgende'}
                                    pageText={'Pagina'}
                                    rowsText={'rijen'}
                                    ofText={'van'}
                                    getTheadThProps={getTheadThProps}
                                    getTdProps={getTdProps}
                                    getTrProps={getTrProps}
                                    getPaginationProps={getPaginationProps}
                                />
                            ) : (
                                <p>Er zijn geen offerten gedefinieerd voor dit project.</p>
                            )}
                            
                        </div>
                    </div>
                    {todoItem !== null && todoItem !== undefined ? (
                        <form>
                        <div className='row form-group'>
                            <label className='col-md-2'>Naam:</label>
                            <div className='col-md-10'>
                                <input className={'form-control'} name={'itemName'} type={'text'} value={todoItem.itemName} onChange={({target: {value}}) => {todoItem.itemName = value; setTodoItem({...todoItem});}} />
                            </div>
                        </div>
                        <div className='row form-group'>
                        <label className={'col-xl-2'}>Beschrijving</label>
                            <div className='col-md-10'>
                                <textarea className={'form-control'} name={'description'} onChange={({target: {value}}) => {todoItem.description = value; setTodoItem({...todoItem});}}>
                                    {todoItem.itemName}
                                </textarea>
                            
                            </div>
                        </div>
                        <div className={'row form-group'}>
                            <label className={'col-xl-2'}>Prioriteit</label>
                            <div className={'col-xl-6'}>
                                <select className={'form-control'} value={todoItem.priority} name={'priority'} onChange={({target: {value}}) => {todoItem.priority = value; setTodoItem({...todoItem});}}>
                                    <option value='low'>Laag</option>
                                    <option value='medium'>Medium</option>
                                    <option value='high'>Hoog</option>
                                </select>
                            </div>
                        </div>
                        <div className='row form-group'>
                            <label className='col-md-2'>Vervaldatum:</label>
                            <div className='col-md-10'>
                                <input className={'form-control'} name={'dueDate'} type={'date'} value={todoItem.dueDate} onChange={({target: {value}}) => {todoItem.dueDate = value; setTodoItem({...todoItem});}} />
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className='col-md-12'>
                                <a href='#' className="btn btn-primary" type="submit" onClick={() => addTodoItem()}>Todo toevoegen</a>
                            </div>
                        </div>
                    </form>
                    ) : null}
                    <br />
                </Col>
            </Row>
        </>
        )
    );
};

export default ProjectTodos;