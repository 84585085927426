import React, { Fragment, useEffect, useState, useMemo } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import {Typeahead} from "react-bootstrap-typeahead";
import SweetAlert from "react-bootstrap-sweetalert";
import CKEditors from "react-ckeditor-component";
import CurrencyInput from "react-currency-input";
import Loader from "../common/loader";
import _ from "lodash";
import ReactTable from "react-table-6";
import {Link, Redirect, useHistory} from "react-router-dom";
import {Input, Row, Col} from "reactstrap";
import Stepper from 'react-stepper-horizontal';
import { useForm, useFormContext } from 'react-hook-form';
import { Card, CardBody } from 'reactstrap';
import useReactTable from '../../hooks/useReactTable';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';


const WorkOrderCreate = () =>  {
    let history = useHistory();
    const {register, setValue} = useForm();
    const [workOrderName, setWorkOrderName] = useState("");
    const [workOrderDescription, setWorkOrderDescription] = useState("");
    const [workOrderItems, setWorkOrderItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [projects, setProjects] = useState([]);
    const [workOrderTemplates, setWorkOrderTemplates] = useState([]);
    const [selectedWorkOrderTemplate, setSelectedWorkOrderTemplate] = useState(null);
    const [filtered, setFiltered] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const [workOrderScans, setWorkOrderScans] = useState([]);
    const [workOrderPreviews, setWorkOrderPreviews] = useState([]);
    const [loadingToSave, setLoadingToSave] = useState(false);
    
    const columns = useMemo(() => [{
        Header : 'PR #',
        accessor : 'projectNumber',
        headerClassName : 'text-left',
        Cell: row => {
            return <span>{row.row.projectNumber}</span>
        }
    },
    {
        Header : 'Project',
        accessor : 'name',
        headerClassName : 'text-left',
    },
    {
        Header : 'Klant',
        headerClassName : 'text-left',
        accessor : 'customer',
        Cell : row => {
            return <>{row.row.customer !== null ? (
                <Link to={'/user/' + row.row.customer.id + '/detail'} target={'_parent'}>{row.row.customer.firstName} {row.row.customer.lastName}</Link>
            ): ''}</>
        }
    },
    {
        id: 'address',
        Header : 'Adres',
        headerClassName : 'text-left',
        accessor : project => {
            const country = project.country !== undefined && project.country !== null ? project.country.name : '';
            return `${project.street} ${project.houseNumber} ${project.bus}, ${project.postalCode} ${project.city} ${country}`;
        }
    },
    {
        id: 'id',
        Header : '',
        headerClassName : 'text-left',
        accessor : project => {
            return <Link onClick={(e) => selectProject(e, project.id)} className='btn btn-primary'>Selecteer</Link>;
        }
    }]);
    const sections = [
        {title: "Projectgegevens"},
        {title: "Foto's vooraf"},
        {title: "Werkbongegevens"},
        {title: "Upload van notities"}
    ];

    const selectProject = (e, id) => {
        e.preventDefault();
        projects.map((project) => {
            if (project.id == id) {
                setSelectedProject(project);
            }
        })
    };

    const handleSubmit = (e) => {
        setLoadingToSave(true);
        e.preventDefault();

        API.post('workorder', {
            selectedProjectId : selectedProject.id,
            workOrderName,
            workOrderDescription,
            workOrderItems
        }).then(function({data}) {
            let workOrderId = data.id;

            workOrderPreviews.map(async (workOrderPreview) => {
                await API.post('workorder/' + workOrderId + '/workorderpreview', {workOrderPreview});
            });

            workOrderScans.map(async (workOrderScan) => {
                await API.post('workorder/' + workOrderId + '/workorderscan', {workOrderScan});
            });
        });
    }

    const next = () => setCurrentPage((prev) => {
        if (prev === 0) {
            if (selectedProject !== null) {
                return prev + 1;
            } else {
                return prev;
            }
        }
        return prev + 1;
    });
    const prev = () => setCurrentPage((prev) => prev - 1);

    const loadWorkOrderTemplates = () => {
        API.get('workordertemplates').then(function(response) {
            setWorkOrderTemplates(response.data);
        });
    };

    const handleChange = event => {
        setSearchInput(event.target.value);
    };

    const handleWorkOrderItem = event => {
        let items = [...workOrderItems];
        let found = false;
        items.map((item, index) => {
            if (item.id == event.target.name) {
                items[index] = {
                    id: event.target.name,
                    value: event.target.value
                };
                found = true;
            }
        });
        if (!found) {
            items = [...items, {
                id: event.target.name,
                value: event.target.value
            }];
        }
        
        setWorkOrderItems(items);
    }

    const globalSearch = () => {
        API.get('projects/search?search_input=' + searchInput).then(function(response) {
            setProjects(response.data);
            setFiltered(response.data);
        });
    };

    const onChangeWorkOrderTemplate = (event) => {
        const workOrderTemplateId = event.target.value;
        workOrderTemplates.map((workOrderTemplate) => {
            if (workOrderTemplateId == workOrderTemplate.id) {
                setSelectedWorkOrderTemplate(workOrderTemplate);
            }
        });
        
    };

    const handleTakePhoto = (dataUri) => {
        setWorkOrderScans([...workOrderScans, dataUri]);
    };

    const handleTakePhotoForWorkOrderPreview = (dataUri) => {
        setWorkOrderPreviews([...workOrderPreviews, dataUri]);
    };

    useEffect(() => {
        if (currentPage == 2) {
            loadWorkOrderTemplates();
        }
    }, [currentPage]);

    useEffect(() => {
        if (searchInput.length > 2 ) {
            globalSearch();
        }
    }, [searchInput]);

    return (
        loadingToSave ? (
            <Loader />
        ) : (
            <Fragment>
            <Card>
                <CardBody>
                    <Stepper
                        steps={sections}
                        activeStep={currentPage}
                        activeColor={'orange'}
                        defaultBarColor={'grey'}
                        completeColor={'orange'}
                        completeBarColor={'orange'}
                        />

                    {currentPage === 0 && (
                        <>
                        {selectedProject !== null ? (
                            <div className='row mt-5'>
                                <div className='col-lg-10 offset-lg-1'>
                                    <strong>Geselecteerd project:</strong> {selectedProject.projectNumber} - {selectedProject.name}
                                </div>
                            </div>
                        ) : null}
                        
                        <div className="row mt-5">
                            <div className="col-lg-10 offset-lg-1">
                                <Input
                                    size="large"
                                    name="searchInput"
                                    value={searchInput || ""}
                                    onChange={handleChange}
                                    label="Search"
                                    placeholder={'Zoeken'}
                                />
                                <br />
                                <br />
                                <ReactTable 
                                    data={filtered}
                                    columns={columns}
                                    minRows={0}
                                    defaultPageSize={10}
                                    className={"-striped -highlight"}
                                    showPagination={true}
                                    previousText={'Vorige'}
                                    nextText={'Volgende'}
                                    pageText={'Pagina'}
                                    rowsText={'rijen'}
                                    ofText={'van'}
                                    getTheadThProps={getTheadThProps}
                                    getTdProps={getTdProps}
                                    getTrProps={getTrProps}
                                    getPaginationProps={getPaginationProps}
                                />
                            </div>
                        </div>
                        <Row className='mt-5'>
                            <Col className='col-lg-10 offset-lg-1 text-right'>
                                <button className={'btn btn-primary'} onClick={next}>Volgende stap</button>
                            </Col>
                        </Row>
                        </>
                    )}

                    {currentPage === 1 && (
                        <>
                            <Camera idealFacingMode={FACING_MODES.ENVIRONMENT} isImageMirror={false} onTakePhoto = { (dataUri) => { handleTakePhotoForWorkOrderPreview(dataUri); } } />
                            
                            <Row>
                                {workOrderPreviews.length > 0 ? (
                                    workOrderPreviews.map(workOrderPreview => {
                                        return <Col md={3}><img src={workOrderPreview} style={{width: '90%',padding:'1%'}} /></Col>
                                    })
                                ) : null}
                            </Row>

                            <Row className='mt-5'>
                                <Col className='col-lg-10 offset-lg-1 text-right'>
                                    <button className={'btn btn-primary'} onClick={prev} style={{marginRight: '25px'}}>Terug</button>
                                    <button className={'btn btn-primary'} onClick={next} style={{marginRight: '25px'}}>Volgende stap</button>
                                </Col>
                            </Row>
                        </>
                    )}  

                    {currentPage === 2 && (
                        <>
                            <Row className="mt-5">
                                <Col lg='10' className='offset-lg-1'>
                                    <h2>Selecteer sjabloon van werkbon</h2>
                                </Col>
                                <Col lg='10' className="offset-lg-1">
                                    <div>
                                        {workOrderTemplates.map((workOrderTemplate) => {
                                            return (<><Input type='radio' name='selectedWorkOrderTemplateId' value={workOrderTemplate.id} onChange={onChangeWorkOrderTemplate} /> {workOrderTemplate.name} <br /></>)
                                        })}
                                    </div>
                                </Col>
                            </Row>
                            {selectedWorkOrderTemplate ? (
                                <>
                                    <Row className="mt-5">
                                        <Col lg='10' className='offset-lg-1'>
                                            <h2>Werkbongegevens:</h2>
                                        </Col>
                                    </Row>
                                    <div className={'form-group row'}>
                                        <label className={'col-xl-12'}>
                                            Naam:
                                        </label>
                                        <div className={'col-xl-12'}>
                                            <input type={'text'} className={'form-control'} name="name" ref={register} onChange={event => setWorkOrderName(event.target.value)} />
                                        </div>
                                    </div>
                                    <div className={'form-group row'}>
                                        <label className={'col-xl-12'}>
                                            Beschrijving:
                                        </label>
                                        <div className={'col-xl-12'}>
                                            <input type={'text'} className={'form-control'} name="description" ref={register} onChange={event => setWorkOrderDescription(event.target.value)} />
                                        </div>
                                    </div>
                                    {selectedWorkOrderTemplate.items.map((item) => {
                                        return (
                                            <div className={'form-group row'}>
                                                <label className={'col-xl-12'}>
                                                    {item.question}
                                                </label>
                                                <div className={'col-xl-12'}>
                                                    {item.type.name === 'text' ? (
                                                        <textarea type={'text'} name={item.id} onChange={(event) => handleWorkOrderItem(event)} className={'form-control'} />
                                                    ) : null}
                                                    {item.type.name === 'multiplechoice' ? (
                                                        item.options.map((option) => {
                                                            return <label className={'form-control'}><input type={'radio'} name={item.id} value={option.name} onChange={(event) => handleWorkOrderItem(event)} /> {option.name}</label>
                                                        })
                                                    ) : null}
                                                </div>
                                            </div>
                                        )
                                    })}
                                    
                                </>
                            ) : null}
                            <Row className='mt-5'>
                                <Col className='col-lg-12 text-right'>
                                    <button className={'btn btn-primary'} onClick={prev} style={{marginRight: '25px'}}>Terug</button>
                                    <button className={'btn btn-primary'} onClick={next} style={{marginRight: '25px'}}>Volgende stap</button>
                                </Col>
                            </Row>
                        </>
                    )}

                    {currentPage === 3 && (
                        <>
                            <Camera idealFacingMode={FACING_MODES.ENVIRONMENT} isImageMirror={false} onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } } />
                            <Row>
                                {workOrderScans.length > 0 ? (
                                    workOrderScans.map(workOrderScan => {
                                        return <Col md={3}><img src={workOrderScan} style={{width: '90%',padding:'1%'}} /></Col>
                                    })
                                ) : null}
                            </Row>
                            <Row className='mt-5'>
                                <Col className='col-lg-10 offset-lg-1 text-right'>
                                    <button className={'btn btn-primary'} onClick={prev}>Back</button>
                                    <button className={'btn btn-primary'} onClick={handleSubmit}>Submit</button>
                                </Col>
                            </Row>
                        </>
                    )}
                </CardBody>
            </Card>
            
        </Fragment>
        )
    );
};

export default WorkOrderCreate;