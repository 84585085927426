import React, {Fragment, useState} from 'react';
import logo from '../assets/images/logo-pouillon.png';
import {toast} from "react-toastify";

const ForgetPwd = () => {
    const [email, setEmail] = useState(null);
    const requestPasswordToken = (event) => {
        event.preventDefault();
        fetch(process.env.REACT_APP_BASE_URL + '/createpasswordresettoken', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'username=' + email
        }).then(function(response) {
            if (response.status === 204) {
                return null;
            } else {
                return response.json();
            }
        }).then(function(response) {
            if (response !== null && response.error) {
                setTimeout(() => {
                    toast.error("Oppss.. Het wachtwoord is verkeerd of de gebruiker bestaat niet.");
                }, 200);
            } else {
                console.log(response);
            }
        });
    };

    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <div className="auth-innerright">
                                    <div className="reset-password-box">
                                        <div className="card mt-4 mb-0">
                                            <div>
                                                <img src={logo} alt="" />
                                            </div>
                                            <br />
                                            <form className="theme-form">
                                                <h6 className="f-14 mt-4 mb-3">VERSTUUR E-MAIL OM WACHTWOORD TE HERSTELLEN</h6>
                                                <div className="form-group">
                                                    <label className="col-form-label">E-mailadres/Gebruikersnaam</label>
                                                    <input className="form-control" type="text" onChange={(e) => setEmail(e.target.value)} />
                                                </div>
                                                <div className="form-group form-row mb-2">
                                                    <div className="col-md-2">
                                                        <a href={"#"} onClick={(e) => requestPasswordToken(e)} className="btn btn-primary" >Verstuur</a>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ForgetPwd;