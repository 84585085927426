import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {Link} from "react-router-dom";
import _ from "lodash";
import ReactTable from 'react-table-6';
import API from '../../../utils/API';
import useReactTable from '../../../hooks/useReactTable';

const DocumentsOffersOverview = () =>  {
    const [offers, setOffers] = useState([]);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const offerColumns = useMemo(() => [{
        Header : 'Offertenummer',
        accessor : 'offerNumber',
        headerClassName : 'text-left',
        width: 150,
        Cell: row => {
            return <Link to={`/offer/detail/` + row.row._original.id} target={'_parent'}>{row.row.offerNumber}</Link>
        }
    },
    {
        Header : 'Klant',
        accessor : 'customer',
        headerClassName : 'text-left',
        Cell: row => {
            return <>{row.row.customer.firstName} {row.row.customer.lastName}</>
        }
    },
    {
        Header : 'Project',
        accessor : 'project',
        headerClassName : 'text-left',
        Cell: row => {
            return <>{row.row.project != null ? <Link to={'/project/detail/' + row.row.project.id} target={'_blankk'}>{row.row.project.name}</Link> : ''}</>
        }
    },
    {
        Header : 'Status',
        accessor : 'status',
        headerClassName : 'text-left',
        Cell: row => {
            return <>{row.row.status}</>
        }
    },
    {
        Header : 'Offertedatum',
        accessor : 'creationDate',
        headerClassName : 'text-left',
        width: 150,
        Cell: row => {
            return new Intl.DateTimeFormat("nl-BE", {
                year: "numeric",
                month: "long",
                day: "2-digit"
            }).format(new Date(row.row.creationDate))
        }
    },
    {
        Header : 'Vervaldatum',
        accessor : 'expiryDate',
        headerClassName : 'text-left',
        width: 150,
        Cell: row => {
            return new Intl.DateTimeFormat("nl-BE", {
                year: "numeric",
                month: "long",
                day: "2-digit"
            }).format(new Date(row.row.expiryDate))
        }
    },
    {
        Header : () => {return (<i className="icofont icofont-2x icofont-thumbs-up"></i>)},
        accessor : 'approved',
        headerClassName : 'text-center',
        className: 'text-center',
        Cell: row => {
            return <>{row.row.approved ? <strong><i className="icofont icofont-tick-mark"></i></strong> : <strong><i className="icofont icofont-close"></i></strong>}</>
        }
    },
    {
        Header : () => {return (<i className="icofont icofont-2x icofont-thumbs-down"></i>)},
        accessor : 'refused',
        headerClassName : 'text-center',
        className: 'text-center',
        Cell: row => {
            return <>{row.row.refused ? <strong><i className="icofont icofont-tick-mark"></i></strong> : <strong><i className="icofont icofont-close"></i></strong>}</>
        }
    },
    {
        Header: '',
        accessor : 'id',
        width: 75,
        headerClassName: 'text-center',
        Cell: row => {
            return <>
                    <Link onClick={(event) => approveOffer(event, row.row.id)}><i className="icofont icofont-2x icofont-tick-mark"></i></Link>
                </>
        }
    },
    {
        Header: '',
        accessor : 'id',
        width: 75,
        headerClassName: 'text-center',
        Cell: row => {
            return <>
                <Link onClick={(event) => rejectOffer(event, row.row.id)}><i className="icofont icofont-2x icofont-close"></i></Link>
            </>
        }
    },
    {
        Header: '',
        accessor : 'id',
        width: 75,
        headerClassName: 'text-center',
        Cell: row => {
            return <>
                <Link onClick={(event) => resetOffer(event, row.row.id)}><i className="icofont icofont-2x icofont-undo"></i></Link>
            </>
        }
    },
    {
        Header: '',
        accessor: 'id',
        width: 75,
        headerClassName: 'text-center',
        Cell: row => {
            return <Link><i className="icofont icofont-2x icofont-email"></i></Link>
        }
    },
    {
        Header: '',
        accessor: 'id',
        width: 75,
        headerClassName: 'text-center',
        Cell: row => {
            return <Link onClick={(event) => downloadPdf(event, row.row.id, row.row._original.offerNumber)}><i className="icofont icofont-2x icofont-download"></i></Link>
        }
    }]);


    useEffect(() => {
        loadOffers();
    }, []);

    const loadOffers = () => {
        API.get('offers').then(function(response) {
            setOffers(response.data);
        });
    };

    const downloadPdf = (event, offerId, offerNumber) => {
        event.preventDefault();
        API.get('/pdf/offer/' + offerId).then(function(response) {
            const linkId = `link-invoice-id-${offerId}`;
            const link = document.createElement("a");
            link.id = linkId;
            link.innerHTML = "Download PDF file";
            link.download = `${offerNumber}.pdf`;
            link.href = "data:application/octet-stream;base64," + response.data.base64;
            document.body.appendChild(link);
            document.getElementById(linkId).click();
        });

        return false;
    };

    const approveOffer = (event, offerId) => {
        event.preventDefault();
        let me = this;
        API.put('offer/' + offerId + '/approve').then(function() {
            API.get('offers').then(function(response) {
                setOffers(response.data);
            });
        });
    }

    const resetOffer = (event, offerId) => {
        event.preventDefault();
        let me = this;
        API.put('offer/' + offerId + '/cancelapprovereject').then(function() {
            API.get('offers').then(function(response) {
                setOffers(response.data);
            });
        });
    }

    const rejectOffer = (event, offerId) => {
        event.preventDefault();
        let me = this;
        API.put('offer/' + offerId + '/reject').then(function() {
            API.get('offers').then(function(response) {
                setOffers(response.data);
            });
        });
    }

    return (
        <>
            {offers.length > 0 ? (
                <ReactTable 
                    data={offers}
                    columns={offerColumns}
                    defaultPageSize={10}
                    className={"-striped -highlight"}
                    showPagination={true}
                    showPagination={true}
                    previousText={'Vorige'}
                    nextText={'Volgende'}
                    pageText={'Pagina'}
                    rowsText={'rijen'}
                    ofText={'van'}
                    getTheadThProps={getTheadThProps}
                    getTdProps={getTdProps}
                    getTrProps={getTrProps}
                    getPaginationProps={getPaginationProps}
            />
            ) : (
                <p>Er zijn geen offerten aanwezig in het systeem</p>
            )}
        </>
    );
};

export default DocumentsOffersOverview;