import React, { Fragment, useMemo, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import seven from '../../assets/images/other-images/sidebar-bg.jpg';
import profileImg from '../../assets/images/other-images/sidebar-bg.jpg';
import blog from '../../assets/images/other-images/sidebar-bg.jpg';
import {getUser} from "../../services/login.service";
import man from '../../assets/images/user/user.png'
import API from "../../utils/API";
import {Link} from "react-router-dom";
import ReactTable from "react-table-6";
import _ from "lodash";
import { Loader } from 'react-feather';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { instagramTabsStylesHook } from '@mui-treasury/styles/tabs';
import UserEdit from './userEdit';
import {Typeahead} from "react-bootstrap-typeahead";


const UserDetail = (props) => {

    const [editModusLoginData, setEditModusLoginData] = useState(false);
    const [editModusContactData, setEditModusContactData] = useState(false);
    const [editModusGeneralInformation, setEditModusGeneralInformation] = useState(false);
    const [editModusInvoiceData, setEditModusInvoiceData] = useState(false);

    const [tabValue, setTabValue] = useState(0);
    const tabItemStyles = instagramTabsStylesHook.useTabItem({bgColor: '#ececec', selectedBgColor: '#dc6809'});

    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);

    const [loginUser, setLoginUser] = useState(null);
    const [baseUser, setBaseUser] = useState(null);
    const [contactUser, setContactUser] = useState(null);
    const [invoicingUser, setInvoicingUser] = useState(null);
    const [countries, setCountries] = useState([]);

    const [inEditView, setInEditView] = useState(false);

    const [projects, setProjects] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [taxCertificates, setTaxCertificates] = useState([]);
    const onChangeEmail = ({target : {value}}) => {setLoginUser(prevUser => ({...prevUser, email : value}))};
    const onChangeBaseUser = ({target : {name, value}}) => {setBaseUser(prevUser => ({...prevUser, [name] : value}))};
    const onChangeContactUser = ({target : {name, value}}) => {setContactUser(prevUser => ({...prevUser, [name] : value}))};
    const onChangeInvoicingUser = ({target : {name, value}}) => {setInvoicingUser(prevUser => ({...prevUser, invoiceData : {...prevUser.invoiceData, [name] : value}}))};
    const changeIsEnabled = ({target : {checked}}) => {setLoginUser(prevUser => ({...prevUser, enabled : checked}))};
    const changeIsBusinessAccount = ({target : {name, checked}}) => {setInvoicingUser(prevUser => ({...prevUser, invoiceData : {...prevUser.invoiceData, [name] : checked}}))};
    const changeBaseCountry = (selectedArray) => {setContactUser(prevUser => ({...prevUser, country : selectedArray[0]}))};
    const changeInvoiceCountry = (selectedArray) => {setInvoicingUser(prevUser => ({...prevUser, invoiceData : {...prevUser.invoiceData, country : selectedArray[0]}}))};
    const taxCertificateColumns = useMemo(() => [
        {
            Header : 'Aangemaakt op',
            accessor : 'createdAt',
            width: 250,
            Cell: row => {
                if (_.isEmpty(row.row.createdAt)) {
                    return <span></span>
                } else {
                    return new Intl.DateTimeFormat("nl-BE", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit"
                    }).format(new Date(row.row.createdAt))
                }
            }
        },
        {
            Header : 'Aangemaakt door',
            accessor : 'createdBy',
            Cell: row => {
                if (_.isEmpty(row.row.createdBy)) {
                    return <span></span>
                } else {
                    return <span>{row.row.createdBy.firstName} {row.row.createdBy.lastName} </span>
                }
            }
        },
        {
            Header : 'Facturen',
            accessor : 'includedInvoices',
            Cell: row => {
                if (_.isEmpty(row.row.includedInvoices)) {
                    return <span></span>
                } else {
                    var includedInvoicesString = "";
                    for (let index in row.row.includedInvoices) {
                        if (index > 0 ) {
                            includedInvoicesString += ", ";
                        }
                        includedInvoicesString += row.row.includedInvoices[index].id;
                    }
                    return includedInvoicesString;
                }
            }
        },
        {
            Header : 'Download',
            accessor: 'id',
            Cell : row => {
                return <button onClick={(event) => {downloadTaxCertificate(event, row.row._original.id)}}><span className="fa fa-download"></span></button>
            }
        }
    ]);
    const projectColumns = useMemo(
        () => [
            {
                Header : 'Project',
                accessor : 'name',
                width: 250,
                Cell: row => {
                    return <Link to={`/project/detail/` + row.row._original.id} target={'_parent'}>{row.row.name}</Link>
                }
            },
            {
                Header : 'Beschrijving',
                accessor : 'shortDescription'
            },
            {
                id: 'address',
                Header : 'Adres',
                accessor : project => `${project.street} ${project.houseNumber} ${project.bus}`
            },
            {
                Header : 'Postcode',
                accessor : 'postalCode'
            },
            {
                Header : 'Postcode',
                accessor : 'city'
            },
            {
                Header : 'Land',
                accessor : 'country',
                Cell: row => {
                    if (row.row.country !== undefined && row.row.country !== null) {
                        return <span>{row.row.country.name}</span>
                    } else {
                        return <span></span>
                    }
                }
            }
        ]
    );
    const invoiceColumns = useMemo(() => [
        {
            Header : 'Naam',
            accessor : 'name',
            width: 250,
            Cell: row => {
                return <Link to={`/task/detail/` + row.row._original.id} target={'_parent'}>{row.row.name}</Link>
            }
        },
        {
            Header : 'Klant',
            accessor : 'customer',
            Cell: row => {
                if (row.row.customer !== undefined && row.row.customer !== null) {
                    return <span>{row.row.customer.firstName} {row.row.customer.lastName}</span>
                } else {
                    return <span></span>
                }
            }
        },
        {
            Header : 'Project',
            accessor : 'project',
            Cell: row => {
                if (row.row.project !== undefined && row.row.project !== null) {
                    return <span>{row.row.project.name}</span>
                } else {
                    return <span></span>
                }
            }
        },
        {
            Header : 'Werkbon',
            accessor : 'workOrder',
            Cell: row => {
                if (row.row.workOrder !== undefined && row.row.workOrder !== null) {
                    return <span>{row.row.workOrder.name}</span>
                } else {
                    return <span></span>
                }
            }
        },
        {
            Header : 'Prijs excl',
            headerClassName: 'text-right',
            accessor : 'totalPriceExcl',
            className : 'text-right',
            Cell: row => {
                return Intl.NumberFormat('nl-BE', {
                    style: 'currency',
                    currency: 'EUR'
                }).format(row.row.totalPriceExcl);
            }
        },
        {
            Header : 'BTW',
            headerClassName: 'text-right',
            accessor : 'totalVatAmount',
            className : 'text-right',
            Cell: row => {
                return Intl.NumberFormat('nl-BE', {
                    style: 'currency',
                    currency: 'EUR'

                }).format(row.row.totalVatAmount);
            }
        },
        {
            Header : 'Prijs incl',
            headerClassName: 'text-right',
            accessor : 'totalPriceIncl',
            className : 'text-right',
            Cell: row => {
                return Intl.NumberFormat('nl-BE', {
                    style: 'currency',
                    currency: 'EUR'
                }).format(row.row.totalPriceIncl);
            }
        }
    ]);

    useEffect(() => {
        const { id } = props.match.params;
        API.get('user/' + id).then(function(response) {
            setUser(response.data);
            setContactUser(response.data);
            setInvoicingUser(response.data);
            setBaseUser(response.data);
            setLoginUser(response.data);
        });

        API.get('user/' + id +  '/uploadedinvoices').then(function(response) {
           setInvoices(response.data);
        });

        API.get('user/' + id + '/taxcertificates').then(function(response) {
            setTaxCertificates(response.data);
        });

        API.get('user/' + id + '/projects').then(function(response) {
           setProjects(response.data);
        });
        API.get('config/countries').then(function(response) {
            setCountries(response.data);
        });
    }, []);

    useEffect(() => {
        if (user !== null) {
            setIsLoading(false);
        }
    }, [user]);

    const downloadTaxCertificate = (event, taxCertificateId) => {
        event.preventDefault();
        let me = this;
        API.get('/pdf/taxcertificate/' + taxCertificateId).then(function(response) {
            const linkId = `link-invoice-id-${taxCertificateId}`;
            const link = document.createElement("a");
            link.id = linkId;
            link.innerHTML = "Download PDF file";
            link.download = `${taxCertificateId}.pdf`;
            link.href = "data:application/octet-stream;base64," + response.data.base64;
            document.body.appendChild(link);
            document.getElementById(linkId).click();
        });

        return false;
    };

    const saveContactData = () => {
        const { id } = props.match.params;
        API.put('user/' + id + '/contact', {
            primaryEmail : contactUser.primaryEmail,
            primaryPhone : contactUser.primaryPhone,
            primaryMobile : contactUser.primaryMobile,
            alternativeEmail : contactUser.alternativeEmail,
            alternativePhone : contactUser.alternativePhone,
            alternativeMobile : contactUser.alternativeMobile
        }).then(function() {
            setEditModusContactData(false);
        });
    };

    const saveInvoicingData = () => {
        const { id } = props.match.params;
        if (invoicingUser.invoiceData) {
            API.put('user/' + id + '/invoicedata', {
                isBusinessAccount : invoicingUser.invoiceData.businessAccount,
                organisationName : invoicingUser.invoiceData.organisationName,
                vatNumber : invoicingUser.invoiceData.vatNumber,
                street : invoicingUser.invoiceData.street,
                houseNumber : invoicingUser.invoiceData.houseNumber,
                bus : invoicingUser.invoiceData.bus,
                postalCode : invoicingUser.invoiceData.postalCode,
                city : invoicingUser.invoiceData.city,
                country : invoicingUser.invoiceData.country ? invoicingUser.invoiceData.country.id : 0
            }).then(function() {
                setEditModusInvoiceData(false);
            });
        }
    };

    const saveLoginData = () => {
        const { id } = props.match.params;
        API.put('user/' + id + '/login', {
            username : loginUser.username,
            canLogIn : loginUser.enabled,
            colorCode: loginUser.colorCode
        }).then(function() {
            setEditModusLoginData(false);
        });
    };

    const saveBaseData = () => {
        const { id } = props.match.params;
        API.put('user/' + id + '/base', {
            firstName : baseUser.firstName,
            lastName : baseUser.lastName,
            street : baseUser.street,
            houseNumber : baseUser.houseNumber,
            bus : baseUser.bus,
            postalCode : baseUser.postalCode,
            city : baseUser.city,
            country : baseUser.country ? baseUser.country.id : 0,
            extraInformation : baseUser.extraInformation
        }).then(function() {
            setEditModusGeneralInformation(false);
        });
    };


    return (
        isLoading ? (
            <Loader />
        ) : (
            user != null ? (
                <>
                    <Breadcrumb parent="Gebruikers" parentLink={'/users/overview'} title="Gebruikersprofiel">
                        {user.firstName + ' ' + user.lastName}
                    </Breadcrumb>

                    <Row>
                        <Col lg={3}>
                            <Card style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                <CardBody>
                                    <Row>
                                        <Col lg={12} className='text-center'>
                                            <h2><i className="icofont icofont-2x icofont-ui-user" style={{ backgroundColor: 'white', color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR, display: 'inline-block', borderRadius: '100px', boxShadow:' 0 0 2px #888', padding: '0.5em 0.6em'}}></i></h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className='text-center'>
                                            <h2>{user.firstName + ' ' + user.lastName}</h2>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col lg={12} className='text-center'>
                                            <h4>Adresgegevens</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className='text-center'>
                                            <p>
                                                {user.street + ' ' + user.houseNumber + ' ' + user.bus} <br />
                                                {user.postalCode + ' ' + user.city}<br />
                                                {user.country ? (
                                                    <>{user.country.name}</>
                                                ) : null}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className='text-center'>
                                            <h4>Contactgegevens</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className='text-center'>
                                            {user.primaryEmail ? (
                                                <div>
                                                    <h6><i className="fa fa-envelope"></i>   {user.primaryEmail}</h6>
                                                </div>
                                            ) : null}
                                            {user.primaryPhone ? (
                                                <div>
                                                    <h6><i className="fa fa-phone"></i>   {user.primaryPhone}</h6>
                                                </div>
                                            ) : null}
                                            {user.primaryMobile ? (
                                                <div>
                                                    <h6><i className="fa fa-mobile-phone"></i>   {user.primaryMobile}</h6>
                                                </div>
                                            ) : null}
                                            {user.alternativePhone ? (
                                                <div>
                                                    <h6><i className="fa fa-phone"></i>   {user.alternativePhone}</h6>
                                                </div>
                                            ) : null}
                                            {user.alternativeMobile ? (
                                                <div>
                                                    <h6><i className="fa fa-mobile-phone"></i>   {user.alternativeMobile}</h6>
                                                </div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className='text-center'>
                                            <h4>Facturatiegegevens</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className='text-center'>
                                            {user && user.invoiceData ? (
                                                user.invoiceData.isBusinessUser ? (
                                                    <>
                                                        {user.invoiceData.companyName}<br />
                                                        {user.invoiceData.vatNumber} <br />
                                                        ({user.firstName} {user.lastName})
                                                    </>
                                                ) : (
                                                    <>{user ? user.firstName : ''} {user ? user.lastName : ''}</>
                                                )
                                            ) : null}
                                            {user && user.invoiceData ? (
                                                <p>
                                                    {user.invoiceData.street + ' ' + user.invoiceData.houseNumber + ' ' + user.invoiceData.bus} <br />
                                                    {user.invoiceData.postalCode + ' ' + user.invoiceData.city}<br />
                                                    {user.invoiceData.country ? (
                                                        <>{user.invoiceData.country.name}</>
                                                    ) : null}
                                                </p>
                                            ) : (
                                                <span>Geen adresgegevens beschikbaar</span>
                                            )}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={9}>
                            <Row>
                                <Col lg={12}>
                                <Toolbar style={{ minHeight: 72 }}>
                                    <Tabs 
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        value={tabValue} 
                                        onChange={(e, index) => setTabValue(index)}
                                        >
                                            <Tab classes={tabItemStyles} label={"Algemeen"}></Tab>
                                            <Tab classes={tabItemStyles} label={"Projecten"}></Tab>
                                            <Tab classes={tabItemStyles} label={"Facturen"}></Tab>
                                            <Tab classes={tabItemStyles} label={"BTW-attesten"}></Tab>
                                    </Tabs>
                                </Toolbar>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                {tabValue === 0 ? (
                                    <>
                                        {inEditView ? (
                                            null
                                        ) : (
                                            <>
                                                <Row>
                                                    <Col lg={4}>
                                                        <Card>
                                                            <CardBody>
                                                            <Row>
                                                                <Col lg={12} className='text-right'>
                                                                    {editModusLoginData ? (
                                                                        <>
                                                                            <Button onClick={() => setEditModusLoginData(!editModusLoginData)}>Annuleren</Button>
                                                                            &nbsp;&nbsp;&nbsp;
                                                                            <Button onClick={() => saveLoginData()}>Opslaan</Button>
                                                                        </>
                                                                        
                                                                    ) : (
                                                                        <Button onClick={() => setEditModusLoginData(!editModusLoginData)}>Wijzig</Button>
                                                                    )}
                                                                
                                                                </Col>
                                                            </Row>
                                                            {editModusLoginData ? (
                                                                <>
                                                                    {loginUser ? (
                                                                        <form style={{width: '100%'}}>
                                                                            <div className="row mb-2">
                                                                                <div className="col-auto"><img className="img-70 rounded-circle" alt="" src={seven} /></div>
                                                                                <div className="col">
                                                                                    <h3 className="mb-1">{loginUser.firstName} {loginUser.lastName}</h3>
                                                                                    <p className="mb-4">DESIGNER</p>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className={'form-group'}>
                                                                                <label className="form-label"><input type={'checkbox'} name={'enabled'} defaultChecked={loginUser.enabled} onChange={changeIsEnabled}  /> Kan inloggen </label>
                                                                            </div>
                                                                            {loginUser.enabled ? (
                                                                                <>
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">E-mailadres</label>
                                                                                        <input className="form-control" value={loginUser.email} onChange={onChangeEmail} />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Kleurcode</label>
                                                                                        <input className="form-control" value={loginUser.colorCode} onChange={({target : {value}}) => {setLoginUser(prevUser => ({...prevUser, colorCode : value}))}} />
                                                                                    </div>
                                                                                </>
                                                                            ) : null}
                                                                        </form>
                                                                    ) : null}    
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {loginUser ? (
                                                                        <>
                                                                            Type: {loginUser.userRole}<br /><br />
                                                                            {loginUser.enabled ? (
                                                                                <>
                                                                                    Gebruiker kan inloggen met onderstaande inloggegevens
                                                                                    <br />
                                                                                    <br />
                                                                                    Gebruikersnaam: {loginUser.email}<br />
                                                                                    Kleurcode: {loginUser.colorCode}
                                                                                </>
                                                                            ) : (
                                                                                <>Gebruiker heeft <strong>geen</strong> inloggegevens en kan dus <strong>niet</strong> inloggen in dit systeem.</>
                                                                            )}
                                                                        </>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                                
                                                            </CardBody>
                                                        </Card>
                                                        <Card>
                                                            <CardBody>
                                                                <Row>
                                                                    <Col lg={12} className='text-right'>
                                                                        {editModusContactData ? (
                                                                            <>
                                                                                <Button onClick={() => setEditModusContactData(!editModusContactData)}>Annuleren</Button>
                                                                                &nbsp;&nbsp;&nbsp;
                                                                                <Button onClick={() => saveContactData()}>Opslaan</Button>
                                                                            </>
                                                                        ) : (
                                                                            <Button onClick={() => setEditModusContactData(!editModusContactData)}>Wijzig</Button>
                                                                        )}
                                                                    
                                                                    </Col>
                                                                </Row>
                                                                {editModusContactData ? (
                                                                    <>
                                                                       {contactUser ? (
                                                                            <form style={{width: '100%'}}>
                                                                                <div className={'col-md-12'}>
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">
                                                                                            <h6>Primaire contactgegevens</h6>
                                                                                        </label>
                                                                                        <div className="input-group">
                                                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-email"></i></span></div>
                                                                                            <input className="form-control" type="text" name={'primaryEmail'} value={contactUser.primaryEmail} onChange={onChangeContactUser} />
                                                                                        </div>
                                                                                        <br />
                                                                                        <div className="input-group">
                                                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-phone"></i></span></div>
                                                                                            <input className="form-control" type="text" name={'primaryPhone'} value={contactUser.primaryPhone} onChange={onChangeContactUser} />
                                                                                        </div>
                                                                                        <br />
                                                                                        <div className="input-group">
                                                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-mobile-phone"></i></span></div>
                                                                                            <input className="form-control" type="text" name={'primaryMobile'} value={contactUser.primaryMobile} onChange={onChangeContactUser} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={'col-md-12'}>
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">
                                                                                            <h6>Alternatieve contactgegevens</h6>
                                                                                        </label>
                                                                                        <div className="input-group">
                                                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-email"></i></span></div>
                                                                                            <input className="form-control" type="text" name={'alternativeEmail'} value={contactUser.alternativeEmail} onChange={onChangeContactUser} />
                                                                                        </div>
                                                                                        <br />
                                                                                        <div className="input-group">
                                                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-phone"></i></span></div>
                                                                                            <input className="form-control" type="text" name={'alternativePhone'} value={contactUser.alternativePhone} onChange={onChangeContactUser} />
                                                                                        </div>
                                                                                        <br />
                                                                                        <div className="input-group">
                                                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-mobile-phone"></i></span></div>
                                                                                            <input className="form-control" type="text" name={'alternativeMobile'} value={contactUser.alternativeMobile} onChange={onChangeContactUser} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        ) : null}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <h2>Contactgegevens</h2>
                                                                        <h5>Eerste contactgegevens</h5>
                                                                        <p>
                                                                            <i className="icofont icofont-email"></i> {contactUser.primaryEmail}<br />
                                                                            <i className="icofont icofont-phone"></i> {contactUser.primaryPhone}<br />
                                                                            <i className="icofont icofont-mobile-phone"></i> {contactUser.primaryMobile}
                                                                        </p>
                                                                        <h5>Tweede contactgegevens</h5>
                                                                        <p>
                                                                            <i className="icofont icofont-email"></i> {contactUser.alternativeEmail}<br />
                                                                            <i className="icofont icofont-phone"></i> {contactUser.alternativePhone}<br />
                                                                            <i className="icofont icofont-mobile-phone"></i> {contactUser.alternativeMobile}
                                                                        </p>
                                                                    </>
                                                                )}
                                                                
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col lg={8}>
                                                        <Card>
                                                            <CardBody>
                                                                <Row>
                                                                    <Col lg={12} className='text-right'>
                                                                        {editModusGeneralInformation ? (
                                                                            <>
                                                                                <Button onClick={() => setEditModusGeneralInformation(!editModusGeneralInformation)}>Annuleren</Button>
                                                                                &nbsp;&nbsp;&nbsp;
                                                                                <Button onClick={() => saveBaseData()}>Opslaan</Button>
                                                                            </>
                                                                        ) : (
                                                                            <Button onClick={() => setEditModusGeneralInformation(!editModusGeneralInformation)}>Wijzig</Button>
                                                                        )}
                                                                    
                                                                    </Col>
                                                                </Row>
                                                                {editModusGeneralInformation ? (
                                                                    <>
                                                                        {baseUser ? (
                                                                            <form>
                                                                            <div className="row">
                                                                                <div className="col-sm-6 col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Voornaam</label>
                                                                                        <input className="form-control" type="text" name={'firstName'} value={baseUser.firstName} onChange={onChangeBaseUser} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-6 col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Naam</label>
                                                                                        <input className="form-control" type="text" name={'lastName'} value={baseUser.lastName} onChange={onChangeBaseUser} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Straat</label>
                                                                                        <input className="form-control" type="text" name={'street'} value={baseUser.street} onChange={onChangeBaseUser} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Huisnr</label>
                                                                                        <input className="form-control" type="text" name={'houseNumber'} value={baseUser.houseNumber} onChange={onChangeBaseUser} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Bus</label>
                                                                                        <input className="form-control" type="text" name={'bus'} value={baseUser.bus} onChange={onChangeBaseUser} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-6 col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Gemeente</label>
                                                                                        <input className="form-control" type="text" name={'city'} value={baseUser.city} onChange={onChangeBaseUser} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-6 col-md-3">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Postal Code</label>
                                                                                        <input className="form-control" type="text" name={'postalCode'} value={baseUser.postalCode} onChange={onChangeBaseUser} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-5">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Country</label>
                                                                                        {countries ? (
                                                                                            <Typeahead
                                                                                                id="project-typeahead"
                                                                                                onChange={changeBaseCountry}
                                                                                                clearButton
                                                                                                labelKey="name"
                                                                                                selected={baseUser.country ? [baseUser.country] : []}
                                                                                                multiple={false}
                                                                                                options={countries}
                                                                                                placeholder="Selecteer een land ..."
                                                                                            />
                                                                                        ) : (
                                                                                            <p> </p>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Extra informatie</label>
                                                                                        <textarea className="form-control" rows="5"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                        ) : null}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <h2>Algemene gegevens</h2>
                                                                        <p>
                                                                            {baseUser ? (
                                                                                <>
                                                                                    {baseUser.firstName + ' ' + baseUser.lastName}<br />
                                                                                    <br />
                                                                                    {baseUser.street + ' ' + baseUser.houseNumber + ' ' + baseUser.bus}<br />
                                                                                    {baseUser.postalCode + ' ' + baseUser.city}<br />
                                                                                    {baseUser.country.name}<br />
                                                                                    <br />
                                                                                    {baseUser.extraInformation}    
                                                                                </>
                                                                            ) : null}
                                                                            
                                                                        </p>
                                                                    </>
                                                                )}
                                                                
                                                            </CardBody>
                                                        </Card>
                                                        <Card>
                                                            <CardBody>
                                                                <Row>
                                                                    <Col lg={12} className='text-right'>
                                                                        {editModusInvoiceData ? (
                                                                            <>
                                                                                <Button onClick={() => setEditModusInvoiceData(!editModusInvoiceData)}>Annuleren</Button>
                                                                                &nbsp;&nbsp;&nbsp;
                                                                                <Button onClick={() => saveInvoicingData()}>Opslaan</Button>
                                                                            </>
                                                                            
                                                                        ) : (
                                                                            <Button onClick={() => setEditModusInvoiceData(!editModusInvoiceData)}>Wijzig</Button>
                                                                        )}
                                                                    
                                                                    </Col>
                                                                </Row>
                                                                {editModusInvoiceData ? (
                                                                    <>
                                                                        {invoicingUser ? (
                                                                            <form>
                                                                            <div className="row">
                                                                                <div className={'col-md-12'}>
                                                                                    <div className={'form-group'}>
                                                                                        <label className="form-label"><input type={'checkbox'} name={'businessAccount'} defaultChecked={invoicingUser.invoiceData.businessAccount} onChange={changeIsBusinessAccount}  /> Zakelijk account</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-6 col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Organisatie</label>
                                                                                        <input className="form-control" type="text" name={'organisationName'} value={invoicingUser.invoiceData.organisationName} onChange={onChangeInvoicingUser} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-6 col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">BTWnummer</label>
                                                                                        <input className="form-control" type="text" name={'vatNumber'} value={invoicingUser.invoiceData.vatNumber} onChange={onChangeInvoicingUser} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Straat</label>
                                                                                        <input className="form-control" type="text" name={'street'} value={invoicingUser.invoiceData.street} onChange={onChangeInvoicingUser} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Huisnr</label>
                                                                                        <input className="form-control" type="text" name={'houseNumber'} value={invoicingUser.invoiceData.houseNumber} onChange={onChangeInvoicingUser} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Bus</label>
                                                                                        <input className="form-control" type="text" name={'bus'} value={invoicingUser.invoiceData.bus} onChange={onChangeInvoicingUser} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-6 col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Gemeente</label>
                                                                                        <input className="form-control" type="text" name={'city'} value={invoicingUser.invoiceData.city} onChange={onChangeInvoicingUser} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-6 col-md-3">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Postal Code</label>
                                                                                        <input className="form-control" type="text" name={'postalCode'} value={invoicingUser.invoiceData.postalCode} onChange={onChangeInvoicingUser} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-5">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Country</label>
                                                                                        {countries ? (
                                                                                            <Typeahead
                                                                                                id="project-typeahead"
                                                                                                onChange={changeInvoiceCountry}
                                                                                                clearButton
                                                                                                labelKey="name"
                                                                                                selected={invoicingUser.invoiceData.country ? [invoicingUser.invoiceData.country] : []}
                                                                                                multiple={false}
                                                                                                options={countries}
                                                                                                placeholder="Selecteer een land ..."
                                                                                            />
                                                                                        ) : (
                                                                                            <p> </p>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                        ) : null}    
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <h2>Facturatiegegevens</h2>
                                                                        {invoicingUser != null && invoicingUser.invoiceData !== null ? (
                                                                            <p>
                                                                                {invoicingUser.invoiceData.businessAccount ? 'Professioneel account' : 'Particulier'}<br />
                                                                                <br />
                                                                                {invoicingUser.invoiceData.businessAccount ? (
                                                                                    <>{invoicingUser.invoiceData.organisationName + '(' + invoicingUser.invoiceData.vatNumber + ')'}<br /></>
                                                                                ) : (
                                                                                    <>{invoicingUser.firstName + ' ' + invoicingUser.lastName}<br /></>
                                                                                )}
                                                                                {invoicingUser.invoiceData.street + ' ' + invoicingUser.invoiceData.houseNumber + ' ' + invoicingUser.invoiceData.bus}<br />
                                                                                {invoicingUser.invoiceData.postalCode + ' ' + invoicingUser.invoiceData.city}<br />
                                                                                {invoicingUser.invoiceData.country.name}
                                                                            </p>
                                                                        ) : (
                                                                            <p>Geen facturatiegegevens</p>
                                                                        )}    
                                                                    </>
                                                                )}
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </>
                                ) : null}    
                                {tabValue === 1 ? (
                                    <ReactTable
                                        data={projects}
                                        columns={projectColumns}
                                        minRows={1}
                                        defaultPageSize={10}
                                        className={"-striped -highlight"}
                                        showPagination={true}
                                    />
                                ) : null}
                                {tabValue === 2 ? (
                                    <ReactTable
                                        data={invoices}
                                        columns={invoiceColumns}
                                        defaultPageSize={10}
                                        minRows={1}
                                        className={"-striped -highlight"}
                                        showPagination={true}
                                    />
                                ) : null}
                                {tabValue === 3 ? (
                                    <ReactTable
                                        data={taxCertificates}
                                        columns={taxCertificateColumns}
                                        defaultPageSize={10}
                                        minRows={1}
                                        className={"-striped -highlight"}
                                        showPagination={true}
                                    />
                                ) : null}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            ) : null
        )
    );

}

export default UserDetail;