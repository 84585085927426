import React, { Fragment, useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import Loader from '../common/loader';
import useReactTable from '../../hooks/useReactTable';
import {Link} from "react-router-dom";
import {Col, Input, Row} from "reactstrap";

const ProjectsOverview = () =>  {

    const [isLoading, setIsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [projects, setProjects] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [pages, setPages] = useState(-1);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const columns = useMemo(() => [{
        Header : 'Projectnummer',
        accessor : 'projectNumber',
        headerClassName : 'text-left',
        Cell: row => {
            return <Link to={`/project/detail/` + row.row._original.id} target={'_parent'}>{row.row.projectNumber}</Link>
        }
    },
    {
        Header : 'Project',
        accessor : 'name',
        headerClassName : 'text-left'
    },
    {
        Header : 'Klant',
        headerClassName : 'text-left',
        accessor : 'customer',
        width: 300,
        Cell : row => {
            return <>{row.row.customer !== null ? (
                <Link to={'/user/' + row.row.customer.id + '/detail'} target={'_parent'}>{row.row.customer.firstName} {row.row.customer.lastName}</Link>
            ): ''}</>
        }
    },
    {
        id: 'address',
        Header : 'Adres',
        headerClassName : 'text-left',
        width: 300,
        accessor : project => {
            const country = project.country !== undefined && project.country !== null ? project.country.name : '';
            return `${project.street} ${project.houseNumber} ${project.bus}, ${project.postalCode} ${project.city} ${country}`;
        }
    },
    {
        Header: 'Offerten',
        headerClassName: 'text-center',
        accessor: 'numberOfOffers',
        width: 200,
        className: 'text-center',
        Cell : row => <>{row.row._original.numberOfOffers}</>
    },
    {
        Header: 'Werkbonnen',
        headerClassName: 'text-center',
        accessor: 'numberOfWorkOrders',
        width: 200,
        className: 'text-center',
        Cell : row => <>{row.row._original.numberOfWorkOrders}</>
    },
    {
        Header: 'Facturen',
        headerClassName: 'text-center',
        accessor: 'numberOfInvoices',
        width: 200,
        className: 'text-center',
        Cell : row => {
            return <>{row.row._original.numberOfInvoices} {row.row._original.numberOfPaidInvoices !== row.row._original.numberOfInvoices ? ' ('+ (parseInt(row.row._original.numberOfInvoices) - parseInt(row.row._original.numberOfPaidInvoices)) + ' te betalen)' : ''}</>
        }
    }]);

    let timer;

    useEffect(() => {
        loadCurrentUser();
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            globalSearch();
          }, 2000)
          return () => clearTimeout(delayDebounceFn)
    }, [searchInput]);

    const loadCurrentUser = () => {
        API.get('myuser').then(function(response) {
            if (response.data !== undefined) {
                setCurrentUser(response.data[0]);
                setIsLoading(false);
            }
        });
    }

    const handleChange = event => {
        setSearchInput(event.target.value);
    };

    const globalSearch = () => {
        API.get('projects?pageNumber=0&numberOfRows=10&search_input=' + searchInput).then(function(response) {
            setProjects(response.data.items);
            setFiltered(response.data.items);
            setIsLoading(false);
            setPages(Math.ceil(response.data.totalItemCount / response.data.itemNumberPerPage));
        });
    };

    return (
        <Fragment>
            {isLoading ? (
                <Loader />
            ) : (
                <Fragment>
                    

                    

                    <Row>
                        <Col lg={6}>
                            <Breadcrumb>Projecten</Breadcrumb>
                        </Col>
                        <Col lg={6} className={'text-right'}>
                            {currentUser !== null && (currentUser.userRole === 'ADMIN' ||  currentUser.userRole === 'EMPLOYER') ? (
                                    <div className={'row mt-3'}>
                                        <div className={'col-md-12'}>
                                            <Link to={'/projects/create'} className={'btn btn-primary'}>Nieuw project aanmaken</Link>
                                        </div>
                                    </div>
                                ) : null}
                        </Col>
                    </Row>
                    
                    <div className="container-fluid">
                        <div className="edit-profile">
                            {currentUser !== null && (currentUser.userRole === 'ADMIN' ||  currentUser.userRole === 'EMPLOYER' || currentUser.userRole === 'EMPLOYEE') ? (
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Input
                                            size="large"
                                            name="searchInput"
                                            value={searchInput || ""}
                                            onChange={handleChange}
                                            label="Search"
                                            placeholder={'Zoeken'}
                                        />
                                        <br />
                                        <br />
                                        <ReactTable 
                                            pages={pages}
                                            data={filtered}
                                            columns={columns}
                                            minRows={0}
                                            manual
                                            defaultPageSize={10}
                                            className={"-striped -highlight"}
                                            showPagination={true}
                                            previousText={'Vorige'}
                                            nextText={'Volgende'}
                                            pageText={'Pagina'}
                                            rowsText={'rijen'}
                                            ofText={'van'}
                                            getTheadThProps={getTheadThProps}
                                            getTdProps={getTdProps}
                                            getTrProps={getTrProps}
                                            getPaginationProps={getPaginationProps}
                                            onFetchData={(state, instance) => {
                                                API.get('projects?pageNumber=' + state.page + '&numberOfRows=' + state.pageSize + '&search_input=' + searchInput).then(function(response) {
                                                    setProjects(response.data.items);
                                                    setFiltered(response.data.items);
                                                    setIsLoading(false);
                                                    setPages(Math.ceil(response.data.totalItemCount / response.data.itemNumberPerPage));
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : null}

                            
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default ProjectsOverview;