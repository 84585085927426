import React, {useState, Fragment, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import API from "../../../utils/API";
import logo_compact from "../../../assets/images/logo-pouillon.png";
import { Add, AccountTree, Assignment, Description, Group, Home, Schedule, Settings, Person, MeetingRoom, CalendarToday, PermContactCalendarSharp } from '@material-ui/icons';
import { Col, Row } from 'reactstrap';

const Header = () => {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState('EMPLOYEE');
  const history = useHistory();

  useEffect(() => {
    API.get('/myuser').then(function(response) {
      setUser(response.data[0]);
      setUserRole(response.data[0].userRole);
    });
  }, []);

  const logOut = (event) => { 
    event.preventDefault();
    localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN);
    history.push(`${process.env.PUBLIC_URL}/login`);
  };

  return (
    <Fragment>
      <div className="page-main-header" >
        <Row className="main-header-right">
          <Col md={2} className="logo-wrapper d-none d-md-block d-lg-block d-xl-block">
            <Link to="/dashboard/default">
              <img src={logo_compact} alt="" style={{height: '90%'}} />
            </Link>
          </Col>
          <Col className="col-md-10 p-0 d-none d-md-block d-lg-block d-xl-block text-right">
            {userRole === 'ADMIN' || userRole === 'EMPLOYER' ? (
              <div className='d-flex flex-row'>
                <div className='p-2 pr-5'><a href="/" title={'Dashboard'}>Dashboard</a></div>
                <div className='p-2 pr-5'><Link title={'Projecten'} to={'/projects/overview'} > Projecten</Link></div>
                <div className='p-2 pr-5'><Link title={'Planning'} to={'/planning'} > Planning</Link></div>
                <div className='p-2 pr-5'><Link title={'Taken'} to={'/taskmanagement/overview'}>Taken</Link></div>
                <div className='p-2 pr-5'><Link title={'Prestaties'} to={'/timesheets/overview'}>Tijdsregistraties</Link></div>
                <div className='p-2 pr-5'><Link title={'Documenten'} to={'/documents'}> Documenten</Link></div>
                <div className='p-2 pr-5'><Link title={'Gebruikers'} to={"/users/overview"}> Gebruikers</Link></div>
                <div className='p-2 pr-5'><Link title={'Werkbon toevoegen'} to={'/workorder/create'}>Werkbon toevoegen</Link></div>
                <div className='p-2 pr-5'><Link title={'Instellingen'} to={"/settings"}> <Settings style={{height: '40px', width: '40px'}} /></Link></div>
                {user !== null && user !== undefined ? (
                    <>
                      <div className='p-2 pr-5'><Link title={'Mijn profiel'} to={'/user/' + user.id + '/edit'}><Person style={{height: '40px', width: '40px'}} /><br /></Link></div>
                      <div className='p-2 pr-5'><a href="#" title={'Uitloggen'} onClick={logOut}><MeetingRoom style={{height: '40px', width: '40px'}} /><br /></a></div>
                    </>
                   ) : null}
                
              </div>
            ) : (
              <ul style={{textAlign: 'center'}} className={`nav-menus`}>
                  <li style={{width:'14%'}}>
                    <a title={'Dashboard'} href="/"><Home style={{height: '40px', width: '40px'}} /></a>
                  </li>
                  <li style={{width:'14%'}}>
                    <Link title={'Projecten'} to={'/projects/overview'} > <AccountTree style={{height: '40px', width: '40px'}} /></Link>
                  </li>
                  <li style={{width:'14%'}}>
                    <Link title={'Taken'} to={'/tasks/overview'}><Assignment style={{height: '40px', width: '40px'}} /></Link>
                  </li>
                  <li style={{width:'14%'}}>
                    <Link title={'Prestaties'} to={'/timesheets/overview'}><Schedule style={{height: '40px', width: '40px'}} /></Link>
                  </li>
                  <li style={{width:'14%'}}>
                    <Link title={'Werkbon toevoegen'} to={'/workorder/create'}><Add style={{height: '40px', width: '40px'}} /></Link>
                  </li>
                  {user !== null && user !== undefined ? (
                    <>
                    <li style={{width:'14%'}}>
                      <Link title={'Mijn profiel'} to={'/user/' + user.id + '/edit'}><Person style={{height: '40px', width: '40px'}} /></Link>
                    </li>
                    <li style={{width:'14%'}}>
                      <a title={'Uitloggen'} href="#" onClick={logOut}><MeetingRoom style={{height: '40px', width: '40px'}} /></a>
                    </li>
                    </>
                   ) : null}
              </ul>
            )}
          </Col>
          <Col className="col-md-10 p-0 d-none d-none d-sm-block d-md-none">
            {userRole === 'ADMIN' || userRole === 'EMPLOYER' ? (
              <ul className={`nav-menus`} style={{textAlign: 'center'}}>
                <li style={{width:'16%'}}>
                  <a href="/" title={'Dashboard'}><Home style={{height: '40px', width: '40px'}} /></a>
                </li>
                <li style={{width:'16%'}}>
                  <Link title={'Projecten'} to={'/projects/overview'} > <AccountTree style={{height: '40px', width: '40px'}} /></Link>
                </li>
                <li style={{width:'16%'}}>
                  <Link title={'Planning'} to={'/planning'} > <PermContactCalendarSharp style={{height: '40px', width: '40px'}} /></Link>
                </li>
                <li style={{width:'16%'}}>
                  <Link title={'Gebruikers'} to={"/users/overview"}> <Group style={{height: '40px', width: '40px'}} /></Link>
                </li>
                <li style={{width:'16%'}}>
                    <Link title={'Werkbon toevoegen'} to={'/workorder/create'}><Add style={{height: '40px', width: '40px'}} /></Link>
                  </li>
                {user !== null && user !== undefined ? (
                    <>
                    <li style={{width:'16%'}}>
                      <a href="#" title={'Uitloggen'} onClick={logOut}><MeetingRoom style={{height: '40px', width: '40px'}} /><br /></a>
                    </li>
                    </>
                   ) : null}
            </ul>
            ) : (
              <ul style={{textAlign: 'center'}} className={`nav-menus`}>
                  <li style={{width:'20%'}}>
                    <a title={'Dashboard'} href="/"><Home style={{height: '40px', width: '40px'}} /></a>
                  </li>
                  <li style={{width:'20%'}}>
                    <Link title={'Projecten'} to={'/projects/overview'} > <AccountTree style={{height: '40px', width: '40px'}} /></Link>
                  </li>
                <li style={{width:'20%'}}>
                  <Link title={'Planning'} to={'/planning'} > <PermContactCalendarSharp style={{height: '40px', width: '40px'}} /></Link>
                </li>
                  <li style={{width:'20%'}}>
                    <Link title={'Werkbon toevoegen'} to={'/workorder/create'}><Add style={{height: '40px', width: '40px'}} /></Link>
                  </li>
                  {user !== null && user !== undefined ? (
                    <>
                    <li style={{width:'20%'}}>
                      <a title={'Uitloggen'} href="#" onClick={logOut}><MeetingRoom style={{height: '40px', width: '40px'}} /></a>
                    </li>
                    </>
                   ) : null}
              </ul>
            )}
          </Col>
          <Col className="col-md-10 p-0 d-block d-sm-none">
            {userRole === 'ADMIN' || userRole === 'EMPLOYER' ? (
              <ul className={`nav-menus`} style={{textAlign: 'center'}}>
                <li style={{width:'16%'}}>
                  <a href="/" title={'Dashboard'}><Home style={{height: '40px', width: '40px'}} /></a>
                </li>
                <li style={{width:'16%'}}>
                  <Link title={'Projecten'} to={'/projects/overview'} > <AccountTree style={{height: '40px', width: '40px'}} /></Link>
                </li>
                <li style={{width:'16%'}}>
                  <Link title={'Planning'} to={'/planning'} > <PermContactCalendarSharp style={{height: '40px', width: '40px'}} /></Link>
                </li>
                <li style={{width:'16%'}}>
                  <Link title={'Gebruikers'} to={"/users/overview"}> <Group style={{height: '40px', width: '40px'}} /></Link>
                </li>
                <li style={{width:'16%'}}>
                    <Link title={'Werkbon toevoegen'} to={'/workorder/create'}><Add style={{height: '40px', width: '40px'}} /></Link>
                  </li>
                {user !== null && user !== undefined ? (
                    <>
                    <li style={{width:'16%'}}>
                      <a href="#" title={'Uitloggen'} onClick={logOut}><MeetingRoom style={{height: '40px', width: '40px'}} /><br /></a>
                    </li>
                    </>
                   ) : null}
            </ul>
            ) : (
              <ul style={{textAlign: 'center'}} className={`nav-menus`}>
                  <li style={{width:'20%'}}>
                    <a title={'Dashboard'} href="/"><Home style={{height: '40px', width: '40px'}} /></a>
                  </li>
                  <li style={{width:'20%'}}>
                    <Link title={'Projecten'} to={'/projects/overview'} > <AccountTree style={{height: '40px', width: '40px'}} /></Link>
                  </li>
                <li style={{width:'20%'}}>
                  <Link title={'Planning'} to={'/planning'} > <PermContactCalendarSharp style={{height: '40px', width: '40px'}} /></Link>
                </li>
                  <li style={{width:'20%'}}>
                    <Link title={'Werkbon toevoegen'} to={'/workorder/create'}><Add style={{height: '40px', width: '40px'}} /></Link>
                  </li>
                  {user !== null && user !== undefined ? (
                    <>
                    <li style={{width:'20%'}}>
                      <a title={'Uitloggen'} href="#" onClick={logOut}><MeetingRoom style={{height: '40px', width: '40px'}} /></a>
                    </li>
                    </>
                   ) : null}
              </ul>
            )}
          </Col>

        </Row>
      </div>
    </Fragment>
  )
};
export default Header;