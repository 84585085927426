import React, { Fragment, useEffect, useState } from 'react';

import { Row, Col, Card, CardBody} from 'reactstrap';
import moment from "moment-timezone";

import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import FullCalendar from '@fullcalendar/react';

import _ from "lodash";

import API from '../../utils/API';

const TaskPlanningPerEmployee = () =>  {

    const [resources, setResources] = useState([]);
    const [events, setEvents] = useState([]);
    const ref = React.createRef();

    useEffect(() => {

        API.get('users/workers').then(function(response) {
            let tmpArray = [];
            response.data.map(user => {
                tmpArray.push({id: user.id, title: user.firstName +  ' ' + user.lastName})
            });
            setResources(tmpArray);
        });
    }, []);

    const getTasks = (date) => {
        let tmpArray = [...events];
        API.get('/tasks?dateFrom=' + moment(date).format('YYYY-MM-DD 00:00:00') + '&dateUntil=' + moment(date).format('YYYY-MM-DD 23:59:59')).then(function(response) {
            response.data.map(task => {
                if (task.responsibleUser !== null) {
                    tmpArray.push({
                        resourceId: task.responsibleUser.id,
                        title: task.name,
                        start: new Date(task.plannedDateForExecution),
                        end: new Date(task.plannedEndDateForExecution),
                        description: task.project != null ? task.project.description : '',
                        location: task.project != null ? task.project.street + ' ' + task.project.houseNumber + ' ' + task.project.bus + ', ' + task.project.postalCode + ' ' + task.project.city : '',
                        customer: task.project != null && task.project.customer != null ? task.project.customer.firstName + ' ' + task.project.customer.lastName : '',
                    });
                }
            });
            setEvents(tmpArray);
        });
    };

    useEffect(() => {
        getTasks(new Date());
    }, [resources]);


    const changeDateOnCalendar = (event) => {
        let newDate = new Date(event.target.value);
        let calendarApi = ref.current.getApi();
        getTasks(newDate);
        calendarApi.gotoDate(newDate);
    }

    const renderEventContent = (eventInfo) => {
        return (
            <>
                <b>{moment(eventInfo.event.start).format('HH:mm')} - {moment(eventInfo.event.end).format('HH:mm')}</b>
                <span className='ml-2'>{eventInfo.event.title}</span><br />
                <i><b>Klant:</b> {eventInfo.event.extendedProps.customer}</i><br />
                <i><b>Locatie:</b> {eventInfo.event.extendedProps.location}</i>
                <br />
                <i dangerouslySetInnerHTML={{__html:eventInfo.event.extendedProps.description }}></i>   
            </>
        );
    };

    const renderResourceContent = (resourceInfo) => {
        return (<><h5><b className='mt-2 mb-2'><br />{resourceInfo.resource.title}<br /></b></h5></>)
    };


    return (
        <Fragment>
            <Card>
                <CardBody>
                    <Row>
                        <Col lg={2}>
                            Selecteer datum
                        </Col>
                        <Col lg={4}>
                            <input type='date' onChange={changeDateOnCalendar} className='form-control' />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <Row>
                        <Col lg={12}>
                            <FullCalendar
                                ref={ref}
                                datesAboveResources={true}
                                schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
                                initialView="resourceDayGrid"
                                plugins={[resourceDayGridPlugin]}
                                resourceLabelContent={(resourceInfo) => renderResourceContent(resourceInfo)}
                                resourceAreaWidth={'20%'}
                                resources={resources}
                                events={events}
                                eventContent={(eventInfo) => renderEventContent(eventInfo)}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default TaskPlanningPerEmployee;