import React, { Fragment } from 'react';
import _ from "lodash";
import {Col, Row} from "reactstrap";
import { Link } from 'react-feather';

class ConfirmProjectCreation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            project : props.newProject
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        {!_.isEmpty(this.props.newProject) ? (
                            <>
                                <Row>
                                    <Col lg={12}>
                                        <h4>Project dat ingevoerd is</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3}>Naam:</Col>
                                    <Col lg={9}>{this.props.newProject.name}</Col>
                                </Row>
                                <Row>
                                    <Col lg={3}>Korte beschrijving:</Col>
                                    <Col lg={9}>{this.props.newProject.shortDescription}</Col>
                                </Row>
                                <Row>
                                    <Col lg={3}>Beschrijving:</Col>
                                    <Col lg={9}>{this.props.newProject.shortDescription}</Col>
                                </Row>
                                <Row>
                                    <Col lg={3}>Klant:</Col>
                                    <Col lg={9}>
                                        {this.props.newProject.customer.firstName} {this.props.newProject.customer.lastName}<br />
                                        {this.props.newProject.customer.street} {this.props.newProject.customer.houseNumber} {this.props.newProject.customer.houseNumber}<br />
                                        {this.props.newProject.customer.postalCode} {this.props.newProject.customer.city}<br />
                                        {this.props.newProject.customer.country.name}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3}>Locatie:</Col>
                                    <Col lg={9}>
                                        {this.props.newProject.street} {this.props.newProject.houseNumber} {this.props.newProject.bus}<br />
                                        {this.props.newProject.postalCode} {this.props.newProject.city}<br />
                                        {this.props.newProject.country.name}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3}></Col>
                                    <Col lg={9}>
                                        <a href={'/project/detail/' + this.props.newProject.id} target={'parent'}>Ga naar detailpagina</a>
                                    </Col>
                                </Row>
                            </>

                        ) : null }
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default ConfirmProjectCreation;