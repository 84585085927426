import React, { Fragment, useState, useMemo, useEffect } from 'react';
import ReactTable from "react-table-6";
import { Col, Row} from "reactstrap";
import API from '../../../utils/API';
import _ from "lodash";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router';
import useReactTable from '../../../hooks/useReactTable';
import { Link } from 'react-router-dom';

const ProjectWorkOrders = (props) =>  {
    const [project, setProject] = useState(props.project);
    const [workOrders, setWorkOrders] = useState([]);
    
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const columns = useMemo(() => [
        {
            Header : 'Naam',
            accessor : 'name',
            width: 250,
            Cell : cell => (
                <div>
                    <a target={'_blank'} href={process.env.PUBLIC_URL + '/workorder/detail/' + cell.original.id}>{cell.value}</a>
                </div>
            )
        },
        {
            Header : 'Beschrijving',
            accessor : 'description'
        },
        {
            Header : '% ingevuld',
            accessor: 'fulfillmentPercentage',
            Cell: cell => (
                <div className="progress">
                    <div className="progress-bar" role="progressbar" aria-valuenow="{cell.value}" aria-valuemin="0" aria-valuemax="100" style={{width: cell.value +'%'}}>
                        {cell.value}%
                    </div>
                </div>
            )
        }
    ]);

    useEffect(() => {
        if (project !== null) {
            setWorkOrders(project.workOrders);
        }
    }, [project]);

    return (
        <>
            <Row className={'mt-3'}>
                <Col lg={12}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <br />
                            {project && workOrders !== undefined ? (
                                <ReactTable 
                                    data={workOrders}
                                    columns={columns}
                                    minRows={0}
                                    defaultPageSize={10}
                                    className={"-striped -highlight"}
                                    showPagination={true}
                                    previousText={'Vorige'}
                                    nextText={'Volgende'}
                                    pageText={'Pagina'}
                                    rowsText={'rijen'}
                                    ofText={'van'}
                                    getTheadThProps={getTheadThProps}
                                    getTdProps={getTdProps}
                                    getTrProps={getTrProps}
                                    getPaginationProps={getPaginationProps}
                                />
                            ) : (
                                <p>Er zijn geen werkbonnen aangemaakt voor dit project.</p>
                            )}
                        </div>
                    </div>
                    <br />
                </Col>
            </Row>
            <Row className={'mt-1'}>
                <Col lg={12}>
                    {project !== null ? (
                        <a href={`${process.env.PUBLIC_URL}/project/` + project.id + `/workorder/create`} className={'btn btn-primary'}><span className={'fa fa-plus'}></span> Nieuwe werkbon toevoegen</a>
                    ) : null}
                </Col>
            </Row>
        </>
    );
};

export default ProjectWorkOrders;