import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import API from "../../../utils/API";
import {Card, CardBody, Col, Row} from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import nlLocale from '@fullcalendar/core/locales/nl'
import moment from "moment-timezone";
import {Link} from "react-router-dom";
import _ from "lodash";
import GeoCode from "react-geocode";
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import Loader from '../../common/loader';

const Project = () => {
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [selectedTaskProjectNotes, setSelectedTaskProjectNotes] = useState([]);
    const [doAddHolidays, setDoAddHolidays] = useState(false);
    const [holidaysAdded, setHolidayAdded] = useState(false);
    const [tasksLoaded, setTasksLoaded] = useState(false);
    const [user, setUser] = useState(null);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);

    const ref = React.createRef();

    let timer;

    useDocumentTitle("My Workplanner - Dashboard");

    useEffect(() => {
        API.get('myuser').then(function(response) {
            setUser(response.data[0]);
        });
        API.get('my-current-or-next-first-task').then(function(response) {
            setSelectedTask(response.data);
        });
        API.get('mail/send-test-email');

        dayReRender(new Date());
    }, []);

    useEffect(() => {
        if (selectedTask !== null && selectedTask.project !== null) {
            const address = selectedTask.project.street + ' ' + selectedTask.project.houseNumber + ' ' + selectedTask.project.bus + ", " + selectedTask.project.postalCode + " " + selectedTask.project.city + ", " + selectedTask.project.country;
            GeoCode.setApiKey("AIzaSyDK6dN8ajm8oGhTLtkz6Vxgj9qbyMUrfxo");
            GeoCode.fromAddress(address).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    setLat(lat);
                    setLng(lng);
                }
            );
            API.get('/project/' + selectedTask.project.id + '/notes').then(function(response) {
                setSelectedTaskProjectNotes(response.data);
            });
        }
        
    }, [selectedTask]);

    useEffect(() => {
        if (tasksLoaded === true) {
            setDoAddHolidays(true);
        }
    }, [calendarEvents]);

    useEffect(() => {
        if (holidaysAdded === false && doAddHolidays === true) {
            API.get('holidays').then(function(response) {
                let tempList = [...calendarEvents];
                let holidays = response.data;
                holidays.map((holiday) => {
    
                    tempList.push({
                        taskId: -1,
                        title: holiday.name,
                        backgroundColor: "#cc2200",
                        start: new Date(holiday.startDateTime),
                        end: new Date(holiday.endDateTime),
                        holiday: true
                    });
    
                });
                setCalendarEvents(tempList);
                setHolidayAdded(true);
                setDoAddHolidays(false);
            });
        }
        
    }, [doAddHolidays]);

    const renderEventContent = (eventInfo) => {
          return (
            <>
                <center>
                    <b>{moment(eventInfo.event.start).format('HH:mm')} - {moment(eventInfo.event.end).format('HH:mm')}</b><br />
                </center>
                
                <center>
                    {eventInfo.event.extendedProps.taskId !== -1 ? (
                        <>
                            <br />
                            <span style={{textTransform: 'uppercase'}}>{eventInfo.event.extendedProps.executor}</span>
                            <br />
                            <br />
                        </>
                    ) : null}
                </center>   
            </>
        );
        
    };

    const startTask = (id) => {
        API.put('task/' + id + '/state/changetobusy').then(function() {
            API.get('/task/' + id).then(function(response) {
                setSelectedTask(response.data);
             });
        });
    };

    const cancelTask = (id) => {
        API.put('task/' + id + '/state/changetonew').then(function() {
            API.get('/task/' + id).then(function(response) {
                setSelectedTask(response.data);
             });
        });
    };

    const pauseTask = (id) => {
        API.put('task/' + id + '/state/changetopause').then(function() {
            API.get('/task/' + id).then(function(response) {
                setSelectedTask(response.data);
             });
        });
    };

    const endTask = (id, projectId) => {
        window.location.replace('/project/' + projectId + '/task/' + id + '/evaluation');
    };


    const handleEventClick = (eventInfo) => {
        API.get('/task/' + eventInfo.event.extendedProps.taskId).then(function(response) {
           setSelectedTask(response.data);
        });
    }

    const handleDatesSet = (info) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            if (ref != null && ref.current != null) {
                let calendarApi = ref.current.getApi();
                dayReRender(calendarApi.getDate());
            }
            ;
        }, 2000);
    };

    const dayReRender = (date) => {

        let startOfMonth = moment(date).startOf('month').subtract(6,'day').format('YYYY-MM-DD 00:00:00');
        let endOfMonth = moment(date).endOf('month').add(11,'day').format('YYYY-MM-DD 23:59:59');

        API.get('tasks/calendar?dateFrom=' + startOfMonth + '&dateUntil=' + endOfMonth).then(function(response) {
            const tasks = response.data;
            let list = calendarEvents.filter(function(calendarEvent) {
                return calendarEvent.holiday === true;
            });
        
            tasks.map((task) => {
                if (task.plannedDateForExecution !== null && task.plannedEndDateForExecution !== null) {
                    let startDate = new Date(task.plannedDateForExecution);
                    let endDate = new Date(task.plannedEndDateForExecution);
                    let firstItem = true;

                    if (startDate.getFullYear() == endDate.getFullYear() && startDate.getMonth() == endDate.getMonth() && startDate.getDate() == endDate.getDate()) {
                        list.push({
                            taskId: task.id,
                            display: 'block',
                            title: task.description,
                            description: task.projectName,
                            //customer: task.project !== null && task.project.customer !== null ? task.project.customer.firstName + ' ' + task.project.customer.lastName : '',
                            //location : task.project !== null ? task.project.street + ' ' + task.project.houseNumber + ' ' + task.project.bus + ', ' + task.project.postalCode + ' ' + task.project.city : '',
                            executor: task.responsibleUser !== null ? task.responsibleUser.firstName : '',
                            backgroundColor: task.status === "FINISHED" || task.status === "DONE" ? "#aeaeae" : task.responsibleUser !== null ? task.responsibleUser.colorCode : '#FF6600',
                            start: startDate,
                            end: endDate,
                            holiday: false
                        });
                    } else {
                        for (let currentDate = new Date(task.plannedDateForExecution); currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {        
                            if (currentDate.getDay() === 1 || currentDate.getDay() === 2 || currentDate.getDay() === 3 || currentDate.getDay() === 4 || currentDate.getDay() === 5 || (task.saturdayIncluded && currentDate.getDay() === 6) || (task.sundayIncluded && currentDate.getDay() === 0)) {
                                let begin = new Date(currentDate);
                                let end = new Date(currentDate);
                                if (firstItem === true) {
                                    begin.setHours(startDate.getHours());
                                    begin.setMinutes(startDate.getMinutes());
                                    if ((endDate.getTime() - startDate.getTime()) / 1000 * 3600 * 24 > 1) {
                                        end.setHours(18);
                                        end.setMinutes(0);
                                    } else {
                                        end.setHours(currentDate.getHours());
                                        end.setMinutes(currentDate.getMinutes());
                                    }
                                    firstItem = false;
                                } else {
                                    begin.setHours(8);
                                    begin.setMinutes(0);
                                    if (endDate.getFullYear() === end.getFullYear() && endDate.getMonth() === end.getMonth() && endDate.getDate() === end.getDate()) {
                                        end.setHours(endDate.getHours());
                                        end.setMinutes(endDate.getMinutes());
                                    } else {
                                        end.setHours(18);
                                        end.setMinutes(0);
                                    }
                                }
                                list.push({
                                    taskId: task.id,
                                    display: 'block',
                                    title: task.description,
                                    description: task.projectName,
                                    //customer: task.project !== null && task.project.customer !== null ? task.project.customer.firstName + ' ' + task.project.customer.lastName : '',
                                    //location : task.project !== null ? task.project.street + ' ' + task.project.houseNumber + ' ' + task.project.bus + ', ' + task.project.postalCode + ' ' + task.project.city : '',
                                    executor: task.responsibleUser !== null ? task.responsibleUser.firstName : '',
                                    backgroundColor: task.responsibleUser !== null ? task.responsibleUser.colorCode : '#FF6600',
                                    start: new Date(begin),
                                    end: new Date(end)
                                });
                            }
                        }
                    }
                }
            });
            setTasksLoaded(true);
            setCalendarEvents(list);
        });
    };
    
        return (
            <Fragment>
                <Breadcrumb title="Project">Dashboard</Breadcrumb>
                <div className="container-fluid">
                    <Row>
                        <Col lg={7}>
                            <Card>
                                <CardBody>
                                    <FullCalendar
                                        ref={ref}
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                        initialView={'dayGridMonth'}
                                        eventContent={(eventInfo) => renderEventContent(eventInfo)}
                                        events={calendarEvents}
                                        headerToolbar={{
                                            left: 'today prev,next',
                                            center: 'title',
                                            right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                          }}
                                        eventClick={(a,b,c) => handleEventClick(a,b,c)}
                                        locale={nlLocale}
                                        businessHours={{
                                            daysOfWeek: [ 1, 2, 3, 4, 5 ],
                                            startTime: '08:00',
                                            endTime: '19:00'}
                                        }
                                        height={650}
                                        slotTime={'06:00:00'}
                                        scrollTimeReset={false}
                                        datesSet={(datesSet) => handleDatesSet(datesSet)}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={5}>
                            <Card style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                <CardBody>
                                    {user === null || user === undefined ? (
                                        <Loader />
                                    ) : (
                                        <>
                                            {selectedTask === null || selectedTask === undefined ? (
                                                <p>Er is geen taak aangeduid om informatie te tonen</p>
                                            ) : (
                                        <>
                                            <Row>
                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                    <h3>
                                                        TAAK: {selectedTask.name}
                                                        <Link
                                                            className={'btn float-right'}
                                                            style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                            target={'_blank'} to={'/task/detail/' + selectedTask.id}><i className="icofont icofont-2x icofont-eye-alt"></i> Taak bekijken</Link>

                                                    </h3>
                                                </Col>
                                            </Row>
                                            
                                            {new Date(selectedTask.plannedDateForExecution) < new Date() && new Date(selectedTask.plannedEndDateForExecution) > new Date() && selectedTask.status === 'NEW' ? (
                                                <Row className={'mt-3'}>
                                                    <Col xs={12} sm={12} md={12} lg={12}>
                                                        <Card style={{backgroundColor: '#cc0000', color: '#ffffff'}}>
                                                            <CardBody>
                                                                <p>Deze taak is nog niet gestart en zou al begonnen moeten zijn.</p>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            ): null}
                                            <Row className={'mt-3'}>
                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                    <div dangerouslySetInnerHTML={{__html: selectedTask.description}} />
                                                </Col>
                                            </Row>
                                            <Row className={'mt-3'}>
                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                    <table className={'table'}>
                                                        
                                                        <tr>
                                                            <th style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR, verticalAlign: 'top'}}>Status</th>
                                                            <td style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                                                {selectedTask.status}<br />
                                                                {selectedTask.responsibleUser !== null && selectedTask.responsibleUser.id === user.id ? (
                                                                    <>
                                                                        {selectedTask.status === 'NEW' ? (
                                                                            <button
                                                                                className={'btn mr-2'}
                                                                                style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                                                onClick={() => startTask(selectedTask.id)}
                                                                            >
                                                                                <span>START</span>
                                                                            </button>
                                                                        ) : null}

                                                                        {selectedTask.status === 'PAUSE' ? (
                                                                            <button
                                                                                className={'btn mr-2'}
                                                                                style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                                                onClick={() => startTask(selectedTask.id)}
                                                                            >
                                                                                <span>GA VERDER</span>
                                                                            </button>
                                                                        ) : null}   
                                                                            
                                                                        {selectedTask.status === 'BUSY' ? (
                                                                            <>
                                                                                <button
                                                                                    className={'btn mr-2'}
                                                                                    style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                                                    onClick={() => cancelTask(selectedTask.id)}
                                                                                >
                                                                                    <span>ANNULEER</span>
                                                                                </button>
                                                                                <button
                                                                                    className={'btn mr-2'}
                                                                                    style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                                                    onClick={() => pauseTask(selectedTask.id)}
                                                                                >
                                                                                    <span>PAUZEER</span>
                                                                                </button>
                                                                                <button
                                                                                    className={'btn mr-2'}
                                                                                    style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                                                    onClick={() => endTask(selectedTask.id, selectedTask.project.id)}
                                                                                >
                                                                                    <span>EVALUEER</span>
                                                                                </button>
                                                                            </>
                                                                        ) : null}
                                                                            
                                                                        {selectedTask.status === 'FINISHED' || selectedTask.status === 'DONE' ? (
                                                                            <>
                                                                                Deze taak is reeds beëindigd
                                                                            </>
                                                                        ) : null}
                                                                    </>
                                                                ) : null}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>Start- eindtijd</th>
                                                            <td style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                                                {new Intl.DateTimeFormat("nl-BE", {
                                                                year: "numeric",
                                                                month: "long",
                                                                day: "2-digit",
                                                                hour: "2-digit",
                                                                minute: "2-digit"
                                                            }).format(new Date(selectedTask.plannedDateForExecution))} - {new Intl.DateTimeFormat("nl-BE", {
                                                                year: "numeric",
                                                                month: "long",
                                                                day: "2-digit",
                                                                hour: "2-digit",
                                                                minute: "2-digit"
                                                            }).format(new Date(selectedTask.plannedEndDateForExecution))}
                                                            
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>Uitvoerders</th>
                                                            <td style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                                                {selectedTask.responsibleUser !== undefined && selectedTask.responsibleUser !== null ? (
                                                                    <>{selectedTask.responsibleUser.firstName} {selectedTask.responsibleUser.lastName}</>
                                                                ) : (
                                                                    <>
                                                                        {selectedTask.responsibleUsers.map((responsibleUser, index) => {
                                                                            return <>{index !== 0 ? ', ': null}{responsibleUser.firstName} {responsibleUser.lastName}</>
                                                                        })}
                                                                    </>
                                                                )}
                                                                
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>Verantwoordelijke</th>
                                                            <td style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>{selectedTask.accountableUser.firstName} {selectedTask.accountableUser.lastName}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR, verticalAlign: 'top'}}>Project</th>
                                                            <td style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                                                {selectedTask.project !== null && selectedTask.project !== undefined ? (
                                                                    <>
                                                                        <p>
                                                                            <strong>{selectedTask.project.name}</strong>
                                                                        </p>
                                                                        <p dangerouslySetInnerHTML={{__html: selectedTask.project.description}} />
                                                                        <p>
                                                                            <a
                                                                                className={'btn mr-2'}
                                                                                style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                                                target={'_blank'}
                                                                                href={'/project/detail/'+ selectedTask.project.id}
                                                                            ><i className="icofont icofont-2x icofont-eye-alt"></i> Project openen</a>

                                                                            <a
                                                                                className={'btn mr-2'}
                                                                                style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                                                target={'_blank'}
                                                                                href={'/project/'+ selectedTask.project.id + '/workorder/create'}
                                                                            ><i className="icofont icofont-2x icofont-addons"></i> Werkbon aanmaken</a>
                                                                        </p>
                                                                    </>
                                                                ) : null}
                                                                
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR, verticalAlign: 'top'}}>Locatie</th>
                                                            <td style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                                                {selectedTask.project !== null && selectedTask.project !== undefined ? (
                                                                    <>
                                                                        {selectedTask.project.street} {selectedTask.project.houseNumber} {selectedTask.project.bus},
                                                                        {selectedTask.project.postalCode} {selectedTask.project.city},
                                                                        {selectedTask.project.country !== null ? selectedTask.project.country.name : null}
                                                                        <br />
                                                                        <br />
                                                                        {lat !== null && lng !== null ? (
                                                                            <>
                                                                                <a
                                                                                    className={'btn mr-2'}
                                                                                    style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                                                    target={'_blank'}
                                                                                    href={'https://www.google.be/maps/dir//' + selectedTask.project.street + ' ' + selectedTask.project.houseNumber + ',' + selectedTask.project.postalCode + ' ' + selectedTask.project.city/* + '/@' + this.state.lat + ','+ this.state.lng*/}
                                                                                >
                                                                                    <i className="icofont icofont-2x icofont-social-google-map"></i> Google Maps
                                                                                </a>
                                                                                <a
                                                                                    className={'btn mr-3'}
                                                                                    style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                                                    target={'_blank'}
                                                                                    href={'https://www.waze.com/ul?ll=' + lat + ',' + lng + '&navigate=yes&zoom=17'}
                                                                                >
                                                                                    <i className="icofont icofont-2x icofont-direction-sign"></i> Waze
                                                                                </a>
                                                                            </>
                                                                        ) :(
                                                                            <>
                                                                                Er zijn geen routebeschrijvingen beschikbaarh. De geocoördinaten konden niet berekend worden.
                                                                            </>
                                                                        )}
                                                                    </>
                                                                ) : null}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR, verticalAlign: 'top'}}>Notities</th>
                                                            <td style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}></td>
                                                        </tr>
                                                        <tr style={{border: '0'}}>
                                                            <td colSpan={2} style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR, borderTop: '0px solid #000000'}}>
                                                                {selectedTaskProjectNotes.map((note) => {
                                                                    return (
                                                                        <Row>
                                                                            <Col lg={3} style={{fontSize: '9px'}}>{new Intl.DateTimeFormat("nl-BE", {
                                                                            year: "numeric",
                                                                            month: "numeric",
                                                                            day: "2-digit",
                                                                            hour: "2-digit",
                                                                            minute: "2-digit"
                                                            }).format(new Date(note.creationDate))}</Col>
                                                                            <Col lg={8}><p dangerouslySetInnerHTML={{__html: note.note}}></p></Col>
                                                                        </Row>
                                                                        
                                                                    );
                                                                })}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </Col>
                                            </Row>
                                            <Row className={'mt-3'}>
                                                <Col sm={12} xs={12} md={12} lg={12}>
                                                    <Card style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}>
                                                        <CardBody>
                                                            <h6><strong>KLANT</strong></h6>
                                                            {selectedTask.workOrder !== null && selectedTask.workOrder !== undefined ? (
                                                                selectedTask.workOrder.project !== null && selectedTask.workOrder.project !== undefined ? (
                                                                    selectedTask.workOrder.project.customer !== null && selectedTask.workOrder.project.customer !== undefined ?
                                                                        (
                                                                            <table style={{width:'100%'}}>
                                                                                <tr>
                                                                                    <td style={{width: '50%'}}>
                                                                                        {selectedTask.workOrder.project.customer.firstName} {selectedTask.workOrder.project.customer.lastName}
                                                                                    </td>
                                                                                    <td style={{width: '50%'}}>
                                                                                        {_.isEmpty(selectedTask.workOrder.project.customer.primaryEmail) ? null : (<><span className={'fa fa-envelope'}></span> {selectedTask.workOrder.project.customer.primaryEmail}<br /></>)}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{width: '50%'}}>
                                                                                        {_.isEmpty(selectedTask.workOrder.project.customer.primaryPhone) ? null : (<><span className={'fa fa-phone'}></span> <a style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}} href={'tel:' + selectedTask.workOrder.project.customer.primaryPhone}>{selectedTask.workOrder.project.customer.primaryPhone}</a><br /></>)}
                                                                                    </td>
                                                                                    <td style={{width: '50%'}}>
                                                                                        {_.isEmpty(selectedTask.workOrder.project.customer.primaryMobile) ? null : (<><span className={'fa fa-mobile-phone'}></span> <a style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}} href={'tel:' + selectedTask.workOrder.project.customer.primaryMobile}>{selectedTask.workOrder.project.customer.primaryMobile}</a><br /></>)}
                                                                                    </td>
                                                                                </tr>
                                                                                </table>
                                                                        ) : null
                                                                ) : null
                                                            ) : (
                                                                selectedTask.project !== null && selectedTask.project !== undefined ? (
                                                                        selectedTask.project !== null && selectedTask.project !== undefined ? (
                                                                            selectedTask.project.customer !== null && selectedTask.project.customer !== undefined ?
                                                                                (
                                                                                    <table style={{width:'100%'}}>
                                                                                        <tr>
                                                                                            <td style={{width: '50%'}}>
                                                                                                {selectedTask.project.customer.firstName} {selectedTask.project.customer.lastName}<br />
                                                                                            </td>
                                                                                            <td style={{width: '50%'}}>
                                                                                                {_.isEmpty(selectedTask.project.customer.primaryEmail) ? null : (<><span className={'fa fa-envelope'}></span> {selectedTask.project.customer.primaryEmail}<br /></>)}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td style={{width: '50%'}}>
                                                                                                {_.isEmpty(selectedTask.project.customer.primaryPhone) ? null : (<><span className={'fa fa-phone'}></span> <a style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}} href={'tel:' + selectedTask.project.customer.primaryPhone}>{selectedTask.project.customer.primaryPhone}</a><br /></>)}
                                                                                            </td>
                                                                                            <td style={{width: '50%'}}>
                                                                                                {_.isEmpty(selectedTask.project.customer.primaryMobile) ? null : (<><span className={'fa fa-mobile-phone'}></span> <a style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}} href={'tel:' + selectedTask.project.customer.primaryMobile}>{selectedTask.project.customer.primaryMobile}</a><br /></>)}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </table>
                                                                                ) : null
                                                                        ) : null
                                                                    ) : null
                                                            )}
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            
                                            {selectedTask.workOrder !== null && selectedTask.workOrder !== undefined ? (
                                                <>
                                                    <Row className={'mt-3'}>
                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                            <h6>
                                                                <strong>WERKBON: {selectedTask.workOrder.name}</strong>
                                                                <Link
                                                                    className={'btn float-right'}
                                                                    style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                                    target={'_blank'}
                                                                    to={'/workorder/detail/'+ selectedTask.workOrder.id}
                                                                ><i className="icofont icofont-2x icofont-eye-alt"></i></Link>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                            <div dangerouslySetInnerHTML={{__html: selectedTask.workOrder.description}} />
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : null}
                                        </>
                                    )}
                                        </>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
}

export default Project;