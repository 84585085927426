import React, { Fragment, useState, useMemo, useEffect } from 'react';
import {Card, CardBody, Col, Input, Row} from "reactstrap";
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import WorkSharpIcon from '@material-ui/icons/WorkSharp';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { serratedTabsStylesHook } from '@mui-treasury/styles/tabs';
import WorkOrderPhotosBefore from './workOrderPhotosBefore';
import API from '../../utils/API';
import WorkOrderPhotosAfter from './workOrderPhotosAfter';
import WorkOrderPhotosGeneral from './workOrderPhotosGeneral';

const WorkOrderPhotos = (props) =>  {

    const [workOrder, setWorkOrder] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const tabsStyles = serratedTabsStylesHook.useTabs();
    const tabItemStyles = serratedTabsStylesHook.useTabItem({bgColor: '#ececec', selectedBgColor: '#dc6809'});

    const onWorkOrderChange = () => {
        if (workOrder !== null) {
            API.get('workorder/' + workOrder.id).then(function(response) {
                setWorkOrder(response.data);
            });
        }
    }

    useEffect(() => {
        setWorkOrder(props.workOrder);
    }, []);

    return (
        <>
            <Card>
                <CardBody>
                {workOrder !== null ? (
                    <>
                        <Row>
                            <Col lg={12}>
                                <AppBar position={'static'} elevation={0} style={{ backgroundColor: 'transparent' }}>
                                    <Toolbar style={{ overflow: 'hidden', minHeight: 72 }}>
                                        <Row>
                                            <Col lg={12}>
                                                <Tabs style={{ alignSelf: 'flex-end' }} classes={tabsStyles} value={tabValue} onChange={(e, index) => setTabValue(index)}>
                                                    <Tab classes={tabItemStyles} disableRipple icon={<WorkSharpIcon />} label={"Voor de werken"}></Tab>
                                                    <Tab classes={tabItemStyles} disableRipple icon={<DescriptionSharpIcon />} label={"Na de werken"}></Tab>
                                                    <Tab classes={tabItemStyles} disableRipple icon={<ScheduleIcon />} label={"Algmeen"}></Tab>
                                                </Tabs>
                                            </Col>
                                        </Row>
                                    </Toolbar>
                                </AppBar>
                            </Col>
                        </Row>
                        <Row className={'mt-3 ml-3 mr-3'}>
                            <Col lg={12}>
                                {tabValue === 0 ? (
                                    <WorkOrderPhotosBefore workOrder={workOrder} onWorkOrderChange={onWorkOrderChange} />
                                ) : null}
                                {tabValue === 1 ? (
                                    <WorkOrderPhotosAfter workOrder={workOrder} onWorkOrderChange={onWorkOrderChange} />
                                ) : null} 
                                {tabValue === 2 ? (
                                    <WorkOrderPhotosGeneral workOrder={workOrder} onWorkOrderChange={onWorkOrderChange} />
                                ) : null}  
                            </Col>
                        </Row>
                    </>
                ) : null}
                    
                </CardBody>
            </Card>
        </>
    );
};

export default WorkOrderPhotos;