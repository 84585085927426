import React, { Fragment } from 'react';
import CKEditors from "react-ckeditor-component";
import _ from "lodash";
import API from "../../../utils/API";
import {Col, Row} from "reactstrap";
import {Typeahead} from "react-bootstrap-typeahead";

class WorkOrderInformation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            workOrder : {
                name: '',
                description : '',
                template:  null,
                responsibleUsers: [],
                accountableUsers: [],
                plannedDateForExecution: null,
                plannedTimeForExecution: null,
                estimatedDaysWork : 0,
                estimatedHoursWork : 0
            },
            workOrderTemplates : [],
            workingUsers : [],
            errors : {
                name: "",
                description: "",
                template : ""
            }
        };

        this._validateOnDemand = true;
    }

    componentDidMount() {
        let me = this;
        API.get('/users/workers').then(function (response) {
            me.setState({workingUsers: response.data}, function() {
                console.log(me.state);
            })
        });
        API.get('/workordertemplates').then(function (response) {
            me.setState({workOrderTemplates: response.data}, function() {
                console.log(me.state);
            })
        });
    }

    componentWillUnmount() {
    }

    isValidated = () => {
        let isDataValid = true;
        this.props.updateWorkOrderStore(this.state.workOrder);
        return this.validationCheck();
    };

    validationCheck = () => {
        let isValid = true;
        if (_.isEmpty(this.state.workOrder.name)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, name : "Fout opgetreden" }}));
            isValid = false;
        } else {
            this.setState((prevState) => ({errors: { ...prevState.errors, name : "" }}));
        }

        if (_.isEmpty(this.state.workOrder.description)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, description : "Fout opgetreden" }}));
            isValid = false;
        } else {
            this.setState((prevState) => ({errors: { ...prevState.errors, description : "" }}));
        }
        return isValid;
    };

    changeWorkOrderName = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            workOrder: { ...prevState.workOrder, name : value }
        }));
    };

    changeWorkOrderDescription = (event) => {
        let value = event.editor.getData();
        this.setState((prevState) => ({
            workOrder: { ...prevState.workOrder, description : value }
        }));
    };

    changeTaskEstimatedHoursWork = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({workOrder: { ...prevState.workOrder, estimatedHoursWork : value }}));
    };

    changeTaskEstimatedDaysWork = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({workOrder: { ...prevState.workOrder, estimatedDaysWork : value }}));
    };

    changeResponsibleUsers = (selectedArray) => {
        this.setState((prevState) => ({workOrder: { ...prevState.workOrder, responsibleUsers : selectedArray }}), function() {
            console.log(this.state);
        });
    };

    changeAccountableUsers = (selectedArray) => {
        this.setState((prevState) => ({workOrder: { ...prevState.workOrder, accountableUsers : selectedArray }}), function() {
            console.log(this.state);
        });
    };

    changeWorkOrderTemplate = (selectedArray) => {
        this.setState((prevState) => ({workOrder: { ...prevState.workOrder, template : selectedArray[0] }}), function() {
            console.log(this.state);
        });
    };

    changePlannedDayOfExecution = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({workOrder: { ...prevState.workOrder, plannedDateForExecution : value }}));
    };

    changePlannedTimeOfExecution = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({workOrder: { ...prevState.workOrder, plannedTimeForExecution : value }}));
    };

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Naam</label>
                                    <input className="form-control" type="text" name="name" value={this.state.workOrder.name} onChange={this.changeWorkOrderName} />
                                    {this.state.errors.name ? <span className={'errorInForm'}>Naam is verplicht</span> : null }
                                </div>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className="col-sm-12 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Beschrijving</label>
                                    <CKEditors
                                        activeclassName="p10"
                                        content={this.state.workOrder.description}
                                        events={{
                                            "change": this.changeWorkOrderDescription
                                        }}
                                    />
                                    {this.state.errors.description ? <span className={'errorInForm'}>Beschrijving is verplicht</span> : null}
                                </div>
                            </div>
                        </div>
                        <Row>
                            <Col lg={12}>
                                <label>Sjabloon</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Typeahead
                                    ref="workOrderTemplate"
                                    labelKey={option => `${option.name}`}
                                    multiple={false}
                                    onChange={this.changeWorkOrderTemplate}
                                    options={this.state.workOrderTemplates}
                                    placeholder="Kies een sjabloon ..."
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <label className={'col-xl-12'}>Geplande uitvoeringsdatum:</label>
                            </Col>
                            <Col lg={6}>
                                <input type={'date'} className={'form-control digits'} value={this.state.workOrder.plannedDateForExecution} onChange={this.changePlannedDayOfExecution} />
                            </Col>
                            <Col lg={6}>
                                <input type={'time'} className={'form-control digits'} value={this.state.workOrder.plannedTimeForExecution} onChange={this.changePlannedTimeOfExecution} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Row>
                                    <label className={'col-xl-12'}>
                                        Aantal dagen werk:
                                    </label>
                                    <Col xl={12}>
                                        <input type={'number'} className={'form-control digits'} value={this.state.workOrder.estimatedDaysWork} onChange={this.changeTaskEstimatedDaysWork} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row>
                                    <label className={'col-xl-12'}>
                                        Aantal uren werk:
                                    </label>
                                    <Col xl={12}>
                                        <input type={'number'} className={'form-control digits'} value={this.state.workOrder.estimatedHoursWork} onChange={this.changeTaskEstimatedHoursWork} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <label>Uitvoerende personen</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Typeahead
                                    ref="responsibleUsersSelector"
                                    labelKey={option => `${option.firstName} ${option.lastName}`}
                                    multiple={true}
                                    onChange={this.changeResponsibleUsers}
                                    options={this.state.workingUsers}
                                    placeholder="Kies een medewerker ..."
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <label>Verantwoordelijke personen</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Typeahead
                                    ref="accountableUsersSelector"
                                    labelKey={option => `${option.firstName} ${option.lastName}`}
                                    multiple={true}
                                    onChange={this.changeAccountableUsers}
                                    options={this.state.workingUsers}
                                    placeholder="Kies een medewerker ..."
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
                <br />
                <br />
            </Fragment>
        );
    }
};

export default WorkOrderInformation;