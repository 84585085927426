import React from 'react';
import Header from './common/header-component/header';
import Footer from './common/footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { white, orange } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@material-ui/core';


const AppLayout = ({children}) => {

    const theme = createTheme({
        palette: {
          primary: {
            main: '#ffffff',
          },
        },
      });

    return (
        <ThemeProvider theme={theme}>
            <div>
                <div className="page-wrapper">
                    <div className="page-body-wrapper">
                        <Header />
                        <div className="page-body">
                            {children}
                        </div>
                        <Footer />
                    </div>
                </div>
                <ToastContainer />
            </div>        
        </ThemeProvider>
    );
}

export default AppLayout;