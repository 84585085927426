import React, {Fragment} from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import CKEditors from "react-ckeditor-component";
import _ from "lodash";
import {Col, Row} from "reactstrap";
import Loader from "../common/loader";
import CurrencyInput from "react-currency-input";

class OfferDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading : false,
            establishments: [],
            project: null,
            showSuccessDialog: false,
            offer: null,
            showChangeCreator : false,
            showChangeCustomer : false,
            showChangeExtraInformation : false,
            showChangeDetails : false
        };
    }

    componentDidMount() {
        const {id} = this.props.match.params;
        let me = this;
        API.get('/offer/' + id).then(function (response) {
            me.setState({offer: response.data}, function () {
               me.setState({project: response.data.project}, function() {
                   console.log(me.state);
               });
            });
        });
        API.get('establishments').then(function (response) {
            me.setState({establishments: response.data});
        });
    }

    changeShowChangeCreator = (event) => {
        event.preventDefault();
        this.setState({showChangeCreator : !this.state.showChangeCreator});
    }

    changeShowChangeCustomer = (event) => {
        event.preventDefault();
        this.setState({showChangeCustomer : !this.state.showChangeCustomer});
    }

    changeShowChangeExtraInformation = (event) => {
        event.preventDefault();
        this.setState({showChangeExtraInformation : !this.state.showChangeExtraInformation});
    }

    changeShowChangeDetails = (event) => {
        event.preventDefault();
        this.setState({showChangeDetails : !this.state.showChangeDetails});
    }

    updateOffer = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        var me = this;
        const {id} = this.props.match.params;
        API.put('/offer/' + id, this.state.offer).then(function() {
            me.setState({loading: false});
            me.props.history.push('/project/detail/' + me.state.project.id);
        })
    };

    changeEstablishment = (event) => {
        let value = event.target.value;
        let me = this;
        for (const [index, establishment] of this.state.establishments.entries()) {
            if (parseInt(establishment.id) === parseInt(value)) {
                this.setState((prevState) => ({offer: { ...prevState.offer, creator: {
                    id : establishment.id,
                    companyName: establishment.companyName,
                    cbeNumber: establishment.cbeNumber,
                    street: establishment.street,
                    houseNumber: establishment.houseNumber,
                    bus: establishment.bus,
                    postalCode: establishment.postalCode,
                    city: establishment.city,
                    country: establishment.country ? establishment.country.name : '',
                    email: establishment.email,
                    phone: establishment.phone,
                    mobile: establishment.mobile
                }}}), function() {
                    console.log(me.state);
                });
            }
        }
    };

    changeExtraInformation = (event) => {
        let value = event.editor.getData();
        this.setState((prevState) => ({
            offer: { ...prevState.offer, extraInformation : value }
        }));
    };

    changeExternalReference1 = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, externalReference1 : value }
        }));
    };

    changeExternalReference2 = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, externalReference2 : value }
        }));
    };

    changeExternalReference3 = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, externalReference3 : value }
        }));
    };

    changeCustomerCompanyName = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, customer : {...prevState.offer.customer, companyName : value}}
        }));
    };

    changeCustomerFirstName = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, customer : {...prevState.offer.customer, firstName : value}}
        }));
    };

    changeCustomerLastName = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, customer : {...prevState.offer.customer, lastName : value}}
        }));
    };

    changeCustomerCbeNumber = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, customer : {...prevState.offer.customer, cbeNumber : value}}
        }));
    };

    changeCustomerStreet = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, customer : {...prevState.offer.customer, street : value}}
        }));
    };

    changeCustomerHouseNumber = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, customer : {...prevState.offer.customer, houseNumber : value}}
        }));
    };

    changeCustomerBus = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, customer : {...prevState.offer.customer, bus : value}}
        }));
    };

    changeCustomerPostalCode = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, customer : {...prevState.offer.customer, postalCode : value}}
        }));
    };

    changeCustomerCity = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, customer : {...prevState.offer.customer, city : value}}
        }));
    };

    changeCreatorCompanyName = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, creator : {...prevState.offer.creator, companyName : value}}
        }));
    };

    changeCreatorCbeNumber = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, creator : {...prevState.offer.creator, cbeNumber : value}}
        }));
    };

    changeCreatorStreet = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, creator : {...prevState.offer.creator, street : value}}
        }));
    };

    changeCreatorHouseNumber = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, creator : {...prevState.offer.creator, houseNumber : value}}
        }));
    };

    changeCreatorBus = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, creator : {...prevState.offer.creator, bus : value}}
        }));
    };

    changeCreatorPostalCode = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, creator : {...prevState.offer.creator, postalCode : value}}
        }));
    };

    changeCreatorCity = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, creator : {...prevState.offer.creator, city : value}}
        }));
    };

    changeCreatorEmail = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, creator : {...prevState.offer.creator, email : value}}
        }));
    };

    changeCreatorPhone = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, creator : {...prevState.offer.creator, phone : value}}
        }));
    };

    changeCreatorMobile = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            offer: { ...prevState.offer, creator : {...prevState.offer.creator, mobile : value}}
        }));
    };

    addOfferItem = (event) => {
        event.preventDefault();
        let me = this;
        this.setState((prevState) => ({offer: { ...prevState.offer, offerItems: [...prevState.offer.offerItems, {description: "", numberOfItems : 1, unitOfItems : "Stuks", unitPrice: 0.00, vatPercentage: "21", vatAmount: 0.00, totalAmount: 0.00 }]}}), function() {
            console.log(me.state);
        });
    };

    changeOfferItem2 = (event, maskedvalue, floatvalue, index) => {
        event.preventDefault();
        const list = [...this.state.offer.offerItems];
        list[index]['unitPrice'] = floatvalue;

            list[index]['vatAmount'] = list[index]['vatPercentage'] / 100 * floatvalue * list[index]['numberOfItems'];
            list[index]['totalAmount'] = (1 + list[index]['vatPercentage'] / 100) * floatvalue * list[index]['numberOfItems'];

        this.setState((prevState) => ({offer: { ...prevState.offer, offerItems: list}}));
    };


    changeOfferItem = (event, index) => {
        const {name, value} = event.target;
        const list = [...this.state.offer.offerItems];
        list[index][name] = value;

        if (name === 'unitPrice' || name === 'numberOfItems' || name === 'vatPercentage') {
            list[index]['vatAmount'] = list[index]['vatPercentage'] / 100 * list[index]['unitPrice'] * list[index]['numberOfItems'];
            list[index]['totalAmount'] = (1 + list[index]['vatPercentage'] / 100) * list[index]['unitPrice'] * list[index]['numberOfItems'];
        }
        this.setState((prevState) => ({offer: { ...prevState.offer, offerItems: list}}));
    };

    removeOfferItem = (index) => {
        let me = this;
        const list = [...this.state.offer.offerItems];
        list.splice(index, 1);
        this.setState((prevState) => ({offer: { ...prevState.offer, offerItems: list}}), function() {
            console.log(me.state);
        });
    };

    render() {
        return (
            this.state.project !== null && this.state.offer !== null ? (
                this.state.loading ? (
                  <Loader />
                ) : (
                    <Fragment>
                        <Breadcrumb parent={'Project ' + this.state.project.name} title={"Offerte " + this.state.offer.offerNumber + " (" + this.state.offer.status + ")" } />
                        <div className="container-fluid">
                            <div className="edit-profile">
                                <form onSubmit={this.createNewOffer}>
                                    {!this.state.offer.signedForApproval /*&& this.state.offer.status !== 'FINISHED' */ ? (
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={'card'}>
                                                    <div className="card-body">
                                                        <div className={'row form-group'}>
                                                            <label className={'col-xl-6'}>Offerte aanmaken in naam van</label>
                                                            <div className={'col-xl-6'}>
                                                                <select onChange={this.changeEstablishment}
                                                                        className={'form-control'} name={'establishmentId'}>
                                                                    <option></option>
                                                                    {this.state.establishments.map(function (establishment) {
                                                                        return (
                                                                            <option
                                                                                value={establishment.id}>{establishment.companyName}</option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}

                                    <Row>
                                        <Col lg={6}>
                                            <div className={'card'}>
                                                <div className={'card-body'}>
                                                    <div className={'form-group row'}>
                                                        <div className={'col-sm-11'}>
                                                            <h4>Maker van offerte:</h4>
                                                        </div>
                                                        {!this.state.offer.signedForApproval && this.state.offer.status !== 'FINISHED' ? (
                                                            <div className={'col-sm-1'}>
                                                                <a onClick={(event) => this.changeShowChangeCreator(event)} href={'#'}><h4 className={'fa fa-pencil'}></h4></a>
                                                            </div>
                                                        ) : null}
                                                    </div>

                                                    {!this.state.offer.signedForApproval && /*this.state.offer.status !== 'FINISHED' &&*/ this.state.showChangeCreator ? (
                                                        <>
                                                            <div className={'row'}>
                                                                <label className={'col-xl-6'}>Bedrijfsnaam</label>
                                                                <label className={'col-xl-6'}>Ondernemingsnummer</label>
                                                            </div>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-6'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCreatorCompanyName} value={this.state.offer.creator ? this.state.offer.creator.companyName : ''} />
                                                                </div>
                                                                <div className={'col-xl-6'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCreatorCbeNumber} value={this.state.offer.creator ? this.state.offer.creator.cbeNumber : ''} />
                                                                </div>
                                                            </div>
                                                            <div className={'row'}>
                                                                <label className={'col-xl-8'}>Straat</label>
                                                                <label className={'col-xl-2'}>Huisnummer</label>
                                                                <label className={'col-xl-2'}>Bus</label>
                                                            </div>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-8'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCreatorStreet} value={this.state.offer.creator ? this.state.offer.creator.street : ''} />
                                                                </div>
                                                                <div className={'col-xl-2'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCreatorHouseNumber} value={this.state.offer.creator ? this.state.offer.creator.houseNumber : ''} />
                                                                </div>
                                                                <div className={'col-xl-2'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCreatorBus} value={this.state.offer.creator ? this.state.offer.creator.bus : ''} />
                                                                </div>
                                                            </div>

                                                            <div className={'row'}>
                                                                <label className={'col-xl-2'}>Postcode</label>
                                                                <label className={'col-xl-5'}>Gemeente</label>
                                                                <label className={'col-xl-5'}>Land</label>
                                                            </div>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-2'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCreatorPostalCode} value={this.state.offer.creator ? this.state.offer.creator.postalCode : ''} />
                                                                </div>
                                                                <div className={'col-xl-5'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCreatorCity} value={this.state.offer.creator ? this.state.offer.creator.city : ''} />
                                                                </div>
                                                                <div className={'col-xl-5'}>
                                                                    <input type={'text'} className={'form-control'} value={this.state.offer.creator ? this.state.offer.creator.country : ''} />
                                                                </div>
                                                            </div>
                                                            <div className={'row'}>
                                                                <label className={'col-xl-12'}>E-mailadres</label>
                                                            </div>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-12'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCreatorEmail} value={this.state.offer.creator ? this.state.offer.creator.email : ''} />
                                                                </div>
                                                            </div>
                                                            <div className={'row'}>
                                                                <label className={'col-xl-6'}>Telefoon</label>
                                                                <label className={'col-xl-6'}>GSM</label>
                                                            </div>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-6'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCreatorPhone} value={this.state.offer.creator ? this.state.offer.creator.phone : ''} />
                                                                </div>
                                                                <div className={'col-xl-6'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCreatorMobile} value={this.state.offer.creator ? this.state.offer.creator.mobile : ''} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        this.state.offer.creator !== null ? (
                                                            <>
                                                                <strong>
                                                                    {this.state.offer.creator.companyName} <br />
                                                                    {this.state.offer.creator.cbeNumber} <br />
                                                                </strong>
                                                                <br />{this.state.offer.creator.street} {this.state.offer.creator.houseNumber} {this.state.offer.creator.bus}<br />
                                                                {this.state.offer.creator.postalCode} {this.state.offer.creator.city} <br />
                                                                {this.state.offer.creator.country}<br />
                                                                <br />
                                                                Email: {this.state.offer.creator.email}<br />
                                                                Tel: {this.state.offer.creator.phone}<br />
                                                                GSM: {this.state.offer.creator.mobile}
                                                            </>
                                                        ) : null
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className={'card'}>
                                                <div className={'card-body'}>
                                                    <div className={'form-group row'}>
                                                        <div className={'col-sm-11'}>
                                                            <h4>Aanvrager van offerte:</h4>
                                                        </div>
                                                        {!this.state.offer.signedForApproval /*&& this.state.offer.status !== 'FINISHED' */? (
                                                            <div className={'col-sm-1'}>
                                                                <a onClick={(event) => this.changeShowChangeCustomer(event)} href={'#'}><h4 className={'fa fa-pencil'}></h4></a>
                                                            </div>
                                                        ) : null}
                                                    </div>

                                                    {!this.state.offer.signedForApproval /*&& this.state.offer.status !== 'FINISHED'*/ && this.state.showChangeCustomer ? (
                                                        <>
                                                            <div className={'row'}>
                                                                <label className={'col-xl-12'}>Bedrijfsnaam</label>
                                                            </div>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-12'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCustomerCompanyName} value={this.state.offer.customer ? this.state.offer.customer.companyName : ''} />
                                                                </div>
                                                            </div>
                                                            <div className={'row'}>
                                                                <label className={'col-xl-12'}>Ondernemingsnummer</label>
                                                            </div>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-12'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCustomerCbeNumber} value={this.state.offer.customer ? this.state.offer.customer.cbeNumber : ''} />
                                                                </div>
                                                            </div>
                                                            <div className={'row'}>
                                                                <label className={'col-xl-6'}>Voornaam</label>
                                                                <label className={'col-xl-6'}>Naam</label>
                                                            </div>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-6'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCustomerFirstName} value={this.state.offer.customer ? this.state.offer.customer.firstName : ''} />
                                                                </div>
                                                                <div className={'col-xl-6'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCustomerLastName} value={this.state.offer.customer ? this.state.offer.customer.lastName : ''} />
                                                                </div>
                                                            </div>
                                                            <div className={'row'}>
                                                                <label className={'col-xl-8'}>Straat</label>
                                                                <label className={'col-xl-2'}>Huisnummer</label>
                                                                <label className={'col-xl-2'}>Bus</label>
                                                            </div>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-8'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCustomerStreet} value={this.state.offer.customer ? this.state.offer.customer.street : ''} />
                                                                </div>
                                                                <div className={'col-xl-2'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCustomerHouseNumber} value={this.state.offer.customer ? this.state.offer.customer.houseNumber : ''} />
                                                                </div>
                                                                <div className={'col-xl-2'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCustomerBus} value={this.state.offer.customer ? this.state.offer.customer.bus : ''} />
                                                                </div>
                                                            </div>
                                                            <div className={'row'}>
                                                                <label className={'col-xl-2'}>Postcode</label>
                                                                <label className={'col-xl-5'}>Gemeente</label>
                                                                <label className={'col-xl-5'}>Land</label>
                                                            </div>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-2'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCustomerPostalCode} value={this.state.offer.customer ? this.state.offer.customer.postalCode : ''} />
                                                                </div>
                                                                <div className={'col-xl-5'}>
                                                                    <input type={'text'} className={'form-control'} onChange={this.changeCustomerCity} value={this.state.offer.customer ? this.state.offer.customer.city : ''} />
                                                                </div>
                                                                <div className={'col-xl-5'}>
                                                                    <input type={'text'} className={'form-control'} value={this.state.offer.customer ? this.state.offer.customer.country : ''} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {this.state.offer.customer ? (
                                                                <>
                                                                    <strong>
                                                                        {!_.isEmpty(this.state.offer.customer.companyName) ? (<>{this.state.offer.customer.companyName}<br /></>) : ''}
                                                                        {!_.isEmpty(this.state.offer.customer.cbeNumber) ? (<>{this.state.offer.customer.cbeNumber}<br /></>) : ''}
                                                                        {this.state.offer.customer.firstName} {this.state.offer.customer.lastName}<br />
                                                                    </strong>
                                                                    <br />
                                                                    {this.state.offer.customer.street} {this.state.offer.customer.houseNumber} {this.state.offer.customer.bus} <br />
                                                                    {this.state.offer.customer.postalCode} {this.state.offer.customer.city} <br />
                                                                    {this.state.offer.customer.country}
                                                                </>
                                                            ) : null}

                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className={'card'}>
                                                <div className={'card-body'}>
                                                    <div className={'form-group row'}>
                                                        <div className={'col-sm-11'}>
                                                            <h4>Extra informatie:</h4>
                                                        </div>
                                                        {!this.state.offer.signedForApproval /*&& this.state.offer.status !== 'FINISHED' */? (
                                                            <div className={'col-sm-1'}>
                                                                <a onClick={(event) => this.changeShowChangeExtraInformation(event)} href={'#'}><h4 className={'fa fa-pencil'}></h4></a>
                                                            </div>
                                                        ) : null}

                                                    </div>
                                                    {!this.state.offer.signedForApproval /*&& this.state.offer.status !== 'FINISHED'*/ && this.state.showChangeExtraInformation ? (
                                                        <>
                                                            <Row className={'form-group'}>
                                                                <Col lg={'3'}>
                                                                    Externe referentie 1:
                                                                </Col>
                                                                <Col lg={'9'}>
                                                                    <input type={'text'} className={'form-control'} name={'externalReference1'} value={this.state.offer.externalReference1} onChange={this.changeExternalReference1} />
                                                                </Col>
                                                            </Row>
                                                            <Row className={'form-group'}>
                                                                <Col lg={'3'}>
                                                                    Externe referentie 2:
                                                                </Col>
                                                                <Col lg={'9'}>
                                                                    <input type={'text'} className={'form-control'} name={'externalReference2'} value={this.state.offer.externalReference2} onChange={this.changeExternalReference2} />
                                                                </Col>
                                                            </Row>
                                                            <Row className={'form-group'}>
                                                                <Col lg={'3'}>
                                                                    Externe referentie 3:
                                                                </Col>
                                                                <Col lg={'9'}>
                                                                    <input type={'text'} className={'form-control'} name={'externalReference3'} value={this.state.offer.externalReference3} onChange={this.changeExternalReference3} />
                                                                </Col>
                                                            </Row>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-12'}>
                                                                    <CKEditors
                                                                        activeclassName="p10"
                                                                        content={this.state.offer.extraInformation}
                                                                        events={{
                                                                            "change": this.changeExtraInformation
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {!_.isEmpty(this.state.offer.externalReference1) ? (
                                                                <>
                                                                    Externe referentie 1: {this.state.offer.externalReference1} <br />
                                                                </>
                                                            ) : null}
                                                            {!_.isEmpty(this.state.offer.externalReference2) ? (
                                                                <>
                                                                    Externe referentie 2: {this.state.offer.externalReference2} <br />
                                                                </>
                                                            ) : null}
                                                            {!_.isEmpty(this.state.offer.externalReference3) ? (
                                                                <>
                                                                    Externe referentie 3: {this.state.offer.externalReference3} <br />
                                                                </>
                                                            ) : null}
                                                            {!_.isEmpty(this.state.offer.extraInformation) ? (
                                                                <>
                                                                    {this.state.offer.extraInformation}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    Geen extra informatie beschikbaar
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className={"card"}>
                                                <div className={'card-body'}>
                                                    <div className={'form-group row'}>
                                                        <div className={'col-sm-11'}>
                                                            <h4>Detaillijnen:</h4>
                                                        </div>
                                                        {!this.state.offer.signedForApproval /*&& this.state.offer.status !== 'FINISHED' */? (
                                                            <div className={'col-sm-1'}>
                                                                <a onClick={(event) => this.changeShowChangeDetails(event)} href={'#'}><h4 className={'fa fa-pencil'}></h4></a>
                                                            </div>
                                                        ) : null}

                                                    </div>
                                                    {!this.state.offer.signedForApproval /*&& this.state.offer.status !== 'FINISHED'*/ && this.state.showChangeDetails ? (
                                                        <>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-12'}>
                                                                    <div className={'row form-group'}>
                                                                        <div className={'col-xl-4'}>Beschrijving</div>
                                                                        <div className={'col-xl-1'}>Aantal</div>
                                                                        <div className={'col-xl-1'}>Eenheid</div>
                                                                        <div className={'col-xl-1'}>Eenheidsprijs</div>
                                                                        <div className={'col-xl-1'}>BTW %</div>
                                                                        <div className={'col-xl-1'}>BTW bedrag</div>
                                                                        <div className={'col-xl-1'}>Totaal</div>
                                                                        <div className={'col-xl-1'}></div>
                                                                        <div className={'col-xl-1'}></div>
                                                                    </div>
                                                                    {this.state.offer.offerItems.map((x,i) => {
                                                                        return (
                                                                            <div className={'row form-group'}>
                                                                                <label className={'col-xl-4'}>
                                                                                    <input className={'form-control'} name={'description'} value={x.description} onChange={e => this.changeOfferItem(e, i)} />
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    <input className={'form-control'} name={'numberOfItems'} value={x.numberOfItems} onChange={e => this.changeOfferItem(e, i)} />
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    <input className={'form-control'} name={'unitOfItems'} value={x.unitOfItems} onChange={e => this.changeOfferItem(e, i)} />
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    <CurrencyInput decimalSeparator="," thousandSeparator="."  className={'form-control'} name={'unitPrice'} value={x.unitPrice} onChangeEvent={(e, maskedvalue, floatvalue, index) => this.changeOfferItem2(e, maskedvalue, floatvalue, i)} />
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    <input className={'form-control'} name={'vatPercentage'} value={x.vatPercentage} onChange={e => this.changeOfferItem(e, i)} />
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(x.vatAmount)}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(x.totalAmount)}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {this.state.offer.offerItems.length !== 1 && <a href={'#'} className={'btn btn-primary'} onClick={(event) => this.removeOfferItem(i)}><span className={'fa fa-times'}></span></a>}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {this.state.offer.offerItems.length -1 === i && <a href={'#'} className={'btn btn-primary'} onClick={(event) => this.addOfferItem(event)}><span className={'fa fa-plus'}></span></a>}
                                                                                </label>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-12'}>
                                                                    <div className={'row form-group'}>
                                                                        <div className={'col-xl-4'}>Beschrijving</div>
                                                                        <div className={'col-xl-1'}>Aantal</div>
                                                                        <div className={'col-xl-1'}>Eenheid</div>
                                                                        <div className={'col-xl-1'}>Eenheidsprijs</div>
                                                                        <div className={'col-xl-1'}>BTW %</div>
                                                                        <div className={'col-xl-1'}>BTW bedrag</div>
                                                                        <div className={'col-xl-1'}>Totaal</div>
                                                                        <div className={'col-xl-1'}></div>
                                                                        <div className={'col-xl-1'}></div>
                                                                    </div>
                                                                    {this.state.offer.offerItems.map((x,i) => {
                                                                        return (
                                                                            <div className={'row form-group'}>
                                                                                <label className={'col-xl-4'}>
                                                                                    {x.description}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {x.numberOfItems}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {x.unitOfItems}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(x.unitPrice)}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {x.vatPercentage}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(x.vatAmount)}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(x.totalAmount)}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                </label>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className={'card'}>
                                                <div className={'card-body'}>
                                                    <div className={'row'}>
                                                        <div className={'col-xl-12'}>
                                                            <h4 style={{textAlign: 'right'}}>Totaal: {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(this.state.offer.offerItems.reduce((totalPrice, offerItem) => totalPrice + parseFloat(offerItem.totalAmount),0))}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {!this.state.offer.signedForApproval /*&& this.state.offer.status !== 'FINISHED'*/ ? (
                                        <Row>
                                            <Col lg={12}>
                                                <div className={'card'}>
                                                    <div className={'card-body'}>
                                                        <div className="card-footer text-right">
                                                            <button className="btn btn-primary" type="submit"
                                                                    onClick={(event) => this.updateOffer(event)}>Offerte wijzigen
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    ) : null}
                                </form>
                            </div>
                        </div>
                    </Fragment>
                )
            ) : null

        );
    }
};

export default OfferDetail;