import axios from "axios";

export default axios.create({
    baseURL: process.env.REACT_APP_BASE_URL + '/api/',
    responseType: "json",
    headers : {
        'Authorization' : 'Bearer ' + localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN),
        'Accept' : '*/*',
        'Access-Control-Allow-Origin' : '*',
        'Origin' : '*'
    }
});