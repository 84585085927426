import React, { Fragment, useState, useMemo, useEffect } from 'react';
import ReactTable from "react-table-6";
import {Link} from "react-router-dom";
import {Card, CardBody, Col, Input, Row} from "reactstrap";
import API from '../../../utils/API';
import _ from "lodash";
import useReactTable from '../../../hooks/useReactTable';

const ProjectInvoices = (props) =>  {

    const [project, setProject] = useState(props.project);
    const [invoices, setInvoices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const columns = useMemo(() => [
        {
            Header : 'Factuurnummer',
            accessor : 'invoiceNumber',
            Cell : cell => (
                <div>
                    {
                        _.isEmpty(cell.value) ? (
                            <a href={`${process.env.PUBLIC_URL}/invoice/detail/` + cell.original.id}>Factuur in ontwerp</a>
                        ) : (
                            <a href={`${process.env.PUBLIC_URL}/invoice/detail/` + cell.original.id}>{cell.value}</a>
                        )
                    }
                </div>
            )
        },
        {
            Header : 'Factuurdatum',
            accessor : 'invoiceDate',
            width: 100,
            Cell : cell => (
                <>
                    {new Intl.DateTimeFormat('nl-BE', {dateStyle: 'medium'}).format(Date.parse(cell.value))}
                </>
            )
        },
        {
            Header : 'Vervaldatum',
            accessor : 'expiryDate',
            width: 100,
            Cell : cell => (
                <>
                    {new Intl.DateTimeFormat('nl-BE', {dateStyle: 'medium'}).format(Date.parse(cell.value))}
                </>
            )
        },
        {
            Header : 'Totaal excl.',
            accessor : 'totalExcl',
            width: 100,
            Cell : cell => (
                <>
                    {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(cell.value)}
                </>
            )
        },
        {
            Header : 'BTW',
            accessor : 'totalVat',
            width: 100,
            Cell : cell => (
                <>
                    {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(cell.value)}
                </>
            )
        },
        {
            Header : 'Totaal incl',
            accessor : 'totalIncl',
            width: 100,
            Cell : cell => (
                <>
                    {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(cell.value)}
                </>
            )
        },
        {
            Header : '',
            accessor : 'id',
            width: 50,
            Cell : cell => (
                cell.original.draft === true ? (
                    <>
                        <a href={'#'}  onClick={(event) => finalizeInvoice(event, cell.value)}><i className="icofont icofont-2x icofont-wallet"></i></a>
                    </>
                ) : null
            )
        },
        {
            Header : '',
            accessor : 'id',
            width: 50,
            Cell : cell => (

                    <>
                        {cell.original.draft === true ? null : /*(
                            <a href={'#'}  onClick={(event) => this.openFormToSendToExternalParty(event, "invoice", cell.original)}><i className="icofont icofont-2x icofont-email"></i></a>
                        )*/ null}
                    </>

            )
        },
        {
            Header : '',
            accessor : 'id',
            width: 50,
            Cell : cell => (
                cell.original.status === 'FINISHED' ? (
                    <>
                        {cell.original.draft === true ? null : /*(
                            <a href={'#'}  onClick={(event) => this.changeOfferStatus(event, cell.value, 'FINISHED')}><i className="icofont icofont-2x icofont-email"></i></a>
                        )*/ null}

                    </>
                ) : null
            )
        },
        {
            Header : '',
            accessor : 'id',
            width: 50,
            Cell : cell => (
                cell.original.status === 'FINISHED' ? (
                    <>
                        {cell.original.draft === true ? null : (
                            <a href={'#'}  onClick={(event) => sendInvoice(event, cell.value)}><i className="icofont icofont-2x icofont-email"></i></a>
                        )}

                    </>
                ) : null
            )
        },
        {
            Header : '',
            accessor : 'id',
            width: 50,
            Cell : cell => (
                <>
                    {cell.original.draft === true ? null : (
                        <a href={'#'} onClick={(event) => sendInvoice(event, cell.original.id)}><i className="icofont icofont-2x icofont-envelope"></i></a>
                    )}

                </>
            )
        },
        {
            Header : '',
            accessor : 'id',
            width: 50,
            Cell : cell => (
                <>
                    {cell.original.draft === true ? null : (
                        <a href={'#'} onClick={() => downloadInvoice(cell.original.id, cell.original.invoiceNumber)}><i className="icofont icofont-2x icofont-download"></i></a>
                    )}

                </>
            )
        },
        {
            Header: '',
            accessor: 'id',
            width: 75,
            headerClassName: 'text-center',
            Cell: row => (
                <>
                    {row.original.draft === true ? null : (
                        <Link to={`/invoice/` + row.original.id + `/creditnote`}><i className="icofont icofont-2x icofont-paper"></i></Link>
                    )}

                </>
            )
        }
    ]);

    useEffect(() => {
        if (project !== null) {
            API.get('/project/' + project.id + '/invoices').then(function(response) {
                setInvoices(response.data);
            });
        }
    }, [project]);

    const finalizeInvoice = (event, invoiceId) => {
        event.preventDefault();
        let me = this;
        setIsLoading(true);
        API.put('invoice/' + invoiceId + '/finalize').then(function(response) {
            API.get('/project/' + project.id + '/invoices').then(function(response) {
                setInvoices(response.data);
            });
            setIsLoading(false);
        });
    };

    const downloadInvoice = (invoiceId, invoiceNumber) => {
        API.get('pdf/invoice/' + invoiceId).then(function(response) {
            const linkSource = `data:application/pdf;base64,${response.data.base64}`;
            const downloadLink = document.createElement("a");
            const fileName = invoiceNumber + ".pdf";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        });
    };

    const sendInvoice = (event, invoiceId) => {
        event.preventDefault();
        API.get('mail/invoice/' + invoiceId);
    };

    return (
        <>
            <Row className={'mt-3'}>
                <Col lg={12}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <br />
                            <ReactTable
                                data={invoices}
                                columns={columns}
                                minRows={0}
                    defaultPageSize={10}
                    className={"-striped -highlight"}
                    showPagination={true}
                    previousText={'Vorige'}
                    nextText={'Volgende'}
                    pageText={'Pagina'}
                    rowsText={'rijen'}
                    ofText={'van'}
                    getTheadThProps={getTheadThProps}
                    getTdProps={getTdProps}
                    getTrProps={getTrProps}
                    getPaginationProps={getPaginationProps}
                            />
                        </div>
                    </div>
                    <br />
                    {project !== null ? (
                        <a href={`${process.env.PUBLIC_URL}/project/` + project.id + `/invoice/create`} className={'btn btn-primary'}><span className={'fa fa-plus'}></span> Nieuwe factuur toevoegen</a>
                    ) : null}
                </Col>
            </Row>
        </>
    );
};

export default ProjectInvoices;