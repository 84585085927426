import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import _ from "lodash";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import {Card, CardBody, Col, Row} from "reactstrap";
import moment from "moment-timezone";
import {Link} from "react-router-dom";
import Loader from "../common/loader";


class TasksOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentUser : null,
            isLoading: true,
            tasks : [],
            filtered: [],
            columns : [
                {
                    Header : 'Uitvoeringsdatum',
                    accessor : 'wishedDateForExecution',
                    Cell: row => {
                        if (_.isEmpty(row.row.wishedDateForExecution)) {
                            return <span>(geen datum)</span>
                        } else {
                            return new Intl.DateTimeFormat("nl-BE", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit"
                            }).format(new Date(row.row.wishedDateForExecution))
                        }
                    }
                },
                {
                    Header : 'Taak',
                    accessor : 'name',
                    width: 250,
                    Cell: row => {
                        return <Link to={`/task/detail/` + row.row._original.id} target={'_parent'}>{row.row.name}</Link>
                    }
                },
                {
                    Header : 'Status',
                    accessor : 'status',
                    width: 250,
                    Cell: row => {
                        return <span>{row.row.status}</span>
                    }
                },
                {
                    Header : 'Project',
                    accessor : 'project',
                    Cell: row => {
                        return <span>{row.row.projectName}</span>
                    }
                },
                {
                    Header : 'Werkbon',
                    accessor : 'workOrder',
                    Cell: row => {
                        if (row.row.workOrder !== undefined && row.row.workOrder !== null) {
                            return <span>{row.row.workOrder.name}</span>
                        } else {
                            return <span></span>
                        }
                    }
                },
                {
                    Header : 'Uitvoerder',
                    accessor : 'responsibleUser',
                    Cell: row => {
                        if (row.row.responsibleUser !== undefined && row.row.responsibleUser !== null) {
                            return <span>{row.row.responsibleUser.firstName} {row.row.responsibleUser.lastName}</span>
                        } else {
                            return <span></span>
                        }
                    }
                }
            ]
        };
    }

    componentDidMount() {
        const me = this;
        API.get('/myuser').then(function(response) {
            me.setState({currentUser: response.data[0]}, function() {
                me.loadTasks();
                me.setState({isLoading: false});
            });
        });

    }

    loadTasks = () => {
        let me = this;
        API.get('tasks').then(function(response) {
            me.setState({tasks: response.data}, function() {
                let list = [];
                me.state.tasks.map((task) => {

                    if (task.plannedDateForExecution !== null && task.plannedEndDateForExecution !== null) {
                        let startDate = new Date(task.plannedDateForExecution);
                        let endDate = new Date(task.plannedEndDateForExecution);
                        let firstItem = true;
                        for (let currentDate = new Date(task.plannedDateForExecution); currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
                            if (currentDate.getDay() === 1 || currentDate.getDay() === 2 || currentDate.getDay() === 3 || currentDate.getDay() === 4 || currentDate.getDay() === 5) {
                                let begin = new Date(currentDate);
                                let end = new Date(currentDate);

                                if (firstItem === true) {
                                    begin.setHours(startDate.getHours());
                                    begin.setMinutes(startDate.getMinutes());
                                    if ((endDate.getTime() - startDate.getTime()) / 1000 * 3600 * 24 > 1) {
                                        end.setHours(18);
                                        end.setMinutes(0);
                                    } else {
                                        end.setHours(currentDate.getHours());
                                        end.setMinutes(currentDate.getMinutes());
                                    }
                                    firstItem = false;
                                } else {
                                    begin.setHours(8);
                                    begin.setMinutes(0);
                                    if (endDate.getFullYear() === end.getFullYear() && endDate.getMonth() === end.getMonth() && endDate.getDate() === end.getDate()) {
                                        end.setHours(endDate.getHours());
                                        end.setMinutes(endDate.getMinutes());
                                    } else {
                                        end.setHours(18);
                                        end.setMinutes(0);
                                    }
                                }
                                list.push({
                                    taskId: task.id,
                                    title: task.name,
                                    project: task.projectName,
                                    executor: task.responsibleUser !== null ? task.responsibleUser.firstName : '',
                                    backgroundColor: task.responsibleUser !== null ? task.responsibleUser.colorCode : '#FF6600',
                                    customer: task.project !== null && task.project.customer !== null ? task.project.customer.firstName + ' ' + task.project.customer.lastName : '',
                                    location : task.project !== null ? task.project.street + ' ' + task.project.houseNumber + ' ' + task.project.bus + ', ' + task.project.postalCode + ' ' + task.project.city : '',
                                    //workorderId: task.workOrder !== null ? task.workOrder.id : -1,
                                    workorder: task.workOrderName,
                                    start: new Date(begin),
                                    end: new Date(end)
                                });
                            }
                        }
                    }

                });
                me.setState({calendarEvents: list});
            });
        });
    };

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1);
                    else filter["value"] = value;
                    insertNewFilter = 0;
                }
            });
        }
        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }
        this.setState({ filtered: filtered });
    };

    handleDateClick = (arg) => {
        //
    };

    handleEventClick = (eventInfo) => {
        let me = this;
        API.get('/task/' + eventInfo.event.extendedProps.taskId).then(function(response) {
            me.setState({task: response.data});
         });
    }

    renderEventContent = (eventInfo) => {
        return <>
        <b>{moment(eventInfo.event.start).format('HH:mm')} - {moment(eventInfo.event.end).format('HH:mm')}</b>
        <span className='ml-2'>{eventInfo.event.title}</span><br />
        <i><b>Klant:</b> {eventInfo.event.extendedProps.customer}</i><br />
        <i><b>Locatie:</b> {eventInfo.event.extendedProps.location}</i>
        <br />
        <i dangerouslySetInnerHTML={{__html:eventInfo.event.extendedProps.description }}></i>
        {eventInfo.event.extendedProps.taskId !== -1 ? (
            <>
                <br />
                Uitvoerder: {eventInfo.event.extendedProps.executor}
            </>
        ) : null}   
    </>
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb>Taken</Breadcrumb>
                {this.state.isLoading ? (
                    <Loader />
                ) : (
                    this.state.currentUser !== null && (this.state.currentUser.userRole === 'ADMIN' || this.state.currentUser.userRole === 'EMPLOYER') ? (
                        <>
                            <Row>
                                <Col lg={8}>
                                    <FullCalendar 
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                        initialView={'dayGridMonth'}
                                        dateClick={(arg) => this.handleDateClick(arg)}
                                        eventContent={(eventInfo) => this.renderEventContent(eventInfo)} 
                                        events={this.state.calendarEvents}
                                        eventClick={(a,b,c) => this.handleEventClick(a,b,c)}
                                    />
                                </Col>
                                <Col lg={4}>
                                    {this.state.task !== null && this.state.task !== undefined ? (
                                        <>
                                            <Card style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                                <CardBody>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                            <h3>
                                                                TAAK: {this.state.task.name}
                                                                <Link
                                                                    className={'btn float-right'}
                                                                    style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                                    target={'_blank'} to={'/task/detail/' + this.state.task.id}><i className="icofont icofont-2x icofont-eye-alt"></i></Link>
                                                            </h3>
                                                        </Col>
                                                    </Row>
                                                    <Row className={'mt-3'}>
                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                            <div dangerouslySetInnerHTML={{__html: this.state.task.description}} />
                                                        </Col>
                                                    </Row>
                                                    <Row className={'mt-3'}>
                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                            <table className={'table'}>
                                                                <tr>
                                                                    <th style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>Uitvoeringsdatum</th>
                                                                    <td style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>{new Intl.DateTimeFormat("nl-BE", {
                                                                        year: "numeric",
                                                                        month: "long",
                                                                        day: "2-digit",
                                                                        hour: "2-digit",
                                                                        minute: "2-digit"
                                                                    }).format(new Date(this.state.task.plannedDateForExecution))}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>Uitvoerders</th>
                                                                    <td style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                                                        {this.state.task.responsibleUser.firstName} {this.state.task.responsibleUser.lastName}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>Geschatte werkduur</th>
                                                                    <td style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>{this.state.task.estimatedDaysWork} dagen en {this.state.task.estimatedHoursWork} uren</td>
                                                                </tr>
                                                                <tr>
                                                                    <th style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>Verantwoordelijke</th>
                                                                    <td style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                                                        {this.state.task.accountableUser.firstName} {this.state.task.accountableUser.lastName}
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </Col>
                                                    </Row>
                                                    <Row className={'mt-3'}>
                                                        <Col sm={12} xs={12} md={12} lg={12}>

                                                            <h6><strong>KLANT</strong></h6>

                                                            {this.state.task.workOrder !== null && this.state.task.workOrder !== undefined ? (
                                                                this.state.task.workOrder.project !== null && this.state.task.workOrder.project !== undefined ? (
                                                                    this.state.task.workOrder.project.customer !== null && this.state.task.workOrder.project.customer !== undefined ?
                                                                        (
                                                                            <p>
                                                                                {this.state.task.workOrder.project.customer.firstName} {this.state.task.workOrder.project.customer.lastName}<br />
                                                                                {_.isEmpty(this.state.task.workOrder.project.customer.primaryPhone) ? null : (<><span className={'fa fa-phone'}></span> {this.state.task.workOrder.project.customer.primaryPhone}<br /></>)}
                                                                                {_.isEmpty(this.state.task.workOrder.project.customer.primaryMobile) ? null : (<><span className={'fa fa-mobile-phone'}></span> {this.state.task.workOrder.project.customer.primaryMobile}<br /></>)}
                                                                                {_.isEmpty(this.state.task.workOrder.project.customer.primaryEmail) ? null : (<><span className={'fa fa-envelope'}></span> {this.state.task.workOrder.project.customer.primaryEmail}<br /></>)}
                                                                            </p>
                                                                        ) : null
                                                                ) : null
                                                            ) : (
                                                                this.state.task.project !== null && this.state.task.project !== undefined ? (
                                                                    this.state.task.project.customer !== null && this.state.task.project.customer !== undefined ?
                                                                        (
                                                                            <p>
                                                                                {this.state.task.project.customer.firstName} {this.state.task.project.customer.lastName}<br />
                                                                                {_.isEmpty(this.state.task.project.customer.primaryPhone) ? null : (<><span className={'fa fa-phone'}></span> {this.state.task.project.customer.primaryPhone}<br /></>)}
                                                                                {_.isEmpty(this.state.task.project.customer.primaryMobile) ? null : (<><span className={'fa fa-mobile-phone'}></span> {this.state.task.project.customer.primaryMobile}<br /></>)}
                                                                                {_.isEmpty(this.state.task.project.customer.primaryEmail) ? null : (<><span className={'fa fa-envelope'}></span> {this.state.task.project.customer.primaryEmail}<br /></>)}
                                                                            </p>
                                                                        ) : null
                                                                ) : null
                                                            )}

                                                        </Col>
                                                    </Row>
                                                    <Row className={'mt-3'}>
                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                            <h6><strong>LOCATIE VAN UITVOERING</strong></h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <div className="text-left">

                                                                {this.state.task.project !== null && this.state.task.project !== undefined ? (
                                                                    <>
                                                                        {this.state.task.project.street} {this.state.task.project.houseNumber} {this.state.task.project.bus}<br />
                                                                        {this.state.task.project.postalCode} {this.state.task.project.city}<br />
                                                                        {this.state.task.project.country !== null ? this.state.task.project.country.name : null}
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <a
                                                                className={'btn float-right'}
                                                                style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                                target={'_blank'}
                                                                href={'https://www.google.be/maps/dir//' + this.state.task.project.street + ' ' + this.state.task.project.houseNumber + ',' + this.state.task.project.postalCode + ' ' + this.state.task.project.city/* + '/@' + this.state.lat + ','+ this.state.lng*/}
                                                            >
                                                                <i className="icofont icofont-2x icofont-direction-sign"></i>
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                    {this.state.task.workOrder !== null && this.state.task.workOrder !== undefined ? (
                                                        <>
                                                            <Row className={'mt-3'}>
                                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                                    <h6>
                                                                        <strong>WERKBON: {this.state.task.workOrder.name}</strong>
                                                                        <Link
                                                                            className={'btn float-right'}
                                                                            style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                                            target={'_blank'}
                                                                            to={'/workorder/detail/'+ this.state.task.workOrder.id}
                                                                        ><i className="icofont icofont-2x icofont-eye-alt"></i></Link>
                                                                    </h6>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                                    <div dangerouslySetInnerHTML={{__html: this.state.task.workOrder.description}} />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    ) : null}
                                                    {this.state.task.project !== null && this.state.task.project !== undefined ? (
                                                        <>
                                                            <Row className={'mt-3'}>
                                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                                    <h6>
                                                                        <strong>PROJECT: {this.state.task.project.name}</strong>
                                                                        <Link
                                                                            className={'btn float-right'}
                                                                            style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                                            target={'_blank'}
                                                                            to={'/project/detail/'+ this.state.task.project.id}
                                                                        ><i className="icofont icofont-2x icofont-eye-alt"></i></Link>
                                                                    </h6>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                                    <div dangerouslySetInnerHTML={{__html: this.state.task.project.description}} />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    ) : null}
                                                </CardBody>
                                            </Card>
                                        </>
                                    ) : null}
                                </Col>
                            </Row>
                        </>
                    ) : 'U hebt geen rechten om deze pagina te bekijken'
                )}

            </Fragment>
        );
    }
};

export default TasksOverview;