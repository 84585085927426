import React, { Fragment } from 'react';
import '../../assets/css/reacttable_pouillon.css';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import {Typeahead} from "react-bootstrap-typeahead";
import _ from "lodash";
import CKEditors from "react-ckeditor-component";
import SweetAlert from "react-bootstrap-sweetalert";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import Loader from "../common/loader";
import {Link} from "react-router-dom";
import APIUnauthorised from '../../utils/APIUnauthorised';
import EvaluationCard from './evaluationCard';
import TaskWorkOrderInformation from './taskWorkOrderInformation';
import TaskProjectInformation from './taskProjectInformation';
import TaskCustomerInformation from './taskCustomerInformation';

class TaskDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading : true,
            currentUser : null,
            task : null,
            editableTask : null,
            editView : false,
            newTimesheetItem : {
                description : "",
                day: "",
                startHour : "",
                endHour : ""
            },
            timesheetItems : [],
            taskForm : {
                name: '',
                description : '',
                responsibleUserId : null,
                accountableUserId : null
            },
            addNewTimesheetItem : false,
            users : [],
            projects : [],
            filtered: [],
            columns : [
            {
                Header : 'Beschrijving',
                headerClassName: 'text-left',
                accessor : 'description'
            },
                {
                    Header : 'Dag',
                    headerClassName: 'text-left',
                    accessor : 'startTime',
                    Cell: row => {
                        if (_.isEmpty(row.row.startTime)) {
                            return <span>(geen datum)</span>
                        } else {
                            return new Intl.DateTimeFormat("nl-BE", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit"
                            }).format(new Date(row.row.startTime))
                        }
                    }
                },
            {
                Header : 'Starttijd',
                headerClassName: 'text-left',
                accessor : 'startTime',
                Cell: row => {
                    if (_.isEmpty(row.row.startTime)) {
                        return <span>(geen datum)</span>
                    } else {
                        return new Intl.DateTimeFormat("nl-BE", {
                            hour: "2-digit",
                            minute: "2-digit"
                        }).format(new Date(row.row.startTime))
                    }
                }
            },
            {
                Header : 'Eindtijd',
                headerClassName: 'text-left',
                accessor : 'endTime',
                Cell: row => {
                    if (_.isEmpty(row.row.endTime)) {
                        return <span>(geen datum)</span>
                    } else {
                        return new Intl.DateTimeFormat("nl-BE", {
                            hour: "2-digit",
                            minute: "2-digit"
                        }).format(new Date(row.row.endTime))
                    }
                }
            },
            {
                Header : 'Uitvoerder',
                headerClassName: 'text-left',
                accessor : 'user',
                Cell: row => {
                    if (row.row.user !== undefined && row.row.user !== null) {
                        return <span>{row.row.user.firstName} {row.row.user.lastName}</span>
                    } else {
                        return <span></span>
                    }
                }
            }
        ]
        };
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        var me = this;
        API.get('users/workers').then(function(response) {
            me.setState({users: response.data});
        });

        API.get('projects').then(function(response) {
            me.setState({projects: response.data});
        });

        API.get('myuser').then(function(response) {
            me.setState({currentUser: response.data[0]});
        });

        API.get('task/' + id).then(function(response) {
            me.setState({task: response.data});
            me.setState({editableTask: response.data}, function() {
                this.setState((prevState) => ({editableTask: { ...prevState.editableTask, plannedDateForExecution : new Intl.DateTimeFormat("fr-CA", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit"
                        }).format(new Date(this.state.editableTask.plannedDateForExecution)) }}));
                this.setState((prevState) => ({editableTask: { ...prevState.editableTask, plannedTimeForExecution : new Intl.DateTimeFormat("en", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false
                        }).format(new Date(this.state.editableTask.plannedDateForExecution)) }}), function() {
                    console.log(this.state);
                });
                this.setState((prevState) => ({editableTask: { ...prevState.editableTask, plannedEndDateForExecution : new Intl.DateTimeFormat("fr-CA", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit"
                        }).format(new Date(this.state.editableTask.plannedEndDateForExecution)) }}));
                this.setState((prevState) => ({editableTask: { ...prevState.editableTask, plannedEndTimeForExecution : new Intl.DateTimeFormat("en", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false
                        }).format(new Date(this.state.editableTask.plannedEndDateForExecution)) }}), function() {
                    console.log(this.state);
                });
                if (this.state.editableTask.accountableUser !== null) {
                    this.setState((prevState) => ({editableTask: { ...prevState.editableTask, accountableUser: this.state.editableTask.accountableUser.id }}));
                }
                if (this.state.editableTask.responsibleUser !== null) {
                    this.setState((prevState) => ({editableTask: { ...prevState.editableTask, responsibleUser: this.state.editableTask.responsibleUser.id }}));
                }
                me.setState({isLoading: false});
            });
        });

        API.get('task/' + id + '/timesheetItems').then(function(response) {
           me.setState({timesheetItems : response.data});
        });
    }

    updateTask = (event) => {
        event.preventDefault();
        const { id } = this.props.match.params;
        var me = this;
        API.put('task/' + id, {
            'name' : this.state.editableTask.name,
            'description' : this.state.editableTask.description,
            'responsibleUserId' : this.state.editableTask.responsibleUser,
            'accountableUserId' : this.state.editableTask.accountableUser,
            'projectId' :  this.state.editableTask.project != null ? this.state.editableTask.project.id : null,
            'workOrderId' : this.state.editableTask.workOrder != null ? this.state.editableTask.workOrder.id : null,
            'plannedDateForExecution' : this.state.editableTask.plannedDateForExecution + ' ' + this.state.editableTask.plannedTimeForExecution,
            'plannedEndDateForExecution' : this.state.editableTask.plannedEndDateForExecution + ' ' + this.state.editableTask.plannedEndTimeForExecution,
            'status' : this.state.editableTask.status
        }).then(function(response) {
            me.setState({task : me.state.editableTask});
        });
    };

    addTimesheetItem = () => {
        const { id } = this.props.match.params;
        let me = this;
        API.post('/task/' + id + '/timesheetitem', {
           'description' : this.state.newTimesheetItem.description,
           'day' : this.state.newTimesheetItem.day,
           'startHour' : this.state.newTimesheetItem.startHour,
           'endHour' :  this.state.newTimesheetItem.endHour
        }).then(function(response) {
            API.get('task/' + id + '/timesheetItems').then(function(response) {
                me.setState({timesheetItems : response.data});
                me.setState({addNewTimesheetItem : false});
            });
        });
    };

    startTask = (event) => {
        event.preventDefault();
        const { id } = this.props.match.params;
        let me =  this;
        API.put('/task/' + id + '/state/changetobusy').then(function(response) {
            me.setState({task : response.data});
        });
    };

    stopTask = (event) => {
        event.preventDefault();
        const { id } = this.props.match.params;
        let me =  this;
        API.put('/task/' + id + '/state/changetodone').then(function(response) {
            me.setState({task : response.data});
        });
    };

    pauseTask = (event) => {
        event.preventDefault();
        const { id } = this.props.match.params;
        let me =  this;
        API.put('/task/' + id + '/state/changetopause').then(function(response) {
            me.setState({task : response.data});
        });
    };

    startTask = (event) => {
        event.preventDefault();
        const { id } = this.props.match.params;
        let me =  this;
        API.put('/task/' + id + '/state/changetobusy').then(function(response) {
            me.setState({task : response.data});
        });
    };

    openSegmentToAddTimesheetItem = () => {
        this.setState({addNewTimesheetItem: true});
    };

    cancelAddNewTimesheetItem = () => {
        this.setState({addNewTimesheetItem : false});
    };

    changeTaskName = (event) => {
        let value = event.target.value;
        if (value === "" || value === null) {
            this.setState((prevState) => ({errorsOfTaskForm: { ...prevState.errorsOfTaskForm, name : true }}));
        }
        this.setState((prevState) => ({editableTask: { ...prevState.editableTask, name : value }}));
    };

    changeTaskDescription = (event) => {
        let value = event.editor.getData();
        if (value === "" || value === null) {
            this.setState((prevState) => ({errorsOfTaskForm: { ...prevState.errorsOfTaskForm, description : true }}));
        }
        this.setState((prevState) => ({editableTask: { ...prevState.editableTask, description : value }}));
    };

    changeTaskProject = (selectedArray) => {
        let newProject = selectedArray[0];
        this.setState((prevState) => ({editableTask: { ...prevState.editableTask, project : newProject }}));
        this.setState((prevState) => ({editableTask: { ...prevState.editableTask, workOrder : null }}));
    };

    changeTaskWorkOrder = (selectedArray) => {
        let newWorkOrder = selectedArray[0];
        this.setState((prevState) => ({editableTask: { ...prevState.editableTask, workOrder : newWorkOrder }}));
    };

    changeTaskStatus = (selectedArray) => {
        if (selectedArray.length > 0) {
            let newStatus = selectedArray[0];
            this.setState((prevState) => ({editableTask: { ...prevState.editableTask, status : newStatus.label }}));
        } else {
            this.setState((prevState) => ({editableTask: { ...prevState.editableTask, status : "" }}));
        }
    };

    changeTaskResponsibleUser = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableTask: { ...prevState.editableTask, responsibleUser : value }}));
    };

    changeTaskAccountableUser = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableTask: { ...prevState.editableTask, accountableUser : value }}));
    };

    changeTaskEstimatedHoursWork = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableTask: { ...prevState.editableTask, estimatedHoursWork : value }}));
    };

    changeTaskEstimatedDaysWork = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableTask: { ...prevState.editableTask, estimatedDaysWork : value }}));
    };

    changeTimesheetDescription = (event) => {
        let description = event.target.value;
        this.setState((prevState) => ({newTimesheetItem: { ...prevState.newTimesheetItem, description : description }}));
    };

    changeTimesheetDay = (event) => {
        let day = event.target.value;
        this.setState((prevState) => ({newTimesheetItem: { ...prevState.newTimesheetItem, day : day }}));
    };

    changeTimesheetStartHour = (event) => {
        let startHour = event.target.value;
        this.setState((prevState) => ({newTimesheetItem: { ...prevState.newTimesheetItem, startHour : startHour }}));
    };

    changeTimesheetEndHour = (event) => {
        let endHour = event.target.value;
        this.setState((prevState) => ({newTimesheetItem: { ...prevState.newTimesheetItem, endHour : endHour }}));
    };

    changePlannedDayOfExecution = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableTask: { ...prevState.editableTask, plannedDateForExecution : value }}));
    };

    changePlannedTimeOfExecution = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableTask: { ...prevState.editableTask, plannedTimeForExecution : value }}));
    };

    changePlannedEndDayOfExecution = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableTask: { ...prevState.editableTask, plannedEndDateForExecution : value }}));
    };

    changePlannedEndTimeOfExecution = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableTask: { ...prevState.editableTask, plannedEndTimeForExecution : value }}));
    };

    validateTask = () => {
        let validated = true;
        if (this.state.taskForm.name === "" || this.state.taskForm.name === null) {
            validated = false;
        }
        if (this.state.taskForm.description === "" || this.state.taskForm.description === null) {
            validated = false;
        }
        if (this.state.taskForm.responsibleUserId === "" || this.state.taskForm.responsibleUserId === null) {
            validated = false;
        }
        if (this.state.taskForm.accountableUserId === "" || this.state.taskForm.accountableUserId === null) {
            validated = false;
        }
        return validated;
    };

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1);
                    else filter["value"] = value;
                    insertNewFilter = 0;
                }
            });
        }
        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }
        this.setState({ filtered: filtered });
    };

    openEditView = () => {
        this.setState({editView: true});
    };

    closeEditView = () => {
        this.setState({editView: false});
    };

    render() {
        return (
            <Fragment>
                {this.state.isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <Breadcrumb parent={'Project ' + this.state.editableTask.project.name} parentLink={'/project/detail/' + this.state.editableTask.project.id} title={'Taakomschrijving'}>
                            Taak {this.state.editableTask.name}   
                        </Breadcrumb>
                        <div className="container-fluid">
                            {this.state.currentUser !== null && this.state.currentUser !== undefined && (this.state.currentUser.userRole == 'ADMIN' || this.state.currentUser.userRole === 'EMPLOYER' || this.state.currentUser.userRole === 'EMPLOYEE') ? (
                                <>
                                    {this.state.task ? (
                                        <>
                                            {(this.state.currentUser.userRole === 'ADMIN' || this.state.currentUser.userRole === 'EMPLOYER') && this.state.editView ? (
                                                
                                                <div className={'row product-page-main mt-3'} style={{width: '100%'}}>
                                                    <Col lg={12}>
                                                    <Card>
                                                        <CardBody>
                                                        <form style={{width: '100%'}} >
                                                        <div className={'row'}>
                                                            <div className={'col-xl-10'}>
                                                                <h6><strong>Algemene informatie</strong></h6>
                                                            </div>
                                                            <Col xl={2} className='text-right'>
                                                                <Link onClick={this.closeEditView}><i className='fa fa-pencil'></i>  Annuleren</Link>
                                                            </Col>
                                                        </div>
                                                        <div className={'row'}>
                                                            <div className={'col-xl-6'}>
                                                                <div className={'form-group row'}>
                                                                    <label className={'col-xl-12'}>
                                                                        Naam:
                                                                    </label>
                                                                    <div className={'col-xl-12'}>
                                                                        <input type={'text'} className={'form-control'} value={this.state.editableTask.name} onChange={this.changeTaskName} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={'col-xl-6'}>
                                                                <div className={'form-group row'}>
                                                                    <label className={'col-xl-12'}>
                                                                        Startuur:
                                                                    </label>
                                                                    <div className={'col-xl-8'}>
                                                                        <input type={'date'} className={'form-control digits'} value={this.state.editableTask.plannedDateForExecution} onChange={this.changePlannedDayOfExecution} />
                                                                    </div>
                                                                    <div className={'col-xl-4'}>
                                                                        <input type={'time'} className={'form-control digits'} value={this.state.editableTask.plannedTimeForExecution} onChange={this.changePlannedTimeOfExecution} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={'col-xl-6'}>
                                                                <div className={'form-group row'}>
                                                                    <label className={'col-xl-12'}>
                                                                        Einduur:
                                                                    </label>
                                                                    <div className={'col-xl-8'}>
                                                                        <input type={'date'} className={'form-control digits'} value={this.state.editableTask.plannedEndDateForExecution} onChange={this.changePlannedEndDayOfExecution} />
                                                                    </div>
                                                                    <div className={'col-xl-4'}>
                                                                        <input type={'time'} className={'form-control digits'} value={this.state.editableTask.plannedEndTimeForExecution} onChange={this.changePlannedEndTimeOfExecution} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={'row'}>
                                                            <div className={'col-xl-6'}>
                                                                <div className={'form-group row'}>
                                                                    <label className={'col-xl-12'}>
                                                                        Uitvoerder:
                                                                    </label>
                                                                    <div className={'col-xl-12'}>
                                                                        {this.state.users && this.state.editableTask ? (
                                                                            <select className={'form-control'} value={this.state.editableTask.responsibleUser} onChange={(event) => this.changeTaskResponsibleUser(event)}>
                                                                                <option value={""}>---</option>
                                                                                {this.state.users.map((user, index) => {
                                                                                    return <option value={user.id} key={index}>{user.firstName} {user.lastName} ({user.email})</option>;
                                                                                })}
                                                                            </select>
                                                                        ) : (
                                                                            <p></p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={'col-xl-6'}>
                                                                <div className={'form-group row'}>
                                                                    <label className={'col-xl-12'}>
                                                                        Eindverantwoordelijke:
                                                                    </label>
                                                                    <div className={'col-xl-12'}>
                                                                        {this.state.users && this.state.editableTask ? (
                                                                            <select className={'form-control'} value={this.state.editableTask.accountableUser} onChange={(event) => this.changeTaskAccountableUser(event)}>
                                                                                <option value={""}>---</option>
                                                                                {this.state.users.map((user, index) => {
                                                                                    return <option value={user.id} key={index}>{user.firstName} {user.lastName} ({user.email})</option>;
                                                                                })}
                                                                            </select>
                                                                        ) : (
                                                                            <p></p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={'row'}>
                                                            <div className={'col-xl-6'}>
                                                                <div className={'form-group row'}>
                                                                    <label className={'col-xl-12'}>
                                                                        Project:
                                                                    </label>
                                                                    <div className={'col-xl-12'}>
                                                                        {this.state.projects && this.state.editableTask ? (
                                                                            <Typeahead
                                                                                id="project-typeahead"
                                                                                onChange={this.changeTaskProject}
                                                                                clearButton
                                                                                labelKey="name"
                                                                                selected={this.state.editableTask.project ? [this.state.editableTask.project] : []}
                                                                                multiple={false}
                                                                                options={this.state.projects}
                                                                                placeholder="Selecteer een project ..."
                                                                            />
                                                                        ) : (
                                                                            <p> </p>
                                                                        )}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={'col-xl-6'}>
                                                                <div className={'form-group row'}>
                                                                    <label className={'col-xl-12'}>
                                                                        Werkbon:
                                                                    </label>
                                                                    <div className={'col-xl-12'}>
                                                                        {this.state.editableTask && this.state.editableTask.project ? (
                                                                            <Typeahead
                                                                                id="project-typeahead"
                                                                                onChange={this.changeTaskWorkOrder}
                                                                                clearButton
                                                                                labelKey="name"
                                                                                selected={this.state.editableTask.workOrder ? [this.state.editableTask.workOrder] : []}
                                                                                multiple={false}
                                                                                options={this.state.editableTask.project ? this.state.editableTask.project.workOrders : []}
                                                                                placeholder="Selecteer een werkbon ..."
                                                                            />
                                                                        ) : (
                                                                            <p> </p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Status:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <Typeahead
                                                                    id="status-typeahead"
                                                                    name="status"
                                                                    onChange={this.changeTaskStatus}
                                                                    clearButton
                                                                    labelKey="label"
                                                                    selected={[this.state.editableTask.status]}
                                                                    multiple={false}
                                                                    options={[{"label": "NEW"}, {"label": "BUSY"}, {"label": "PAUSE"}, {"label": "DONE"}]}
                                                                    placeholder="Selecteer de status ..."
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Beschrijving:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <CKEditors
                                                                    activeclassName="p10"
                                                                    content={this.state.editableTask.description}
                                                                    events={{
                                                                        "change": this.changeTaskDescription
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className={'form-group row'}>
                                                            <div className={'col-xl-12'}>
                                                                <button className={'btn btn-primary'} onClick={(event) => this.updateTask(event)}>Taak wijzigen</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                        </CardBody>
                                                    </Card>
                                                    </Col>
                                                </div>
                                            ) : (
                                                <>
                                                    <Row className={'mt-3'}>
                                                        <Col lg={12} sm={12}>
                                                            <Card>
                                                                <CardBody>
                                                                    <div className={'row'}>
                                                                        <div className={'col-xl-10'}>
                                                                            <h6><strong>Algemene informatie</strong></h6>
                                                                        </div>
                                                                        <Col xl={2} className='text-right'>
                                                                            <Link onClick={this.openEditView}><i className='fa fa-pencil'></i>  Wijzigen</Link>
                                                                        </Col>
                                                                    </div>
                                                                    <div className={'row mt-3'}>
                                                                        <div className={'col-xl-6'}>
                                                                            <h6><strong>Naam</strong></h6>
                                                                            <p>
                                                                                {this.state.task.name}
                                                                            </p>
                                                                        </div>
                                                                        <div className={'col-xl-6'}>
                                                                            <h6><strong>Status</strong></h6>
                                                                            <p>
                                                                                {this.state.task.status}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className={'row mt-3'}>
                                                                        <div className={'col-xl-12'}>
                                                                            <h6><strong>Beschrijving</strong></h6>
                                                                            <p>
                                                                                {this.state.task.description}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className={'row mt-3'}>
                                                                        <div className={'col-xl-6'}>
                                                                            <h6><strong>Uitvoerder</strong></h6>
                                                                            <p>
                                                                            {this.state.task.responsibleUser ? (
                                                                                    <>
                                                                                        {this.state.task.responsibleUser.firstName} {this.state.task.responsibleUser.lastName}
                                                                                    </>
                                                                                ) : (
                                                                                    <span>Geen eindverantwoordelijk ingegeven</span>
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                        <div className={'col-xl-6'}>
                                                                            <h6><strong>Eindverantwoordelijke</strong></h6>
                                                                            <p>
                                                                                {this.state.task.accountableUser ? (
                                                                                    <>
                                                                                        {this.state.task.accountableUser.firstName} {this.state.task.accountableUser.lastName}
                                                                                    </>
                                                                                ) : (
                                                                                    <span>Geen eindverantwoordelijk ingegeven</span>
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className={'row mt-3'}>
                                                                        <div className={'col-xl-6'}>
                                                                            <h6><strong>Geplande startdatum van uitvoering</strong></h6>
                                                                            <p>
                                                                                {new Intl.DateTimeFormat("nl-BE", {
                                                                                    year: "numeric",
                                                                                    month: "long",
                                                                                    day: "2-digit",
                                                                                    hour: "2-digit",
                                                                                    minute: "2-digit"
                                                                                }).format(new Date(this.state.task.plannedDateForExecution))}
                                                                            </p>
                                                                        </div>
                                                                        <div className={'col-xl-6'}>
                                                                            <h6><strong>Geplande einddatum van uitvoering</strong></h6>
                                                                            <p>
                                                                                {new Intl.DateTimeFormat("nl-BE", {
                                                                                    year: "numeric",
                                                                                    month: "long",
                                                                                    day: "2-digit",
                                                                                    hour: "2-digit",
                                                                                    minute: "2-digit"
                                                                                }).format(new Date(this.state.task.plannedEndDateForExecution))}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <Row className={'mt-5'}>
                                                                        <Col lg={12}>
                                                                            {this.state.task.status === 'NEW' ? (
                                                                                <a href={'#'} className={'btn btn-primary pt-3 pb-3 mr-2'} onClick={(event) => this.startTask(event)}>Start taak</a>
                                                                            ) : null}
                                                                            {this.state.task.status === 'BUSY' ? (
                                                                                <>
                                                                                    <a href={'#'} className={'btn btn-primary pt-3 pb-3 mr-2'} onClick={(event) => this.pauseTask(event)}>Pauzeer taak</a>
                                                                                    <Link to={'/project/' + + this.state.task.project.id + '/task/' + this.state.task.id + '/evaluation'} className={'btn btn-primary pt-3 pb-3 mr-2'}>Evalueer taak</Link>
                                                                                </>
                                                                            ) : null}
                                                                            {this.state.task.status === 'EVALUATED' ? (
                                                                                <>
                                                                                    <a href={'#'} className={'btn btn-primary pt-3 pb-3 mr-2'} onClick={(event) => this.stopTask(event)}>Stop taak</a>
                                                                                </>
                                                                            ) : null}
                                                                            {this.state.task.status === 'PAUSE' ? (
                                                                                <>
                                                                                    <a href={'#'} className={'btn btn-primary pt-3 pb-3 mr-2'} onClick={(event) => this.startTask(event)}>Start taak</a>
                                                                                    <a href={'#'} className={'btn btn-primary pt-3 pb-3 mr-2'} onClick={(event) => this.stopTask(event)}>Stop taak</a>
                                                                                </>
                                                                            ) : null}
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>

                                                        </Col>
                                                    </Row>

                                                    
                                                </>
                                            )}

                                            {this.state.currentUser.userRole === 'ADMIN' || this.state.currentUser.userRole === 'EMPLOYER'  || this.state.currentUser.userRole === 'EMPLOYEE' ? (
                                                <>
                                                    <Row>
                                                        <Col lg={4}>
                                                            <TaskCustomerInformation task={this.state.task}></TaskCustomerInformation>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <TaskProjectInformation task={this.state.task}></TaskProjectInformation>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <TaskWorkOrderInformation task={this.state.task}></TaskWorkOrderInformation>
                                                        </Col>
                                                    </Row>
                                                    <EvaluationCard task={this.state.task}></EvaluationCard>
                                                </>
                                            ) : null}
                                            <Card>
                                                <CardBody>
                                                    <SweetAlert
                                                        show={this.state.addNewTimesheetItem}
                                                        showCloseButton={true}
                                                        onCancel={() => this.cancelAddNewTimesheetItem()}
                                                        info
                                                        title="Prestatie toevoegen"
                                                        onConfirm={() => this.addTimesheetItem()}
                                                        confirmBtnText={'Prestatie toevoegen'}
                                                        cancelBtnText={'Annuleren'}
                                                        style={{width: '90%'}}
                                                    >
                                                        <form className={'form'}>
                                                            <div className="form-group row">
                                                                <label className="col-sm-3 col-form-label">Beschrijving</label>
                                                                <div className="col-sm-9">
                                                                    <input className="form-control" onChange={this.changeTimesheetDescription} type="text" value={this.state.newTimesheetItem.description} /></div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-sm-3 col-form-label">Dag</label>
                                                                <div className="col-sm-9">
                                                                    <input className="form-control digits" onChange={this.changeTimesheetDay} type="date" value={this.state.newTimesheetItem.day} /></div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-sm-3 col-form-label">Startuur</label>
                                                                <div className="col-sm-9">
                                                                    <input className="form-control digits" onChange={this.changeTimesheetStartHour} type="time" value={this.state.newTimesheetItem.startHour} /></div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-sm-3 col-form-label">Einduur</label>
                                                                <div className="col-sm-9">
                                                                    <input className="form-control digits" onChange={this.changeTimesheetEndHour} type="time" value={this.state.newTimesheetItem.endHour} /></div>
                                                            </div>
                                                        </form>
                                                    </SweetAlert>

                                                    <div className={'col-xl-12'}>
                                                        <h4><strong>Gepresteerde uren</strong> <a onClick={this.openSegmentToAddTimesheetItem}><span className={'fa fa-plus'}></span></a></h4>
                                                    </div>
                                                    <br />
                                                    <div className={'col-xl-12'}>
                                                        <ReactTable onChange={this.componentDidMount}
                                                                    data={this.state.timesheetItems}
                                                                    columns={this.state.columns}
                                                                    defaultPageSize={10}
                                                                    className={"-striped -highlight"}
                                                                    showPagination={true}
                                                        />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </>
                                    ) :  (
                                        <div className={'row product-page-main'}>
                                            <p>Geen taakomschrijving gevonden</p>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <p>Je hebt te weinig rechten om deze pagina te kunnen bekijken. Indien je deze pagina moet kunnen bekijken, gelieve de systeembeheerder te contacteren.</p>
                            )}
                        </div>
                    </>
                )}
            </Fragment>
        );
    }
};

export default TaskDetail;